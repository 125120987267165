import React, { useEffect, useRef } from 'react';
import ProductGallery from '../components/Product/ProductGallery';
import ProductInfo from '../components/Product/ProductInfo';
import ProductOtherDetails from '../components/Product/ProductOtherDetails';
import { useNavigate } from 'react-router-dom';
import { getProductByIdQuery } from '../actions/query';
import { useMutation, useQuery } from '@apollo/client';
import { Skeleton } from '../components/SkeltonLoader';
import { ADD_TO_CART, CREATE_OR_ADD_TO_SESSION_CART } from '../actions/mutation';
import SimilarProducts from '../components/Product/SimilarProducts';
import { getSimilarProductsQuery } from '../actions/query';
import {useParams} from 'react-router-dom'
import { connect } from 'react-redux';
import { setIsLoggedIn } from '../store/slice/auth-slice';
// Hook for logged-in users
export const useAddToCart = () => {
  const userId = localStorage.getItem('userId');
  React.useEffect(() => {
    console.log('Debug - User ID:', userId);
   
    if (userId) {
      setIsLoggedIn(true);
      console.log('Debug - User ID is not null:', userId);
    } else {
      setIsLoggedIn(false);
      console.log('Debug - User ID is null');
    }
  }, [userId]);
  const [addToSessionCartMutation, { loading: sessionLoading, error: sessionError }] =
    useMutation(CREATE_OR_ADD_TO_SESSION_CART);
  const [addToUserCartMutation, { loading: userLoading, error: userError }] =
    useMutation(ADD_TO_CART);

  const addToCart = async ({ userId, productId, skuId, quantity }) => {
    console.log('Debug - useAddToCart called with:', {  
      userId,
      productId,
      skuId,
      quantity
    });

    if (!productId || !skuId || quantity <= 0) {
      console.error('Debug - Invalid input:', { productId, skuId, quantity });
      throw new Error("Invalid input: All fields are required.");
    }

    try {
      if (userId) {
        console.log('Debug - Executing user cart mutation');
        const response = await addToUserCartMutation({
          variables: {
            userId,
            cartItem: {
              cartItems: [
                {
                  productId,
                  skuId,
                  quantity,
                },
              ],
            }
          },
        });
        console.log('Debug - User cart mutation response:', response);
        return response.data.createOrAddToCart;
      } else {
        const sessionId = localStorage.getItem("sessionId");
        console.log('Debug - Executing session cart mutation with sessionId:', sessionId);
        
        if (!sessionId) {
          console.error('Debug - Session ID missing');
          throw new Error("Session ID is missing.");
        }

        const response = await addToSessionCartMutation({
          variables: {
            sessionId,
            cartItem: {
              cartItems: [
                {
                  productId,
                  skuId,
                  quantity,
                },
              ],
            },
          },
        });
        console.log('Debug - Session cart mutation response:', response);
        return response.data.createOrAddToSessionCart;
      }
    } catch (err) {
      console.error('Debug - Cart mutation error:', {
        error: err,
        message: err.message,
        graphQLErrors: err.graphQLErrors,
        networkError: err.networkError
      });
      throw new Error("Failed to add to cart: " + err.message);
    }
  };

  return {
    addToCart,
    loading: sessionLoading || userLoading,
    error: sessionError || userError,
  };
};

const ProductDetail = ({ isLoggedIn }) => {
  const [selectedSku, setSelectedSku] = React.useState(null);
  const { addToCart: addToUserCart, loading: loggedInCartLoading } = useAddToCart();
  const navigate = useNavigate();
  const { id } = useParams();
  const productId = parseInt(id);
  const mainProductRef = useRef(null);

  const userId = localStorage.getItem('userId');

  const {
    loading: productLoading,
    error: productError,
    data: productsDetails,
    refetch
  } = useQuery(getProductByIdQuery, {
    variables: { id: productId },
    skip: !productId || isNaN(productId)
  });

  const prodDetails = productsDetails?.getProductById;

  useEffect(() => {
    if (prodDetails?.skus?.length > 0) {
      setSelectedSku(prodDetails.skus[0]);
    }
  }, [prodDetails]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    if (mainProductRef.current) {
      mainProductRef.current.focus();
    }
  }, []);

  const handleAddToCart = async (skuId, quantity) => {
    try {
      if (isLoggedIn && userId) {
        await addToUserCart({
          userId: parseInt(userId),
          productId,
          skuId,
          quantity: parseInt(quantity),
        });
        navigate(`/cart/${userId}`);
      } else {
        await addToUserCart({
          userId: null,
          productId,
          skuId,
          quantity: parseInt(quantity),
        });
        navigate('/cart/0');
      }
    } catch (error) {
      console.error('Error adding to cart:', error);
      alert(error.message || 'Failed to add product to cart. Please try again.');
    }
  };

  const {
    loading: similarProductsLoading,
    error: similarProductsError,
    data: similarProductsData
  } = useQuery(getSimilarProductsQuery, {
    variables: {
      categoryId: prodDetails?.categoryId ? parseInt(prodDetails.categoryId) : null,
      limit: 4
    },
    skip: !productId || !prodDetails?.categoryId
  });

  if (productLoading || loggedInCartLoading) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-8 mt-10">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <Skeleton
            variant="rectangular"
            width="100%"
            height="600px"
            className="rounded-lg"
            count={1}
          />
          <div className="space-y-4">
            <Skeleton height={40} width="70%" />
            <Skeleton height={30} width="40%" />
            <Skeleton height={100} />
            <Skeleton height={50} width="60%" />
            <Skeleton height={50} />
          </div>
        </div>
      </div>
    );
  }

  if (productError) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-8 mt-10">
        <div className="text-red-500 text-center text-lg">
          Error loading product details: {productError.message}
        </div>
      </div>
    );
  }

  if (!prodDetails) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-8 mt-10">
        <div className="text-center text-lg">Product not found</div>
      </div>
    );
  }

  const formattedProduct = {
    ...prodDetails,
    price: selectedSku?.price ?? prodDetails?.price ?? 0,
    originalPrice: prodDetails?.originalPrice ?? 0,
    discount: prodDetails?.discountPercentage && prodDetails?.discountPercentage !== 0
      ? prodDetails.discountPercentage
      : null,
    rating: prodDetails?.rating ?? 0,
    ratings: prodDetails?.ratings,
    skus: prodDetails?.skus?.map(sku => ({
      ...sku,
      id: sku.id,
      variants: Object.entries(sku.variants || {}).reduce((acc, [key, value]) => {
        acc[key] = Array.isArray(value) ? value[0] : value; // Handle both array and single values
        return acc;
      }, {}),
      price: sku.price,
      stock: sku.stock,
      images: sku.images || []
    })) ?? [],
    // Add variant types for filtering
    variantTypes: prodDetails?.skus?.length
      ? Object.keys(prodDetails.skus[0].variants || {})
      : []
  };

  console.log({
    userId: localStorage.getItem('userId'),
    authToken: localStorage.getItem('authToken'),
    sessionId: localStorage.getItem('sessionId')
  });

  return (
    <div ref={mainProductRef} tabIndex={-1} className="max-w-7xl mx-auto px-4 py-8 mt-20">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
        <ProductGallery images={selectedSku?.images ?? []} />
        <ProductInfo
          product={formattedProduct}
          selectedSku={selectedSku}
          setSelectedSku={setSelectedSku}
          handleAddToCart={handleAddToCart}
          isLoading={loggedInCartLoading}
        />
      </div>

      <ProductOtherDetails
        selectedSku={selectedSku}
        formattedProduct={formattedProduct}
        description={prodDetails?.description}
        reviews={prodDetails?.reviews || []}
      />

      <div className="mt-16">

        <SimilarProducts
          categoryId={prodDetails?.categoryId}
          currentProductId={productId}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isAuthenticated,
});

const mapDispatchToProps = {
  setIsLoggedIn: setIsLoggedIn, 
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);