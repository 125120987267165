import React, { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { clearCart, CREATE_ADDRESS, createOrder, createUserWithEmail, DELETE_ADDRESS, MERGE_CARTS_ON_LOGIN, UPDATE_ADDRESS, VerifyOTP, LOGIN } from '../actions/mutation';
import { GET_DEFAULT_ADDRESS, GET_USER_ADDRESSES } from '../actions/query';
import AddressForm from '../components/Address/AddressForm';
import OrderConfirmModal from '../components/Modal/OrderConfirm';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { auth } from '../firebase';
import { connect } from 'react-redux';
import {
  loginStart,
  loginSuccess,
  loginFailure,
  logout,
  setIsLoggedIn,
} from '../store/slice/auth-slice';
import { useQuery as useTanstackQuery } from '@tanstack/react-query';

export const useMergeToCart = () => {
  const [mergeToCartMutation, { loading, error }] = useMutation(MERGE_CARTS_ON_LOGIN);

  const mergeToCart = async ({ userId }) => {
    if (!userId) {
      throw new Error("Invalid input: userId is required.");
    }

    const sessionId = localStorage.getItem("sessionId"); // Retrieve sessionId from localStorage
    if (!sessionId) {
      throw new Error("Session ID is missing.");
    }

    try {
      const response = await mergeToCartMutation({
        variables: {
          sessionId,
          userId,
        },
      });

      return response.data.mergeCartsOnLogin; // Adjust response key as needed
    } catch (err) {
      console.error("Error merging carts:", err);
      throw new Error("Failed to merge carts: " + err.message);
    }
  };

  return {
    mergeToCart,
    loading,
    error,
  };
};

// Add mapStateToProps and mapDispatchToProps at the top level
const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isAuthenticated,
  user: state.auth.user,
  loading: state.auth.loading,
  error: state.auth.error,
});

const mapDispatchToProps = {
  loginStart,
  loginSuccess,
  loginFailure,
  logout,
  setIsLoggedIn,
};

// Modify the CheckoutPage component signature to include Redux props
const CheckoutPage = ({
  isLoggedIn,
  loginStart,
  loginSuccess,
  loginFailure,
  logout,
  setIsLoggedIn
}) => {
  const { state } = useLocation();
  const { mergeToCart, loading: mergeToCartLoading } = useMergeToCart();

  // Ensure cartData is always an array
  const cartData = state?.data || [];
  const [activeSection, setActiveSection] = useState('login');
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState('');
  const [userId, setUserId] = useState(null);
  const [emailOrMobile, setEmailOrMobile] = useState('');
  const [showOtp, setShowOtp] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [phone, setPhone] = useState('');
  const [hasFilled, setHasFilled] = useState(false);

  const handleMergeCart = async (_userId) => {
    if (!_userId) {
      console.error("User ID is required to merge carts.");
      return;
    }

    try {
      const result = await mergeToCart({ userId: parseInt(_userId) });
      console.log("Merge cart successful:", result);
    } catch (err) {
      console.error("Failed to merge cart:", err.message);
    }
  };

  React.useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    const userEmail = localStorage.getItem('userEmail');
    const userId = localStorage.getItem('userId');
    console.log("userId, userEmail", userId, userEmail);

    if (authToken) {
      setIsLoggedIn(true);
      setUserId(parseInt(userId));
      setEmailOrMobile(userEmail || '');
      setActiveSection('address');
    }
  }, []);

  // Calculate totals
  const subtotal = cartData.reduce((sum, item) => sum + (item.originalPrice || 0) * (item.quantity || 0), 0);
  const discount = cartData.reduce((sum, item) => sum + ((item.originalPrice || 0) - (item.sku?.price || 0)) * (item.quantity || 0), 0);
  const deliveryFee = subtotal > 100 ? 0 : 15;
  const total = subtotal - discount + deliveryFee;

  // Validation for section progression
  const canProceedToAddress = isLoggedIn;
  const canProceedToOrder = selectedAddress != null;
  const canProceedToPayment = activeSection === 'order' && cartData.length > 0;

  const handleSectionChange = (section) => {
    if (section === 'address' && !canProceedToAddress) {
      return;
    }
    if (section === 'order' && !canProceedToOrder) {
      return;
    }
    if (section === 'payment' && !canProceedToPayment) {
      return;
    }
    setActiveSection(section === activeSection ? '' : section);
  };

  const AccordionHeader = ({ number, title, isCompleted, isActive, onClick, itemCount, isDisabled }) => (
    <button
      onClick={onClick}
      disabled={isDisabled}
      className={`w-full flex items-center gap-3 p-4 ${isDisabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-50'}`}
    >
      <span className={`w-6 h-6 rounded-full flex items-center justify-center text-sm 
        ${isCompleted ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600'}`}>
        {isCompleted ? '✓' : number}
      </span>
      <span className="font-medium">{title}</span>
      {itemCount && <span className="text-gray-500 ml-2">({itemCount} Items)</span>}
      <span className="ml-auto text-gray-400">
        {isActive ? '−' : '+'}
      </span>
    </button>
  );

  // Payment handling
  const [paymentDetails, setPaymentDetails] = useState({
    cardNumber: '',
    cardHolder: '',
    expiryDate: '',
    cvv: '',
    upiId: '',
    codAgree: false // New field for COD agreement
  });
  const [errors, setErrors] = useState({});

  const validatePaymentDetails = () => {
    const newErrors = {};

    if (selectedPayment === 'cod') {
      if (!paymentDetails.codAgree) {
        newErrors.cod = 'Please agree to pay on delivery';
      }
      return Object.keys(newErrors).length === 0;
    }

    if (selectedPayment.includes('card')) {
      // ... (existing card validation logic)
    } else if (selectedPayment === 'upi') {
      // ... (existing UPI validation logic)
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const [createAddress] = useMutation(CREATE_ADDRESS);
  const [updateAddress] = useMutation(UPDATE_ADDRESS);
  const [deleteAddress] = useMutation(DELETE_ADDRESS);


  // Modify the useQuery section for addresses
  const { loading: addressesLoading, data: addressData, refetch: refetchAddresses } = useQuery(GET_USER_ADDRESSES, {
    variables: { userId },
    skip: !userId || !isLoggedIn,
    onCompleted: (data) => {
      const addresses = data?.getAddressesByUserId || [];
      if (addresses.length > 0 && !selectedAddress) {
        // First try to find the default address
        const defaultAddress = addresses.find(addr => addr.isDefault);
        // If no default address exists, use the first address
        setSelectedAddress(defaultAddress || addresses[0]);
      }
    }
  });

  const addressesData = addressData?.getAddressesByUserId || [];

  const [showAddressForm, setShowAddressForm] = useState(false);
  const [addressFormData, setAddressFormData] = useState({
    street: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    isDefault: false
  });

  // Render functions
  // Add logout functionality
  const handleLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('tempEmail');
    setIsLoggedIn(false);
    setEmailOrMobile('');
    setShowOtp(false);
    setOtpValue('');
    setActiveSection('login');
  };

  // Add login mutation
  const [loginMutation, { loading: loginLoading }] = useMutation(LOGIN);

  // Update generateRecaptcha function
  const generateRecaptcha = () => {
    // Check if recaptcha container exists
    const recaptchaContainer = document.getElementById('recaptcha');
    if (!recaptchaContainer) {
      console.error('Recaptcha container not found');
      return;
    }

    // Clear any existing verifier and container content
    if (window.recaptchaVerifier) {
      window.recaptchaVerifier.clear();
      while (recaptchaContainer.firstChild) {
        recaptchaContainer.removeChild(recaptchaContainer.firstChild);
      }
    }

    // Create new verifier
    window.recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha',
      {
        size: 'invisible',
        callback: (response) => {
          // reCAPTCHA solved
        },
      },
      auth
    );
  };

  // Update handleSend function
  const handleSend = (event) => {
    event.preventDefault();
    if (!phone) {
      alert('Please enter a phone number');
      return;
    }
    setHasFilled(true);

    // Small delay to ensure DOM is ready
    setTimeout(() => {
      generateRecaptcha();
      let appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(auth, phone, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
        })
        .catch((error) => {
          console.log(error);
          alert('Error sending OTP. Please try again.');
          setHasFilled(false);
        });
    }, 100);
  };

  // Update verifyOtp function
  const verifyOtp = (event) => {
    let otp = event.target.value;
    setOtpValue(otp);

    if (otp.length === 6) {
      if (!window.confirmationResult) {
        console.error('No confirmation result found');
        alert('Session expired. Please request OTP again.');
        setHasFilled(false);
        return;
      }

      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(otp)
        .then((result) => {
          console.log('Firebase auth successful:', result);
          const user = result.user;
          localStorage.setItem('authToken', user.accessToken);

          loginStart();
          loginMutation({
            variables: { phone },
            onCompleted: (data) => {
              console.log('Login mutation response:', data);

              if (!data?.login?.token) {
                console.error('Invalid login response structure:', data);
                throw new Error('Invalid login response');
              }

              // Store the JWT token from our backend
              localStorage.setItem('authToken', data.login.token);
              localStorage.setItem('userId', data.login.user.id);

              // Create a user object from the available data
              const userData = {
                phone: data.login.user.phone,
                token: data.login.token,
              };

              console.log('Login successful, user data:', userData);
              loginSuccess(userData);
              setHasFilled(false);
              setOtpValue('');
              setPhone('');
              setActiveSection('address');
              handleMergeCart(data.login.user.id);
            },
            onError: (error) => {
              console.error('Login mutation error:', error);
              console.error('Full error object:', {
                message: error.message,
                networkError: error.networkError,
                graphQLErrors: error.graphQLErrors,
              });

              alert('Error logging in. Please try again.');
              loginFailure(error.message || 'Login failed');
              setHasFilled(false);
            },
          });
        })
        .catch((error) => {
          console.error('OTP verification error:', error);
          console.error('Full OTP error:', {
            code: error.code,
            message: error.message,
            stack: error.stack,
          });

          loginFailure(error.message || 'OTP verification failed');
          alert('Invalid OTP. Please try again.');
          setOtpValue('');
        });
    }
  };

  // Modified login section render
  const renderLoginSection = () => (
    <div className="space-y-4">
      {isLoggedIn ? (
        <div className="flex items-center justify-between">
          <p className="text-gray-700">Logged in as: {emailOrMobile}</p>
          <button
            onClick={handleLogout}
            className="text-pink-500 hover:text-pink-600"
          >
            Logout
          </button>
        </div>
      ) : (
        <>
          {!hasFilled ? (
            <div className="space-y-4">
              <PhoneInput
                international
                defaultCountry="IN"
                value={phone}
                onChange={setPhone}
                className="w-full p-2 border rounded"
              />
              <button
                onClick={handleSend}
                className="w-full bg-pink-500 text-white py-3 rounded font-medium hover:bg-pink-600"
              >
                Send OTP
              </button>
            </div>
          ) : (
            <div className="space-y-4">
              <input
                type="text"
                value={otpValue}
                onChange={(e) => verifyOtp(e)}
                placeholder="Enter OTP"
                maxLength={6}
                className="w-full p-2 border rounded"
                autoFocus
              />
              <p className="text-sm text-gray-600">
                Please enter the 6-digit code sent to {phone}
              </p>
            </div>
          )}
          <div id="recaptcha"></div>
        </>
      )}
    </div>
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    // Format expiry date input
    if (name === 'expiryDate') {
      formattedValue = value
        .replace(/\D/g, '')
        .slice(0, 4)
        .replace(/(\d{2})(\d{2})/, '$1/$2')
        .replace(/(\d{2})(\d{1})/, '$1/$2');
    }

    // Format card number with spaces
    if (name === 'cardNumber') {
      formattedValue = value.replace(/\D/g, '').slice(0, 16);
    }

    setPaymentDetails((prev) => ({
      ...prev,
      [name]: formattedValue,
    }));
  };

  const renderPaymentForm = () => {
    switch (selectedPayment) {
      case 'credit-card':
      case 'debit-card':
        // ... (existing card payment form)
        return (
          <div className="mt-4 space-y-4">
            {/* ... (existing credit/debit card form fields) ... */}
          </div>
        );

      case 'upi':
        // ... (existing UPI payment form)
        return (
          <div className="mt-4">
            {/* ... (existing UPI form fields) ... */}
          </div>
        );

      case 'cod':
        return (
          <div className="mt-4 space-y-4">
            <div className="bg-gray-50 p-4 rounded-lg">
              <p className="text-gray-700 mb-4">
                Pay the full amount of ₹{total} when your order is delivered.
              </p>
              <div className="flex items-start gap-2">
                <input
                  type="checkbox"
                  name="codAgree"
                  id="codAgree"
                  checked={paymentDetails.codAgree}
                  onChange={handleInputChange}
                  className="mt-1"
                />
                <label htmlFor="codAgree" className="text-sm text-gray-600">
                  I agree to pay the full amount in cash when the order is delivered to my address.
                </label>
              </div>
              {errors.cod && (
                <p className="text-red-500 text-sm mt-2">{errors.cod}</p>
              )}
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const [createOrderMutation] = useMutation(createOrder, {
    onError: (error) => {
      console.error('Create order error:', error);
      setErrors({ submit: 'Failed to create order: ' + error.message });
    }
  });

  const [clearCartMutation] = useMutation(clearCart, {
    onError: (error) => {
      console.error('Clear cart error:', error);
      setErrors({ submit: 'Clear cart error: ' + error.message });
    }
  });

  const navigate = useNavigate();
  const [showOrderConfirmModal, setShowOrderConfirmModal] = React.useState(false);
  const [confirmedOrderDetails, setConfirmedOrderDetail] = React.useState();

  const handlePlaceOrder = async () => {
    if (!validatePaymentDetails()) {
      setActiveSection('payment');
      return;
    }

    if (!selectedAddress) {
      setErrors({ submit: 'Please select a shipping address' });
      setActiveSection('address');
      return;
    }

    if (!userId) {
      setErrors({ submit: 'Please log in to place an order' });
      setActiveSection('login');
      return;
    }

    setIsProcessing(true);
    try {
      const orderInput = {
        userId: userId,
        totalAmount: parseFloat(total) || 0,
        status: "pending",
        discount: discount,
        shippingAddressId: selectedAddress.id,
        billingAddressId: selectedAddress.id,
        orderItems: cartData.map(item => ({
          productId: item.sku?.productId || "",
          skuId: item.sku?.id || "",
          quantity: parseInt(item.quantity) || 1,
          price: parseFloat(item.sku?.price) || 0
        }))
      };

      const { data: orderData } = await createOrderMutation({
        variables: {
          input: orderInput
        }
      });
      console.log("createOrderMutation orderData", orderData);
      if (orderData?.createOrderWithItems?.order) {
        console.log("orderData", orderData);
        await clearCartMutation({
          variables: {
            userId
          }
        });
        setConfirmedOrderDetail(orderData.createOrderWithItems.order);
        setShowOrderConfirmModal(true);
        console.log('Order created successfully:', orderData.createOrderWithItems.order);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    } catch (error) {
      console.error('Order creation failed:', error);
      setErrors({
        submit: error.message.includes('shippingAddressId')
          ? 'Please select a delivery address'
          : 'Failed to create order. Please try again.'
      });

      if (error.message.includes('shippingAddressId')) {
        setActiveSection('address');
      }
    } finally {
      setIsProcessing(false);
    }
  };

  const handleEditAddress = (address) => {
    setAddressFormData(address);
    setShowAddressForm(true);
  };

  const handleAddressDelete = async (id) => {
    try {
      await deleteAddress({
        variables: { id },
      });
      await refetchAddresses();
      if (selectedAddress?.id === id) {
        setSelectedAddress(null);
      }
    } catch (error) {
      console.error('Error deleting address:', error);
    }
  };

  const handlePaymentSelect = (method) => {
    setSelectedPayment(method);
  };

  const handleAddressSubmit = async (formData) => {
    try {
      if (!userId) {
        throw new Error('User ID is required. Please log in again.');
      }

      if (formData.id) {
        const { id, __typename, createdAt, updatedAt, ...filteredFormData } = formData;

        await updateAddress({
          variables: {
            updateAddressId: id,
            input: { ...filteredFormData, id },
          },
        });
      } else {
        await createAddress({
          variables: {
            input: {
              userId: parseInt(userId),
              ...formData,
            },
          },
        });
      }
      await refetchAddresses();
      setShowAddressForm(false);
      setAddressFormData({
        street: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
        isDefault: false,
      });
    } catch (error) {
      console.error('Error saving address:', error);
      alert(error.message || 'Error saving address. Please try again.');
    }
  };

  // Add this new function to handle postal code changes
  const handlePostalCodeChange = (postalCode) => {
    console.log("Postal code changed:", postalCode); // Debug log
    setAddressFormData(prev => ({
      ...prev,
      postalCode,
      // Reset city and state when postal code changes
      ...(postalCode.length !== 6 && {
        city: '',
        state: ''
      })
    }));
  };

  const fetchPinCodeDetails = async (pincode) => {
    if (!pincode || pincode.length !== 6) return null;
    const response = await fetch(`https://api.postalpincode.in/pincode/${pincode}`);
    const data = await response.json();
    if (data[0].Status === 'Error') {
      throw new Error('Invalid PIN Code');
    }
    return data[0];
  };

  const { data: pincodeData, isLoading: isPincodeLoading, error: pincodeError } = useTanstackQuery({
    queryKey: ['pincode', addressFormData.postalCode],
    queryFn: () => fetchPinCodeDetails(addressFormData.postalCode),
    enabled: addressFormData.postalCode?.length === 6,
    staleTime: 1000 * 60 * 60, // Cache for 1 hour
  });

  React.useEffect(() => {
    if (pincodeData?.PostOffice?.[0]) {
      const { State, District } = pincodeData.PostOffice[0];
      console.log("Updating address with:", { State, District }); // Debug log
      setAddressFormData(prev => ({
        ...prev,
        city: District || prev.city,
        state: State || prev.state,
      }));
    }
  }, [pincodeData]);

  // Add this useEffect to log addressFormData changes
  React.useEffect(() => {
    console.log("Address form data updated:", addressFormData);
  }, [addressFormData]);

  return (
    <div className="max-w-4xl mx-auto p-4 md:p-8 mt-32">
      <OrderConfirmModal
        isOpen={showOrderConfirmModal}
        onClose={() => setShowOrderConfirmModal(false)}
        orderDetails={{ total, confirmedOrderDetails }}
      />
      <h1 className="text-2xl font-bold mb-6">Checkout</h1>

      <div className="space-y-4">
        {/* Login Section */}
        <div className="border rounded-lg bg-white">
          <AccordionHeader
            number="1"
            title="LOGIN"
            isCompleted={isLoggedIn}
            isActive={activeSection === 'login'}
            onClick={() => handleSectionChange('login')}
          />
          <div className={`px-4 py-3 border-t ${activeSection === 'login' ? 'block' : 'hidden'}`}>
            {renderLoginSection()}
          </div>
        </div>

        {/* Address Section */}
        <div className="border rounded-lg bg-white">
          <AccordionHeader
            number="2"
            title="DELIVERY ADDRESS"
            isCompleted={selectedAddress != null}
            isActive={activeSection === 'address'}
            onClick={() => handleSectionChange('address')}
            isDisabled={!canProceedToAddress}
          />
          <div className={`px-4 py-3 border-t ${activeSection === 'address' ? 'block' : 'hidden'}`}>
            {addressesLoading ? (
              <div className="text-center py-4">Loading addresses...</div>
            ) : (
              <div className="space-y-4">
                {addressesData?.map((address) => (
                  <div
                    key={address.id}
                    className={`p-4 border rounded-lg ${selectedAddress?.id === address.id
                      ? 'border-pink-500'
                      : 'border-gray-200'
                      }`}
                  >
                    <div className="flex justify-between items-start">
                      <div>
                        <p className="font-medium">{address.street}</p>
                        <p className="text-gray-600">
                          {address.city}, {address.state} {address.postalCode}
                        </p>
                        <p className="text-gray-600">{address.country}</p>
                        {address.isDefault && (
                          <span className="text-sm text-pink-600 mt-1">
                            Default Address
                          </span>
                        )}
                      </div>
                      <div className="flex gap-2">
                        <button
                          onClick={() => handleEditAddress(address)}
                          className="text-pink-600 text-sm"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => handleAddressDelete(address.id)}
                          className="text-pink-600 text-sm"
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                    <button
                      onClick={() => setSelectedAddress(address)}
                      className={`mt-2 w-full py-2 rounded-lg ${selectedAddress?.id === address.id
                        ? 'bg-pink-500 text-white'
                        : 'bg-gray-100 text-gray-800'
                        }`}
                    >
                      {selectedAddress?.id === address.id ? 'Selected' : 'Select Address'}
                    </button>
                  </div>
                ))}

                {!showAddressForm && (
                  <button
                    onClick={() => setShowAddressForm(true)}
                    className="w-full py-2 border-2 border-dashed border-gray-300 rounded-lg text-gray-600 hover:border-pink-500 hover:text-pink-600"
                  >
                    + Add New Address
                  </button>
                )}

                {showAddressForm && (
                  <AddressForm
                    initialData={addressFormData}
                    onSubmit={handleAddressSubmit}
                    onCancel={() => {
                      setShowAddressForm(false);
                      setAddressFormData({
                        street: '',
                        city: '',
                        state: '',
                        postalCode: '',
                        country: '',
                        isDefault: false,
                      });
                    }}
                    isPincodeLoading={isPincodeLoading}
                    pincodeError={pincodeError}
                    pincodeData={pincodeData}
                    onPostalCodeChange={handlePostalCodeChange}
                  />
                )}
              </div>
            )}
          </div>
        </div>

        {/* Order Summary */}
        <div className="border rounded-lg bg-white">
          <AccordionHeader
            number="3"
            title="ORDER SUMMARY"
            isCompleted={activeSection === 'payment'}
            isActive={activeSection === 'order'}
            onClick={() => handleSectionChange('order')}
            itemCount={cartData.length}
            isDisabled={!canProceedToOrder}
          />
          <div className={`border-t ${activeSection === 'order' ? 'block' : 'hidden'}`}>
            <div className="border rounded-lg bg-white">
              <AccordionHeader
                number="3"
                title="ORDER SUMMARY"
                isCompleted={activeSection === 'payment'}
                isActive={activeSection === 'order'}
                onClick={() =>
                  setActiveSection(activeSection === 'order' ? '' : 'order')
                }
                itemCount={cartData.length}
              />
              <div
                className={`border-t ${activeSection === 'order' ? 'block' : 'hidden'
                  }`}
              >
                {cartData.map((item) => {
                  const images = item.sku?.images?.[0]?.split(', ');
                  const firstImage = images?.[0];

                  return (
                    <div
                      key={item.id}
                      className="flex gap-4 p-4 border-b last:border-b-0"
                    >
                      <img
                        src={firstImage || '/api/placeholder/120/120'}
                        alt={item.name}
                        className="w-20 h-20 object-cover rounded-lg bg-gray-50"
                      />
                      <div className="flex-grow">
                        <div className="flex justify-between">
                          <h3 className="font-medium">
                            {item.name}
                          </h3>
                        </div>
                        <p className="text-sm text-gray-500">
                          Size: {item.sku?.variants?.size}
                        </p>
                        <p className="text-sm text-gray-500">
                          Color: {item.sku?.variants?.color}
                        </p>
                        <p className="text-sm text-gray-500">
                          Quantity: {item.quantity}
                        </p>
                        <div className="flex justify-between items-center mt-2">
                          <span className="font-medium">₹{item.sku?.price}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        {/* Payment Section */}
        <div className="border rounded-lg bg-white">
          <AccordionHeader
            number="4"
            title="PAYMENT OPTIONS"
            isActive={activeSection === 'payment'}
            onClick={() => handleSectionChange('payment')}
            isDisabled={!canProceedToPayment}
          />
          <div className={`px-4 py-3 border-t ${activeSection === 'payment' ? 'block' : 'hidden'}`}>
            <div className="space-y-4 p-4">
              {/* Payment options with consistent styling */}
              <div className="flex items-center gap-3 p-2 rounded hover:bg-gray-50">
                <input
                  type="radio"
                  name="payment"
                  id="credit-card"
                  className="w-4 h-4 text-pink-500 focus:ring-pink-500"
                  checked={selectedPayment === 'credit-card'}
                  onChange={() => handlePaymentSelect('credit-card')}
                />
                <label htmlFor="credit-card" className="flex-grow cursor-pointer">Credit Card</label>
              </div>

              <div className="flex items-center gap-3 p-2 rounded hover:bg-gray-50">
                <input
                  type="radio"
                  name="payment"
                  id="debit-card"
                  className="w-4 h-4 text-pink-500 focus:ring-pink-500"
                  checked={selectedPayment === 'debit-card'}
                  onChange={() => handlePaymentSelect('debit-card')}
                />
                <label htmlFor="debit-card" className="flex-grow cursor-pointer">Debit Card</label>
              </div>

              <div className="flex items-center gap-3 p-2 rounded hover:bg-gray-50">
                <input
                  type="radio"
                  name="payment"
                  id="upi"
                  className="w-4 h-4 text-pink-500 focus:ring-pink-500"
                  checked={selectedPayment === 'upi'}
                  onChange={() => handlePaymentSelect('upi')}
                />
                <label htmlFor="upi" className="flex-grow cursor-pointer">UPI</label>
              </div>

              <div className="flex items-center gap-3 p-2 rounded hover:bg-gray-50">
                <input
                  type="radio"
                  name="payment"
                  id="cod"
                  className="w-4 h-4 text-pink-500 focus:ring-pink-500"
                  checked={selectedPayment === 'cod'}
                  onChange={() => handlePaymentSelect('cod')}
                />
                <label htmlFor="cod" className="flex-grow cursor-pointer">Cash on Delivery</label>
              </div>

              {renderPaymentForm()}
            </div>
          </div>
        </div>

        {/* Price Details */}
        <div className="mt-4 border rounded-lg bg-white p-6">
          <h2 className="text-lg font-medium mb-4">Price Details</h2>
          <div className="space-y-3">
            <div className="flex justify-between text-gray-600">
              <span>Price ({cartData.length} {cartData.length === 1 ? 'item' : 'items'})</span>
              <span>₹{subtotal.toFixed(2)}</span>
            </div>
            {discount > 0 && (
              <div className="flex justify-between text-pink-600">
                <span>Discount</span>
                <span>-₹{discount.toFixed(2)}</span>
              </div>
            )}
            <div className="flex justify-between text-gray-600">
              <span>Delivery Fee</span>
              <span>₹{deliveryFee.toFixed(2)}</span>
            </div>
            <div className="pt-3 border-t">
              <div className="flex justify-between text-lg font-medium">
                <span>Total Amount</span>
                <span>₹{total.toFixed(2)}</span>
              </div>
            </div>
          </div>

          <button
            onClick={handlePlaceOrder}
            disabled={!selectedPayment || isProcessing}
            className={`w-full mt-6 py-3 px-4 rounded-lg text-white font-medium
              ${!selectedPayment || isProcessing
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-pink-500 hover:bg-pink-600'
              }`}
          >
            {isProcessing ? 'Processing...' : 'Place Order'}
          </button>
        </div>
      </div>
    </div>
  );
};

// Export with Redux connect
export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPage);