import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { uploadImageBaseURL } from '../../utils/endpoint';
const Carousel = ({ slides = [] }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  console.log("Carousel offers", slides);

  // Auto-rotate slides
  useEffect(() => {
    if (slides.length === 0) return;

    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 5000);

    return () => clearInterval(timer);
  }, [slides]);

  // Navigation handlers
  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className="relative w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      {/* Slides Container */}
      <div
        className="relative h-[200px] md:h-48 flex overflow-hidden"
        style={{ transform: `translateX(-${currentSlide * 100}%)` }}
      >
        {slides.map((slide, index) => (
          <div
            key={slide.id}
            className="w-full h-full flex-shrink-0 rounded-lg shadow-md flex justify-between items-center p-4 relative"
            style={{
              backgroundColor: slide.banner?.backgroundColor || '#3f53ee',
              backgroundImage: slide.banner?.backgroundImage
                ? `url(${`${uploadImageBaseURL}/get-image/${slide.banner.backgroundImage}`})`
                : 'none',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            {/* Text Content */}
            <div
              className={`flex flex-col justify-center w-full ${slide.banner?.textPosition === 'left'
                ? 'items-start text-left'
                : slide.banner?.textPosition === 'right'
                  ? 'items-end text-right'
                  : 'items-center text-center'
                }`}
              style={{
                padding: '0 20px',
              }}
            >
              {slide.banner?.title && (
                <h2 className="text-2xl md:text-5xl font-bold text-white mb-2">
                  {slide.banner.title}
                </h2>
              )}
              {slide.banner?.subtitle && (
                <p className="text-base md:text-lg text-white mb-2">
                  {slide.banner.subtitle}
                </p>
              )}
              {slide.offerValue && (
                <p className="text-lg md:text-xl font-semibold text-white mb-2">
                  {`${slide.offerValue} ${slide.offerInPercentage ? 'Off' : ''}`}
                </p>
              )}
              {slide.banner?.additionalText && (
                <p className="text-xs md:text-sm text-white">
                  {slide.banner.additionalText}
                </p>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Navigation Buttons */}
      <button
        onClick={prevSlide}
        className="absolute left-0 md:left-2 top-1/2 -translate-y-1/2 z-10 p-1 md:p-2 bg-white/30 backdrop-blur-sm rounded-full hover:bg-white/50 transition-colors"
      >
        <ChevronLeft className="w-4 h-4 md:w-6 md:h-6 text-white" />
      </button>
      <button
        onClick={nextSlide}
        className="absolute right-0 md:right-4 top-1/2 -translate-y-1/2 z-10 p-1 md:p-2 bg-white/30 backdrop-blur-sm rounded-full hover:bg-white/50 transition-colors"
      >
        <ChevronRight className="w-4 h-4 md:w-6 md:h-6 text-white" />
      </button>

      {/* Dots Navigation */}
      <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex space-x-2">
        {slides.map((slide, index) => (
          <button
            key={slide.id}
            onClick={() => goToSlide(index)}
            className={`w-3 h-3 rounded-full transition-colors ${currentSlide === index ? 'bg-white' : 'bg-white/50'
              }`}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;