// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"; 

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCsAtnIni2d7ZRdi7En6qF0DyJXuiLCL9Y",
  authDomain: "swipmart-b9616.firebaseapp.com",
  projectId: "swipmart-b9616",
  storageBucket: "swipmart-b9616.firebasestorage.app",
  messagingSenderId: "159526208832",
  appId: "1:159526208832:web:a000685804f7902964e6a5",
  measurementId: "G-7NN22J9KNJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };   