import React, { useState } from 'react';
import TabPanel from './TabPanel';
import ReviewSection from './ReviewSection';
const ProductDescription = ({ description }) => {
  return (
    <div>
      <h2>Product Description</h2>
      <div
        dangerouslySetInnerHTML={{ __html: description }}
        className="product-description"
      ></div>
    </div>
  );
};
const ProductOtherDetails = ({ reviews, description, selectedSku, formattedProduct, uploadImage }) => {
  // console.log("formattedProduct,selectedSku",formattedProduct);

  const [activeTab, setActiveTab] = useState('Product Details');
  const tabs = ['Product Details', 'Rating & Reviews', 'Shipping & Returns', 'Return Policies'];
  const reviewCount = reviews?.length || 0;

  return (
    <div className="mt-12 ">
      <TabPanel tabs={tabs} activeTab={activeTab} onTabChange={setActiveTab}>
        {activeTab === 'Product Details' && (
          <div>
            <h3 className="text-lg font-bold">Product Details</h3>
            <ProductDescription description={description} />
          </div>
        )}

        {activeTab === 'Rating & Reviews' && (
          <div>
            {/* <span className="absolute -top-1 -right-6 bg-black text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
              5
            </span> */}
            {/* {reviewCount > 0 && (
              <span className="absolute -top-1 -right-6 bg-black text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
                {5}
              </span>
            )} */}
            <ReviewSection uploadImage={uploadImage} reviews={formattedProduct?.ratings || []} selectedSku={selectedSku} />
          </div>
        )}
        {activeTab === 'Shipping & Returns' && (
          <div className="py-8">
            <h3 className="text-lg font-medium mb-4">Shipping Information</h3>
            {formattedProduct?.shippingPolicyImage ? (
              <img
                src={formattedProduct.shippingPolicyImage}
                alt="Shipping Policy"
                className="max-w-full h-auto rounded-lg shadow-md"
              />
            ) : (
              <p>No shipping policy image available</p>
            )}
          </div>
        )}

        {activeTab === 'Return Policies' && (
          <div className="py-8">
            <h3 className="text-lg font-medium mb-4">Return Policy</h3>
            {formattedProduct?.returnPolicyImage ? (
              <img
                src={formattedProduct.returnPolicyImage}
                alt="Return Policy"
                className="max-w-full h-auto rounded-lg shadow-md"
              />
            ) : (
              <p>No return policy image available</p>
            )}
          </div>
        )}
      </TabPanel>
    </div>
  );
};

export default ProductOtherDetails;