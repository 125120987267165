import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { getProductByCatId } from '../../actions/query';
import ProductCard from '../ProductCard/ProductCard';
import { Skeleton } from '../SkeltonLoader';

const SimilarProducts = ({ categoryId, currentProductId }) => { 
  const navigate = useNavigate();
  
  const { loading, error, data } = useQuery(getProductByCatId, {
    variables: { categoryId },
  });

  if (loading) {
    return (
      <div className="mt-8">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 md:gap-4">
          {[...Array(4)].map((_, i) => (
            <div key={i} className="bg-white rounded-lg p-2 md:p-4">
              <Skeleton height={200} width="100%" className="mb-4" />
              <Skeleton height={20} width="80%" className="mb-2" />
              <Skeleton height={16} width="60%" className="mb-2" />
              <Skeleton height={24} width="40%" />
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (error) return null;

  const similarProducts = data?.getProductsByCategoryId || [];
  
  // Filter out current product and limit to 4 similar products
  const filteredProducts = similarProducts
    .filter(product => product.id !== currentProductId)
    .slice(0, 4);

  if (filteredProducts.length === 0) return null;

  return (
    <div className="mt-8">
      <h2 className="text-xl font-medium mb-4">Similar Products</h2>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 md:gap-4">
        {filteredProducts.map((product) => (
          <ProductCard
            key={product.id}
            product={{
              ...product,
              price: product.skus[0]?.price.toFixed(2) || 0,
              image: product.skus[0]?.images[0]?.split(',')[0] || '',
            }}
            onClick={() => navigate(`/product/details/${product.id}`, { state: product })}
          />
        ))}
      </div>
    </div>
  );
};

export default SimilarProducts; 