import React from 'react';
import { CheckCircle, Package, IndianRupee, X } from 'lucide-react';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="relative bg-white rounded-lg shadow-xl max-w-md w-full animate-in fade-in duration-200">
        {children}
      </div>
    </div>
  );
};

const OrderConfirmModal = ({ isOpen, onClose, orderDetails }) => {
  const _orderDetails = orderDetails?.confirmedOrderDetails;

  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="p-6">
        <div className="flex flex-col items-center space-y-6">
          {/* Success Icon with updated colors */}
          <div className="rounded-full bg-pink-50 p-4">
            <CheckCircle className="h-12 w-12 text-pink-500" />
          </div>

          {/* Header */}
          <div className="text-center">
            <h2 className="text-2xl font-semibold text-gray-900">Order Confirmed!</h2>
            <p className="mt-1 text-gray-500">Thank you for your purchase</p>
          </div>

          {/* Order Details Card with updated border and background */}
          <div className="w-full bg-gray-50 rounded-lg border border-gray-200 hover:border-pink-100 transition-colors">
            <div className="p-6 space-y-4">
              {/* Order ID */}
              <div className="flex items-center space-x-3">
                <Package className="h-5 w-5 text-pink-400" />
                <div>
                  <p className="text-sm text-gray-500">Order Number</p>
                  <p className="font-medium text-gray-900">{_orderDetails?.orderNumber}</p>
                </div>
              </div>

              {/* Total Amount */}
              <div className="flex items-center space-x-3">
                <IndianRupee className="h-5 w-5 text-pink-400" />
                <div>
                  <p className="text-sm text-gray-500">Total Amount</p>
                  <p className="font-medium text-gray-900">₹{_orderDetails?.totalAmount}</p>
                </div>
              </div>
            </div>
          </div>

          {/* Close Button with updated colors */}
          <button
            onClick={onClose}
            className="w-full bg-pink-500 text-white py-2 px-4 rounded-lg hover:bg-pink-600 transition-colors duration-200"
          >
            Close
          </button>
        </div>

        {/* Close Icon Button with updated hover state */}
        <button
          onClick={onClose}
          className="absolute right-4 top-4 rounded-sm p-1 hover:bg-pink-50 transition-colors duration-200"
        >
          <X className="h-4 w-4 text-gray-500 hover:text-pink-500" />
        </button>
      </div>
    </Modal>
  );
};

export default OrderConfirmModal;