import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_ORDERS_BY_USER_ID } from '../actions/query';
import { useQuery } from '@apollo/client';
import { Skeleton } from '../components/SkeltonLoader';
import { ChevronRight } from 'lucide-react';

const OrderList = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [showFilters, setShowFilters] = useState(false);
    const [statusFilters, setStatusFilters] = useState([]);
    const [timeFilters, setTimeFilters] = useState([]);
    
    const { data, loading, error } = useQuery(GET_ORDERS_BY_USER_ID, {
        variables: { userId: parseInt(id) }
    });

    const getStatusColor = (status) => {
        switch (status) {
            case 'PROCESSING':
                return 'bg-yellow-100 text-yellow-800';
            case 'SHIPPED':
                return 'bg-blue-100 text-blue-800';
            case 'DELIVERED':
                return 'bg-green-100 text-green-800';
            case 'CANCELED':
                return 'bg-red-100 text-red-800';
            default:
                return 'bg-gray-100 text-gray-800';
        }
    };

    const getTimelineSteps = (status) => {
        const steps = [
            { label: 'Processing', status: 'PROCESSING' },
            { label: 'Shipped', status: 'SHIPPED' },
            { label: 'Delivered', status: 'DELIVERED' }
        ];

        if (status === 'CANCELED') {
            return [{ label: 'Canceled', status: 'CANCELED' }];
        }

        let activeIndex = steps.findIndex(step => step.status === status);
        if (activeIndex === -1) activeIndex = -1;

        return steps.map((step, index) => ({
            ...step,
            state: index < activeIndex ? 'completed' : index === activeIndex ? 'current' : 'upcoming'
        }));
    };

    // Add filter logic functions
    const filterOrders = (orders) => {
        let filteredOrders = [...orders];

        // Filter by status
        if (statusFilters.length > 0) {
            filteredOrders = filteredOrders.filter(order => {
                const status = order.status;
                return statusFilters.some(filter => {
                    switch (filter) {
                        case 'on-the-way':
                            return status === 'SHIPPED' || status === 'PROCESSING';
                        case 'delivered':
                            return status === 'DELIVERED';
                        case 'cancelled':
                            return status === 'CANCELED';
                        case 'returned':
                            return status === 'RETURNED';
                        default:
                            return false;
                    }
                });
            });
        }

        // Filter by time
        if (timeFilters.length > 0) {
            const now = new Date();
            filteredOrders = filteredOrders.filter(order => {
                const orderDate = new Date(parseInt(order.createdAt));
                return timeFilters.some(filter => {
                    switch (filter) {
                        case 'last-30-days':
                            const thirtyDaysAgo = new Date(now - 30 * 24 * 60 * 60 * 1000);
                            return orderDate >= thirtyDaysAgo;
                        case '2023':
                            return orderDate.getFullYear() === 2023;
                        case '2022':
                            return orderDate.getFullYear() === 2022;
                        case '2021':
                            return orderDate.getFullYear() === 2021;
                        case '2020':
                            return orderDate.getFullYear() === 2020;
                        case 'older':
                            return orderDate.getFullYear() < 2020;
                        default:
                            return false;
                    }
                });
            });
        }

        return filteredOrders;
    };

    // Handle filter changes
    const handleStatusFilterChange = (value) => {
        setStatusFilters(prev => 
            prev.includes(value) 
                ? prev.filter(item => item !== value)
                : [...prev, value]
        );
    };

    const handleTimeFilterChange = (value) => {
        setTimeFilters(prev => 
            prev.includes(value) 
                ? prev.filter(item => item !== value)
                : [...prev, value]
        );
    };

    if (loading) {
        return <Skeleton />;
    }

    const orders = data?.getOrdersByUserId || [];
    const filteredOrders = filterOrders(orders);

    return (
        <div className="max-w-4xl mx-auto p-4 md:p-8 mt-20">
            {/* Breadcrumb */}
            <nav className="flex items-center gap-2 text-sm text-gray-500 mb-6">
                <button onClick={() => navigate('/')} className="hover:text-gray-700">
                    Home
                </button>
                <ChevronRight className="w-4 h-4" />
                <span className="font-medium">My Orders</span>
            </nav>

            <div className="flex flex-col md:flex-row gap-8">
                {/* Filters Sidebar */}
                <div className="w-full md:w-80 h-fit">
                    <div className="bg-white border rounded-lg p-6 space-y-6">
                        <h2 className="text-lg font-medium">Filters</h2>
                        
                        {/* Order Status Filters */}
                        <div className="space-y-4">
                            <h3 className="font-medium text-gray-900">Order Status</h3>
                            <div className="space-y-3">
                                {['on-the-way', 'delivered', 'cancelled'].map(status => (
                                    <label key={status} className="flex items-center cursor-pointer">
                                        <input 
                                            type="checkbox" 
                                            className="form-checkbox h-4 w-4 text-red-500 rounded border-gray-300"
                                            checked={statusFilters.includes(status)}
                                            onChange={() => handleStatusFilterChange(status)}
                                        />
                                        <span className="ml-3 text-sm text-gray-700 capitalize">
                                            {status.replace('-', ' ')}
                                        </span>
                                    </label>
                                ))}
                            </div>
                        </div>

                        {/* Time Filters */}
                        <div className="space-y-4">
                            <h3 className="font-medium text-gray-900">Order Time</h3>
                            <div className="space-y-3">
                                <label className="flex items-center cursor-pointer">
                                    <input 
                                        type="checkbox" 
                                        className="form-checkbox h-4 w-4 text-red-500 rounded border-gray-300"
                                        checked={timeFilters.includes('last-30-days')}
                                        onChange={() => handleTimeFilterChange('last-30-days')}
                                    />
                                    <span className="ml-3 text-sm text-gray-700">Last 30 days</span>
                                </label>
                                {['2023', '2022', '2021', '2020', 'older'].map(year => (
                                    <label key={year} className="flex items-center cursor-pointer">
                                        <input 
                                            type="checkbox" 
                                            className="form-checkbox h-4 w-4 text-red-500 rounded border-gray-300"
                                            checked={timeFilters.includes(year)}
                                            onChange={() => handleTimeFilterChange(year)}
                                        />
                                        <span className="ml-3 text-sm text-gray-700">
                                            {year === 'older' ? 'Older' : year}
                                        </span>
                                    </label>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Orders List */}
                <div className="flex-grow">
                    <h2 className="text-xl font-medium mb-6">My Orders ({filteredOrders.length})</h2>
                    
                    {filteredOrders.length === 0 ? (
                        <div className="bg-white border rounded-lg p-8 text-center">
                            <div className="mb-4">
                                <svg className="w-16 h-16 text-gray-400 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                                </svg>
                            </div>
                            <h3 className="text-lg font-medium mb-2">No Orders Found</h3>
                            <p className="text-gray-600 mb-4">You haven't placed any orders yet.</p>
                            <button 
                                onClick={() => navigate('/')}
                                className="bg-red-500 text-white px-6 py-2 rounded-lg hover:bg-red-600 transition-colors"
                            >
                                Continue Shopping
                            </button>
                        </div>
                    ) : (
                        <div className="space-y-4">
                            {filteredOrders.map((order) => (
                                <div key={order.id} className="bg-white border rounded-lg p-6 hover:shadow-sm transition-shadow">
                                    {/* Order Header */}
                                    <div className="flex justify-between items-start mb-6">
                                        <div>
                                            <div className="flex items-center gap-3 mb-2">
                                                <h3 className="text-lg font-medium">Order #{order.id}</h3>
                                                <span className={`px-3 py-1 text-xs rounded-full font-medium ${getStatusColor(order.status)}`}>
                                                    {order.status}
                                                </span>
                                            </div>
                                            <p className="text-sm text-gray-500">
                                                Placed on {new Date(parseInt(order.createdAt)).toLocaleDateString()}
                                            </p>
                                            <p className="text-sm font-medium mt-1">
                                                Total Amount: ₹{order.totalAmount?.toFixed(2)}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Order Timeline */}
                                    <div className="mb-8">
                                        <div className="relative">
                                            <div className="absolute left-0 right-0 h-0.5 top-5 bg-gray-200">
                                                <div 
                                                    className="h-full bg-blue-500 transition-all duration-500"
                                                    style={{
                                                        width: order.status === 'DELIVERED' ? '100%' : 
                                                               order.status === 'SHIPPED' ? '66%' :
                                                               order.status === 'PROCESSING' ? '33%' : '0%'
                                                    }}
                                                />
                                            </div>
                                            <div className="relative flex justify-between">
                                                {['PROCESSING', 'SHIPPED', 'DELIVERED'].map((step, index) => (
                                                    <div key={step} className="text-center flex-1">
                                                        <div 
                                                            className={`w-10 h-10 mx-auto rounded-full flex items-center justify-center border-2 
                                                                ${order.status === step || 
                                                                  ['SHIPPED', 'DELIVERED'].includes(order.status) && step === 'PROCESSING' || 
                                                                  order.status === 'DELIVERED' && step === 'SHIPPED'
                                                                    ? 'bg-blue-500 border-blue-500' 
                                                                    : 'bg-white border-gray-300'}`}
                                                        >
                                                            {(order.status === step || 
                                                              ['SHIPPED', 'DELIVERED'].includes(order.status) && step === 'PROCESSING' || 
                                                              order.status === 'DELIVERED' && step === 'SHIPPED') && (
                                                                <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                                                </svg>
                                                            )}
                                                        </div>
                                                        <p className={`mt-2 text-sm font-medium ${
                                                            order.status === step ? 'text-blue-500' : 'text-gray-500'
                                                        }`}>
                                                            {step.charAt(0) + step.slice(1).toLowerCase()}
                                                        </p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                    {/* Addresses */}
                                    <div className="space-y-4">
                                        <div className="border-t pt-4">
                                            <h4 className="text-sm font-medium text-gray-900 mb-2">Shipping Address</h4>
                                            {order.shippingAddress ? (
                                                <p className="text-sm text-gray-600">
                                                    {`${order.shippingAddress.street}, ${order.shippingAddress.city}, ${order.shippingAddress.state}, ${order.shippingAddress.postalCode}, ${order.shippingAddress.country}`}
                                                </p>
                                            ) : (
                                                <p className="text-sm text-gray-500 italic">No shipping address available</p>
                                            )}
                                        </div>
                                        <div className="border-t pt-4">
                                            <h4 className="text-sm font-medium text-gray-900 mb-2">Billing Address</h4>
                                            {order.billingAddress ? (
                                                <p className="text-sm text-gray-600">
                                                    {`${order.billingAddress.street}, ${order.billingAddress.city}, ${order.billingAddress.state}, ${order.billingAddress.postalCode}, ${order.billingAddress.country}`}
                                                </p>
                                            ) : (
                                                <p className="text-sm text-gray-500 italic">No billing address available</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default OrderList;