import React from 'react';
import back from '../../assets/background/back.jpg'
import WriteReviewModal from './WriteReviewModal'
import { uploadImageBaseURL } from '../../utils/endpoint';

const ReviewSection = ({ reviews, selectedSku, uploadImage }) => {
  console.log("ReviewSection reviews", reviews);

  const [showwriteReviewModal, setShowWriteReviewModal] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState(null);

  const getRatingDistribution = () => {
    const distribution = { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 };
    reviews?.forEach(review => {
      distribution[Math.floor(review.rating)]++;
    });
    return distribution;
  };

  const ratingDistribution = getRatingDistribution();
  const totalReviews = reviews?.length || 0;

  const averageRating = reviews?.reduce((acc, review) => acc + review.rating, 0) / totalReviews || 0;

  const handleImageSelect = (file) => {
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
    }
  };

  return (
    <div className="p-4">
      {showwriteReviewModal && (
        <WriteReviewModal
          uploadImage={uploadImage}
          onClose={() => {
            setShowWriteReviewModal(false);
            setSelectedImage(null); // Clear selected image when modal closes
          }}
          isVisible={showwriteReviewModal}
          selectedSku={selectedSku}
          selectedImage={selectedImage}
          onImageSelect={handleImageSelect}
        />
      )}
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center gap-3">
          <h2 className="text-base font-semibold">All Reviews <span className="text-gray-500 font-normal">({totalReviews})</span></h2>
          <button className="p-2">
            <FilterIcon />
          </button>
        </div>
        <button onClick={() => setShowWriteReviewModal(true)} className="bg-pink-500 hover:bg-pink-600 transition-colors text-white px-4 py-2 rounded-full text-sm">
          Write a Review
        </button>
      </div>

      <div className="bg-gray-50 p-6 rounded-lg mb-6">
        <div className="flex items-start gap-8">
          <div className="text-center">
            <div className="text-4xl font-bold">{averageRating.toFixed(1)}</div>
            <StarRating rating={averageRating} />
            <div className="text-sm text-gray-500 mt-1">{totalReviews} reviews</div>
          </div>

          <div className="flex-1">
            {[5, 4, 3, 2, 1].map(stars => (
              <div key={stars} className="flex items-center gap-2 mb-2">
                <div className="w-12 text-sm">{stars} stars</div>
                <div className="flex-1 h-2 bg-gray-200 rounded-full overflow-hidden">
                  <div
                    className="h-full bg-pink-500 rounded-full"
                    style={{ width: `${(ratingDistribution[stars] / totalReviews * 100) || 0}%` }}
                  ></div>
                </div>
                <div className="w-12 text-sm text-gray-500">{ratingDistribution[stars]}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="space-y-6">
        {(reviews || []).map((review, index) => (
          <ReviewCard key={index} review={review} />
        ))}
      </div>
    </div>
  );
};

const ReviewCard = ({ review }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <div className="border-b pb-6">
      <div className="flex items-start gap-4 mb-4">
        {/* User Avatar */}
        <div className="w-10 h-10 rounded-full bg-gray-200 flex items-center justify-center">
          {review.profilePicture ? (
            <img
              src={`${uploadImageBaseURL}/get-image/${review.profilePicture}`}
              alt={review.name}
              className="w-full h-full rounded-full object-cover"
            />
          ) : (
            <span className="text-gray-500 text-lg">{review.name?.[0]?.toUpperCase()}</span>
          )}
        </div>

        {/* User Info and Review Content */}
        <div className="flex-1">
          <div className="flex items-center gap-2 mb-2">
            <span className="font-medium">{review.name}</span>
            {review.verified && (
              <div className="w-4 h-4 bg-green-500 rounded-full flex items-center justify-center">
                <span className="text-white text-xs">✓</span>
              </div>
            )}
          </div>

          <div className="flex items-center gap-2 mb-3">
            <StarRating rating={review.rating} />
            <span className="text-gray-400 text-sm">•</span>
            <span className="text-gray-400 text-sm">{formatDate(review.createdAt)}</span>
          </div>

          <p className="text-gray-600 text-sm leading-relaxed">{review.review}</p>

          {review.images && review.images.length > 0 && (
            <div className="flex gap-2 mt-3">
              {review.images.map((image, index) => (
                <img
                  key={index}
                  src={`${uploadImageBaseURL}/get-image/${image}`}
                  alt={`Review image ${index + 1}`}
                  className="w-16 h-16 object-cover rounded-lg cursor-pointer"
                  onClick={() => window.open(`${uploadImageBaseURL}/get-image/${image}`, '_blank')}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const FilterIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M4 4h16M4 12h16M4 20h16" />
  </svg>
);

const StarRating = ({ rating }) => (
  <div className="flex">
    {[...Array(5)].map((_, i) => (
      <Star
        key={i}
        filled={i < Math.floor(rating)}
        halfFilled={i === Math.floor(rating) && rating % 1 !== 0}
      />
    ))}
  </div>
);

const Star = ({ filled, halfFilled }) => (
  <svg className={`w-4 h-4 ${filled || halfFilled ? 'text-pink-500' : 'text-gray-200'}`} viewBox="0 0 20 20" fill="currentColor">
    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
  </svg>
);

export default ReviewSection;