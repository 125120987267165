import React from 'react';
import { 
  FaFacebookF, 
  FaTwitter, 
  FaInstagram, 
  FaTiktok, 
  FaDiscord, 
  FaPinterestP,
  FaCcVisa, 
  FaCcMastercard, 
  FaCcAmex, 
  FaCcPaypal, 
  FaCcDinersClub, 
  FaCcDiscover 
} from 'react-icons/fa';

const Footer = () => {
  const footerSections = {
      Information: [
        { name: 'About Us', link: '/about' },
        { name: 'Contact us', link: '/contact' },
        { name: 'Career', link: '/careers' },
        { name: 'My Account', link: '/account' }
      ],
      'Customer Services': [
        { name: 'Shipping', link: '/shipping' },
        { name: 'Return & Refund', link: '/returns' },
        { name: 'Privacy Policy', link: '/privacy' },
        { name: 'Terms & Conditions', link: '/terms' },
        { name: 'Orders FAQs', link: '/faqs' },
        { name: 'My Wishlist', link: '/wishlist' }
      ],   
  };

  const socialLinks = [
    { name: 'Facebook', icon: 'facebook' },
    { name: 'Twitter', icon: 'twitter' },
    { name: 'Instagram', icon: 'instagram' },
    { name: 'TikTok', icon: 'tiktok' },
    { name: 'Discord', icon: 'discord' },
    { name: 'Pinterest', icon: 'pinterest' },
  ];

  const paymentMethods = ['visa', 'mastercard', 'amex', 'paypal', 'dinersclub', 'discover'];

  return (
    <footer className="py-12 px-4 md:px-8">
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-4 gap-8">
        {/* Company Info */}
        <div>
          <h2 className="text-2xl font-bold mb-6">Swipmart</h2>
          <p className="mb-4">Rahim nagar,Lucknow</p>
          <a href="https://maps.app.goo.gl/G6J6WEx8KRXxggMh8" className="flex items-center mb-4 hover:text-gray-600" target="_blank" rel="noopener noreferrer">
            GET DIRECTION <span className="ml-2">↗</span>
          </a>
          <p className="mb-2">swipmart@gmail.com</p> 
          <p className="mb-6">6392363003</p>
          
          {/* Social Links */}
          <div className="flex gap-3">
            {socialLinks.map((social) => {
              const SocialIcon = {
                facebook: FaFacebookF,
                twitter: FaTwitter,
                instagram: FaInstagram,
                tiktok: FaTiktok,
                discord: FaDiscord,
                pinterest: FaPinterestP
              }[social.icon];

              return (
                <a
                  key={social.name}
                  href="#"
                  className="w-10 h-10 rounded-full border border-gray-300 flex items-center justify-center hover:bg-gradient-to-r hover:from-purple-600 hover:to-pink-600 hover:text-white hover:border-transparent transition-all duration-300"
                >
                  <SocialIcon className="w-4 h-4" />
                </a>
              );
            })}
          </div>
        </div>

        {/* Information & Customer Services */}
        {Object.entries(footerSections).map(([title, links]) => (
          <div key={title}>
            <h3 className="font-bold mb-6">{title}</h3>
            <ul className="space-y-4">
              {links.map((link) => (
                <li key={link.name}>
                  <a href={link.link} className="hover:text-gray-600">
                    {link.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ))}

        {/* Newsletter */}
        <div>
          <h3 className="font-bold mb-6">Newsletter</h3>
          <p className="mb-6">Sign up for our newsletter and get 10% off your first purchase</p>
          
          <div className="relative mb-4">
            <input
              type="email"
              placeholder="Enter your e-mail"
              className="w-full px-4 py-3 border border-gray-300 rounded-full pr-12"
            />
            <button className="absolute right-2 top-1/2 -translate-y-1/2 w-8 h-8 flex items-center justify-center bg-black text-white rounded-full">
              →
            </button>
          </div>

          <p className="text-sm text-gray-600">
            By clicking subscribe, you agree to the{' '}
            <a href="#" className="underline">Terms of Service</a> and{' '}
            <a href="#" className="underline">Privacy Policy</a>
          </p>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="max-w-7xl mx-auto mt-12 pt-8 border-t border-gray-200 flex flex-col md:flex-row justify-between items-center">
        <p className="text-sm text-gray-600 mb-4 md:mb-0">
          ©2025 Swipmart. All Rights Reserved.
        </p>
        
        <div className="flex items-center gap-4">
          {/* <div className="flex items-center gap-2">
            <span className="text-sm">��</span>
            <select className="text-sm bg-transparent">
              <option>Rupee</option>
            </select>
            <select className="text-sm bg-transparent">
              <option>English</option>
            </select>
          </div> */}
          
          <div className="flex gap-2">
            {paymentMethods.map((method) => {
              const PaymentIcon = {
                visa: FaCcVisa,
                mastercard: FaCcMastercard,
                amex: FaCcAmex,
                paypal: FaCcPaypal,
                dinersclub: FaCcDinersClub,
                discover: FaCcDiscover
              }[method];

              return (
                <PaymentIcon 
                  key={method} 
                  className="h-8 w-auto text-gray-600" 
                />
              );
            })}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 