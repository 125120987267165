import React from 'react';
import './InfoPage.css';

const InfoPage = ({ title, content }) => {
  return (
    <div className="info-page-container">
      <div className="info-page-wrapper">
        {/* <h1 className="info-page-title">{title}</h1> */}
        <div className="info-page-content">
          {content}
        </div>
      </div>
    </div>
  );
};

export default InfoPage; 