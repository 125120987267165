import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { addProductRating } from '../../actions/mutation';
import { baseURL } from '../../utils/endpoint';
const uploadImage = async (file) => {
  const formData = new FormData();
  formData.append('image', file);

  const response = await fetch(`${baseURL}/upload-image`, {
    method: 'POST',
    body: formData,
  });

  if (!response.ok) {
    throw new Error('Failed to upload image');
  }

  const data = await response.json();
  return data.id;
};

const WriteReviewModal = ({ selectedSku, onClose, onSuccess, isVisible }) => {
  const [authState, setAuthState] = useState(null);
  const [rating, setRating] = useState(0);
  const [hoveredRating, setHoveredRating] = useState(0);
  const [review, setReview] = useState('');
  const [imageFiles, setImageFiles] = useState([]);
  const [error, setError] = useState('');
  const fileInputRef = React.useRef(null);

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    const userEmail = localStorage.getItem('userEmail');
    const userId = localStorage.getItem('userId');

    if (authToken) {
      setAuthState({
        isLoggedIn: true,
        userId: parseInt(userId),
        email: userEmail || ''
      });
    }
  }, []);

  const { productId, id: skuId } = selectedSku || {};

  const [addProductRatings, { loading }] = useMutation(addProductRating, {
    onCompleted: () => {
      onSuccess?.();
      onClose?.();
    },
    onError: (error) => {
      setError(error.message);
    },
    refetchQueries: ['GetProductRatings', 'GetProductDetails'],
    awaitRefetchQueries: true
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!productId || !authState?.userId) {
      setError('Missing required product or user information');
      return;
    }

    try {
      // Upload images concurrently
      const imageIds = await Promise.all(
        imageFiles.map(async file => {
          const id = await uploadImage(file);
          return id.toString();
        })
      );

      addProductRatings({
        variables: {
          input: {
            productId: parseInt(productId),
            userId: parseInt(authState.userId),
            rating: parseFloat(rating),
            review,
            images: imageIds
          }
        }
      });

      setImageFiles([]);
    } catch (uploadError) {
      setError(`Image upload failed: ${uploadError.message}`);
    }
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    setImageFiles(prev => [...prev, ...files]);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const removeImage = (indexToRemove) => {
    setImageFiles(prev => prev.filter((_, index) => index !== indexToRemove));
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-lg">
        <div className="p-6 border-b">
          <h2 className="text-xl font-semibold ">Write a Review</h2>
        </div>
        <div className="p-6">
          {error && (
            <div className="mb-4 p-3 bg-red-50 border border-red-200 text-red-600 rounded-lg">
              {error}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Rating Stars */}
            <div className="flex items-center gap-2">
              <div className="text-sm font-medium">Your Rating:</div>
              <div className="flex">
                {[1, 2, 3, 4, 5].map((star) => (
                  <button
                    key={star}
                    type="button"
                    className="p-1"
                    onMouseEnter={() => setHoveredRating(star)}
                    onMouseLeave={() => setHoveredRating(0)}
                    onClick={() => setRating(star)}
                  >
                    <svg
                      className={`w-6 h-6 ${star <= (hoveredRating || rating) ? 'text-pink-500' : 'text-gray-200'}`}
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                    </svg>
                  </button>
                ))}
              </div>
            </div>

            {/* Review Text */}
            <div className="space-y-2">
              <label className="text-sm font-medium">Your Review</label>
              <textarea
                value={review}
                onChange={(e) => setReview(e.target.value)}
                placeholder="Share your thoughts about this product..."
                className="w-full h-32 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-pink-500"
              />
            </div>

            {/* Image Upload */}
            <div className="space-y-2">
              <label className="text-sm font-medium">Add Photos</label>
              <div className="flex flex-wrap gap-2">
                {imageFiles.map((file, index) => (
                  <div key={index} className="relative w-16 h-16 bg-gray-100 rounded overflow-hidden">
                    <img
                      src={URL.createObjectURL(file)}
                      alt={file.name}
                      className="w-full h-full object-cover"
                    />
                    <button
                      type="button"
                      className="absolute -top-1 -right-1 w-4 h-4 bg-pink-500 rounded-full text-white text-xs flex items-center justify-center"
                      onClick={() => removeImage(index)}
                    >
                      ×
                    </button>
                  </div>
                ))}
                <label className="w-16 h-16 border-2 border-dashed border-gray-300 rounded flex items-center justify-center cursor-pointer hover:border-gray-400">
                  <input
                    ref={fileInputRef}
                    type="file"
                    className="hidden"
                    multiple
                    accept="image/*"
                    onChange={handleImageUpload}
                  />
                  <svg className="w-6 h-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                  </svg>
                </label>
              </div>
            </div>

            {/* Action Buttons */}
            <div className="flex justify-end gap-4">
              <button
                type="button"
                className="px-4 py-2 border rounded-lg hover:bg-gray-50"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={!rating || loading}
                className="px-4 py-2 bg-pink-500 text-white rounded-lg hover:bg-pink-600 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {loading ? 'Submitting...' : 'Submit Review'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default WriteReviewModal;