import React, { useState, useRef } from 'react';

const ProductGallery = ({ images }) => {
  // Ensure images is an array of URLs
  const processedImages = images[0]?.includes(',')
    ? images[0].split(',')
    : images;

  const [selectedImage, setSelectedImage] = useState(0);
  const mainImageRef = useRef(null);

  const handleMouseMove = (event) => {
    const img = mainImageRef.current;
    if (!img) return;

    const rect = img.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    img.style.transformOrigin = `${x}px ${y}px`;
    img.style.transform = 'scale(1.5)';
    img.style.cursor = 'zoom-in';
    img.style.transition = 'transform 0.3s ease-out';
  };

  const handleMouseLeave = () => {
    const img = mainImageRef.current;
    if (!img) return;

    img.style.transform = 'scale(1)';
    img.style.cursor = 'default';
  };

  return (
    <div>
      {/* Mobile View */}
      <div className="block sm:hidden">
        <div className="relative w-full h-96">
          <img
            ref={mainImageRef}
            src={processedImages[selectedImage]}
            alt="Product main view"
            className="w-full h-full object-contain bg-gray-50 rounded-lg"
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          />
        </div>

        <div className="mt-4 flex gap-2 overflow-x-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200">
          {processedImages.map((image, index) => (
            <button
              key={index}
              className={`flex-shrink-0 w-24 h-24 border rounded-lg overflow-hidden ${selectedImage === index ? 'border-black' : 'border-gray-200'
                }`}
              onClick={() => setSelectedImage(index)}
            >
              <img
                src={image}
                alt={`Product view ${index + 1}`}
                className="w-full h-full object-cover"
              />
            </button>
          ))}
        </div>
      </div>

      {/* Desktop View */}
      <div className="hidden md:flex gap-4">
        <div className="flex flex-col gap-4">
          {processedImages.map((image, index) => (
            <button
              key={index}
              className={`w-20 h-20 border rounded-lg overflow-hidden ${selectedImage === index ? 'border-black' : 'border-gray-200'
                }`}
              onClick={() => setSelectedImage(index)}
            >
              <img
                src={image}
                alt={`Product view ${index + 1}`}
                className="w-full h-full object-cover"
              />
            </button>
          ))}
        </div>
        <div className="flex-1 bg-gray-50 rounded-lg overflow-hidden">
          <img
            ref={mainImageRef}
            src={processedImages[selectedImage]}
            alt="Product main view"
            className="w-full h-full object-contain"
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductGallery;