import React from 'react';
import PropTypes from 'prop-types';

const ProductCard = ({ product = {}, onClick }) => {
  // console.log("ProductCard", product);

  const price = parseFloat(product?.price) || 0;
  const originalPrice = parseFloat(product?.originalPrice) || 0;

  const discountPercentage = originalPrice > 0
    ? Math.round(((originalPrice - price) / originalPrice) * 100)
    : 0;

  const isDiscounted = originalPrice > 0 && price < originalPrice;
  const imageUrl = product?.skus?.[0]?.images?.[0] || product?.images?.[0];

  const isOutOfStock = !product?.skus?.some(sku => sku.stock > 0);

  const handleImageError = (e) => {
    e.target.src = 'https://via.placeholder.com/150';
  };

  return (
    <div
      onClick={onClick}
      className="group bg-white rounded-lg hover:shadow-lg transition-all duration-300 overflow-hidden cursor-pointer w-full max-w-[200px]"
    >
      <div className="relative pt-[80%] w-full overflow-hidden bg-gray-50">
        {imageUrl && (
          <img
            src={imageUrl}
            alt={product.name || 'Product Image'}
            className="absolute inset-0 h-full w-full object-cover transform transition-transform duration-300 group-hover:scale-105"
            loading="lazy"
          // onError={handleImageError}
          />
        )}

        {/* Out of Stock Overlay */}
        {isOutOfStock && (
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <span className="text-white text-sm font-medium px-2 py-1 bg-black bg-opacity-75 rounded">
              Out of Stock
            </span>
          </div>
        )}

        {/* Rating Badge - Adjusted size */}
        {product.rating > 0 && (
          <div className="absolute top-1 left-1 bg-white px-1 py-0.5 rounded flex items-center space-x-0.5">
            <span className="text-xs text-yellow-500">★</span>
            <span className="text-xs font-medium">{product.rating.toFixed(1)}</span>
          </div>
        )}
      </div>

      <div className="p-2 space-y-0.5">
        {/* Brand Name */}
        <p className="text-xs font-medium text-gray-900 line-clamp-1">
          {product.brand || 'Brand Name'}
        </p>

        <h3 className="text-xs text-gray-600 line-clamp-1">
          {product.name}
        </h3>

        <div className="flex flex-wrap items-center gap-1">
          <span className="text-xs font-semibold text-gray-900">
            ₹{price.toFixed(0)}
          </span>
          {isDiscounted && (
            <>
              <span className="text-xs text-gray-500 line-through">
                ₹{originalPrice.toFixed(0)}
              </span>
              <span className="text-xs text-pink-500">
                ({discountPercentage}% OFF)
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

ProductCard.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    brand: PropTypes.string,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    originalPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    images: PropTypes.arrayOf(PropTypes.string),
    stock: PropTypes.number,
    rating: PropTypes.number,
    skus: PropTypes.arrayOf(
      PropTypes.shape({
        images: PropTypes.arrayOf(PropTypes.string)
      })
    ),
    offer: PropTypes.shape({
      offerValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  }),
  onClick: PropTypes.func,
};

ProductCard.defaultProps = {
  product: {
    name: '',
    brand: '',
    price: 0,
    originalPrice: 0,
    images: [],
    skus: [],
    offer: null,
    stock: 0,
    rating: 0
  },
  onClick: () => { },
};

export default ProductCard;