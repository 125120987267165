import React, { useState, useRef, useEffect } from 'react';

const AddressForm = ({ 
  initialData = {
    street: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    isDefault: false
  },
  onSubmit,
  onCancel,
  isPincodeLoading,
  pincodeError,
  pincodeData,
  onChange
}) => {
  const [formData, setFormData] = useState(initialData || {
    street: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    isDefault: false
  });
  const streetInputRef = useRef(null);

  useEffect(() => {
    if (streetInputRef.current) {
      streetInputRef.current.focus();
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // Special handling for postal code
    if (name === 'postalCode') {
      // Only allow numbers and limit to 6 digits
      const cleanValue = value.replace(/\D/g, '').slice(0, 6);
      setFormData(prev => ({
        ...prev,
        [name]: cleanValue,
        // Reset city and state when postal code changes
        ...(cleanValue.length !== 6 && {
          city: '',
          state: ''
        })
      }));
      
      // Notify parent component of postal code change
      onChange?.(name, cleanValue);
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
      onChange?.(name, value);
    }
  };

  // Update local form data when initialData changes (for auto-completion)
  useEffect(() => {
    setFormData(initialData);
  }, [initialData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4 mt-4">
      <div>
        <label htmlFor="street" className="block text-sm font-medium text-gray-700 mb-1">
          Street Address
        </label>
        <input
          id="street"
          ref={streetInputRef}
          type="text"
          name="street"
          value={formData.street}
          onChange={handleChange}
          className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          required
        />
      </div>
      
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label htmlFor="postalCode" className="block text-sm font-medium text-gray-700 mb-1">
            PIN Code
          </label>
          <input
            id="postalCode"
            type="text"
            name="postalCode"
            value={formData.postalCode}
            onChange={handleChange}
            className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            maxLength={6}
            required
          />
          {isPincodeLoading && (
            <p className="text-sm text-gray-500 mt-1">Fetching address details...</p>
          )}
          {pincodeError && (
            <p className="text-sm text-red-500 mt-1">Invalid PIN Code</p>
          )}
        </div>
        <div>
          <label htmlFor="city" className="block text-sm font-medium text-gray-700 mb-1">
            City
          </label>
          <input
            id="city"
            type="text"
            name="city"
            value={formData.city}
            onChange={handleChange}
            className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
            readOnly={!!pincodeData}
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label htmlFor="state" className="block text-sm font-medium text-gray-700 mb-1">
            State
          </label>
          <input
            id="state"
            type="text"
            name="state"
            value={formData.state}
            onChange={handleChange}
            className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
            readOnly={!!pincodeData}
          />
        </div>
        <div>
          <label htmlFor="country" className="block text-sm font-medium text-gray-700 mb-1">
            Country
          </label>
          <input
            id="country"
            type="text"
            name="country"
            value="India"
            onChange={handleChange}
            className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-gray-50"
            required
            readOnly
          />
        </div>
      </div>

      {/* <div className="flex items-center gap-2">
        <input
          id="isDefault"
          type="checkbox"
          name="isDefault"
          checked={formData.isDefault}
          onChange={handleChange}
          className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
        />
        <label htmlFor="isDefault" className="text-sm text-gray-700">
          Set as default address
        </label>
      </div> */}

      <div className="flex gap-4">
        <button
          type="submit"
          className="flex-1 bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          {initialData.id ? 'Update Address' : 'Add Address'}
        </button>
        <button
          type="button"
          onClick={onCancel}
          className="flex-1 bg-gray-200 text-gray-800 py-2 rounded-lg hover:bg-gray-300 focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
        >
          Cancel
        </button>
      </div>
    </form>
  );
};

export default AddressForm;