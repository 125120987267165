const TabPanel = ({ tabs, activeTab, onTabChange, children }) => {
  return (
    <>
      <div className="border-b ">
        <div className="flex gap-8">
          {tabs.map((tab) => (
            <button
              key={tab}
              onClick={() => onTabChange(tab)}
              className={`py-4 ${
                tab === activeTab ? 'border-b-2 border-black' : ''
              }`}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>

      <div className="mt-8">
        {children}
      </div>
    </>
  );
};

export default TabPanel; 