import ProductCard from '../ProductCard/ProductCard';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const ProductSection = ({ title, products }) => {
  console.log("ProductSection products", products)
  const navigate = useNavigate();

  const onClick = (id) => {
    navigate(`/product/details/${id}`);
  };


  return (
    <section className="py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="mb-4">
          <div className="flex justify-between items-center mb-2 sm:mb-4">
            <h2 className="text-lg sm:text-xl font-semibold">{title}</h2>
            <Link to={`/products/all`} className="text-sm text-gray-600 hover:text-gray-900">
              View All
            </Link>
          </div>
          <div className="block sm:hidden">
            <Swiper
              spaceBetween={8}
              slidesPerView={2}
              className="w-full"
            >
              {products.map((product) => (
                <SwiperSlide key={product.id}>
                  <ProductCard
                    product={product}
                    onClick={() => onClick(product.id)}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="hidden sm:grid sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 sm:gap-4">
            {products.map((product) => (
              <ProductCard
                key={product.id}
                product={product}
                onClick={() => onClick(product.id)}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductSection;
