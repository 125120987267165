import React, { useState, useEffect, useCallback, useMemo } from 'react';
import ProductCard from '../components/ProductCard/ProductCard';
import { useQuery } from '@apollo/client';
import { getProductByCatId } from '../actions/query';
import { useNavigate } from 'react-router-dom';
import { getCategoriesQuery } from '../actions/query';
import { Skeleton } from '../components/SkeltonLoader';
import { getAllOffersQuery } from '../actions/query';
import Carousel from '../components/Carosel/Carosel';
import { gql } from '@apollo/client';
import TreeView, { flattenTree } from "react-accessible-treeview";
import { useParams } from 'react-router-dom'
import { uploadImageBaseURL } from '../utils/endpoint';
const FilterSection = ({ products, onFilterChange, isLoading }) => {
  const { data: categoriesData, loading: categoriesLoading } = useQuery(getCategoriesQuery);
  // Get category ID from URL
  const { id } = useParams(); // Extracts categoryId from the URL

  const categoryIdNumber = parseInt(id);

  // Initialize selectedCategories with URL category if valid
  const [selectedCategories, setSelectedCategories] = useState(() => {
    return !isNaN(categoryIdNumber) ? [categoryIdNumber] : [];
  });

  // Initialize with explicit empty values
  const [selectedFilters, setSelectedFilters] = useState({
    priceRange: { min: '', max: '' },
    ratings: new Set(),
    variantFilters: {}
  });

  // Initialize variant types
  const variantTypes = useMemo(() => {
    const types = new Map();
    products?.forEach((product) => {
      product.skus?.forEach((sku) => {
        if (sku.variants) {
          Object.entries(sku.variants).forEach(([key, value]) => {
            if (!types.has(key)) {
              types.set(key, new Set());
            }
            types.get(key).add(value);
          });
        }
      });
    });
    return types;
  }, [products]);

  const clearFilters = () => {
    setSelectedCategories([]);
    setSelectedFilters({
      priceRange: { min: '', max: '' }, // Reset to empty strings
      ratings: new Set(),
      variantFilters: {}
    });
  };

  const handleFilterChange = (type, value, variantKey = null) => {
    setSelectedFilters((prev) => {
      const newFilters = { ...prev };

      if (type === 'priceRange') {
        newFilters.priceRange = {
          ...prev.priceRange,
          ...value,
        };
      } else if (type === 'variant') {
        const variantSet = new Set(newFilters.variantFilters[variantKey] || new Set());
        if (variantSet.has(value)) {
          variantSet.delete(value);
        } else {
          variantSet.add(value);
        }
        newFilters.variantFilters = {
          ...newFilters.variantFilters,
          [variantKey]: variantSet
        };
      } else {
        const set = new Set(prev[type]);
        if (set.has(value)) {
          set.delete(value);
        } else {
          set.add(value);
        }
        newFilters[type] = set;
      }

      return newFilters;
    });
  };

  const PriceRangeInputs = () => {
    const priceRanges = [
      { value: 0, label: '₹0' },
      { value: 500, label: '₹500' },
      { value: 1000, label: '₹1000' },
      { value: 2000, label: '₹2000' },
      { value: 5000, label: '₹5000' },
      { value: 10000, label: '₹10000' },
      { value: 20000, label: '₹20000' },
    ];

    return (
      <div className="space-y-4">
        <div className="flex items-center gap-2">
          <select
            className="border p-2 rounded w-24 focus:ring-pink-500 focus:border-pink-500"
            value={selectedFilters.priceRange.min ?? ''}
            onChange={(e) => handleFilterChange('priceRange', { min: e.target.value })}
          >
            <option value="">Min</option>
            {priceRanges.map(({ value, label }) => (
              <option key={value} value={value.toString()}>
                {label}
              </option>
            ))}
          </select>
          <span>to</span>
          <select
            className="border p-2 rounded w-24 focus:ring-pink-500 focus:border-pink-500"
            value={selectedFilters.priceRange.max ?? ''}
            onChange={(e) => handleFilterChange('priceRange', { max: e.target.value })}
          >
            <option value="">Max</option>
            {priceRanges
              .filter(range => !selectedFilters.priceRange.min || range.value >= Number(selectedFilters.priceRange.min))
              .map(({ value, label }) => (
                <option key={value} value={value.toString()}>
                  {label}
                </option>
              ))}
            <option value="999999">₹20000+</option>
          </select>
        </div>
      </div>
    );
  };

  const handleCategoryChange = (categoryId) => {
    console.log('Category selected:', categoryId);
    setSelectedCategories(prev => {
      const newCategories = prev.includes(categoryId)
        ? prev.filter(id => id !== categoryId)
        : [categoryId];
      console.log('Updated categories:', newCategories);
      return newCategories;
    });
  };

  // Update hasActiveFilters to check for non-empty values
  const hasActiveFilters = useCallback(() => {
    return (
      selectedFilters.priceRange.min !== '' ||
      selectedFilters.priceRange.max !== '' ||
      selectedFilters.ratings.size > 0 ||
      Object.values(selectedFilters.variantFilters).some(set => set.size > 0) ||
      selectedCategories.length > 0
    );
  }, [selectedFilters, selectedCategories]);

  // Update applyFilters to handle ratings correctly
  const applyFilters = useCallback(() => {
    if (!Array.isArray(products)) return;

    console.log('Applying filters with selected categories:', selectedCategories);

    const filtered = products.filter((product) => {
      // Debug logs
      console.log('Filtering product:', product.name);
      console.log('Product categories:', product.categoryId);
      console.log('Selected categories:', selectedCategories);

      // Category filter - if categories are selected, product must match one of them
      if (selectedCategories.length > 0 && !selectedCategories.includes(product.categoryId)) {
        console.log('Product filtered out due to category mismatch');
        return false;
      }

      const productSkus = product.skus || [];
      const productMainPrice = parseFloat(product.price) || 0;
      const productRating = product.rating ? parseFloat(product.rating) : 0;

      // Debug logs
      console.log('Raw Product Rating:', product.rating);
      console.log('Parsed Product Rating:', productRating);
      console.log('Selected Ratings:', Array.from(selectedFilters.ratings));

      // Price filter logic
      const effectiveMinPrice = selectedFilters.priceRange.min === '' ? 0 : Number(selectedFilters.priceRange.min);
      const effectiveMaxPrice = selectedFilters.priceRange.max === '' ? 999999 : Number(selectedFilters.priceRange.max);

      const matchesPrice = (productMainPrice > 0 &&
        productMainPrice >= effectiveMinPrice &&
        productMainPrice <= effectiveMaxPrice) ||
        productSkus.some(sku => {
          const skuPrice = parseFloat(sku.price);
          const maxPrice = effectiveMaxPrice === 999999 ? Infinity : effectiveMaxPrice;
          return skuPrice >= effectiveMinPrice && skuPrice <= maxPrice;
        });

      if (!matchesPrice) return false;

      // Variant filter logic
      const matchesVariants = Object.entries(selectedFilters.variantFilters).every(([variantKey, selectedValues]) => {
        if (!selectedValues || selectedValues.size === 0) return true;
        return productSkus.some(sku =>
          sku.variants &&
          sku.variants[variantKey] &&
          selectedValues.has(sku.variants[variantKey])
        );
      });

      // Modified rating filter logic
      const matchesRatings =
        selectedFilters.ratings.size === 0 || // if no ratings selected, show all products
        (productRating > 0 && // only check ratings for products that have a rating
          Array.from(selectedFilters.ratings).some(rating => {
            const matches = Math.floor(productRating) >= rating;
            console.log(`Checking rating ${rating} against product rating ${productRating}: ${matches}`);
            return matches;
          }));

      console.log('Final rating match:', matchesRatings);

      return matchesPrice && matchesVariants && matchesRatings;
    });
    console.log("Total filtered products:", filtered.length);
    onFilterChange(filtered);
  }, [products, selectedFilters, onFilterChange, selectedCategories]);

  // Update filters when selection changes
  useEffect(() => {
    applyFilters();
  }, [applyFilters]);

  const styles = ['Casual', 'Formal', 'Party', 'Gym'];
  const ratings = [
    { label: '4★ & above', value: 4 },
    { label: '3★ & above', value: 3 },
    { label: '2★ & above', value: 2 },
    { label: '1★ & above', value: 1 },
  ];

  const [openSections, setOpenSections] = useState({
    categories: true,
    price: true,
    brand: true,
    ratings: true,
  });

  const toggleSection = (section) => {
    setOpenSections((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  const RatingFilter = () => {
    const ratings = [5, 4, 3, 2, 1];

    return (
      <div className="space-y-2">
        <h3 className="font-medium text-gray-700">Rating</h3>
        {ratings.map((rating) => (
          <div key={rating} className="flex items-center space-x-2">
            <input
              type="checkbox"
              id={`rating-${rating}`}
              checked={selectedFilters.ratings.has(rating)}
              onChange={() => handleFilterChange('ratings', rating)}
              className="rounded border-gray-300 text-pink-500 focus:ring-pink-500 focus:ring-offset-0"
            />
            <label htmlFor={`rating-${rating}`} className="flex items-center space-x-1">
              <div className="flex items-center">
                {[...Array(rating)].map((_, index) => (
                  <svg
                    key={index}
                    className="w-4 h-4 text-yellow-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                ))}
                {[...Array(5 - rating)].map((_, index) => (
                  <svg
                    key={index}
                    className="w-4 h-4 text-gray-300"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                ))}
              </div>
              <span className="text-sm text-gray-600">& up</span>
            </label>
          </div>
        ))}
      </div>
    );
  };

  // Update the CategoryTree component
  const CategoryTree = ({ categories, selectedCategories, onCategorySelect }) => {
    // Transform categories into the format expected by react-accessible-treeview
    const buildCategoryTree = useMemo(() => {
      const transformData = (categories) => {
        const processNode = (node) => {
          return {
            id: node.id,
            name: node.name,
            description: node.description,
            images: node.images,
            children: node.children?.map(processNode) || [],
            parent: node.parentId // Add parent ID to track hierarchy
          };
        };

        return {
          name: "root",
          children: categories.map(processNode)
        };
      };

      return transformData(categories);
    }, [categories]);

    // Get the parent IDs of selected categories
    const getParentIds = useCallback((flatData, selectedId) => {
      const parentIds = new Set();
      let currentNode = flatData.find(node => node.id === selectedId);

      while (currentNode && currentNode.parent) {
        parentIds.add(currentNode.parent);
        currentNode = flatData.find(node => node.id === currentNode.parent);
      }

      return Array.from(parentIds);
    }, []);

    const data = useMemo(() => flattenTree(buildCategoryTree), [buildCategoryTree]);

    // Calculate expanded state based on selected categories
    const defaultExpandedIds = useMemo(() => {
      const expandedIds = new Set();

      selectedCategories.forEach(selectedId => {
        const parentIds = getParentIds(data, selectedId);
        parentIds.forEach(id => expandedIds.add(id));
      });

      return Array.from(expandedIds);
    }, [selectedCategories, data, getParentIds]);

    return (
      <div className="categories-tree">
        <TreeView
          data={data}
          aria-label="Categories"
          defaultExpandedIds={defaultExpandedIds}
          nodeRenderer={({
            element,
            isBranch,
            isExpanded,
            getNodeProps,
            level,
          }) => (
            <div
              {...getNodeProps()}
              style={{ paddingLeft: 20 * (level - 1) }}
              className={`flex items-center py-2 cursor-pointer hover:bg-gray-50 transition-colors duration-150 ${selectedCategories.includes(element.id) ? 'bg-pink-50' : ''
                }`}
            >
              {/* Branch arrow or checkbox container */}
              <div className="w-6 flex justify-center">
                {isBranch ? (
                  <span
                    className={`text-gray-400 transition-transform duration-200 ${isExpanded ? 'rotate-90' : ''
                      } inline-block`}
                  >
                    ▶
                  </span>
                ) : (
                  <input
                    type="checkbox"
                    checked={selectedCategories.includes(element.id)}
                    onChange={() => onCategorySelect(element.id)}
                    className="rounded border-gray-300 text-pink-500 focus:ring-pink-500"
                    onClick={(e) => e.stopPropagation()}
                  />
                )}
              </div>

              {/* Category content */}
              <div className="flex items-center gap-3 flex-1 min-w-0">
                {/* Image container */}
                {element.images?.[0] && (
                  <div className="w-8 h-8 flex-shrink-0 rounded-md overflow-hidden border border-gray-200">
                    <img
                      src={`${uploadImageBaseURL}/get-image/${element.images[0]}`}
                      alt={element.name}
                      className="w-full h-full object-cover"
                      onError={(e) => {
                        e.target.src = '/placeholder-image.png';
                        e.target.onerror = null;
                      }}
                    />
                  </div>
                )}

                {/* Category name and metadata */}
                <div className="flex-1 min-w-0">
                  <div className="flex items-center gap-2">
                    <span className="text-sm font-medium text-gray-700 truncate">
                      {element.name}
                    </span>
                    {isBranch && (
                      <span className="text-xs text-gray-400">
                        ({element.children?.length || 0})
                      </span>
                    )}
                  </div>
                  {element.description && (
                    <p className="text-xs text-gray-500 truncate">
                      {element.description}
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}
          className="custom-tree"
        />

        <style jsx>{`
          .custom-tree {
            --rct-color-tree-bg: transparent;
            --rct-color-tree-focus: transparent;
            --rct-item-height: auto;
          }

          .categories-tree {
            max-height: 400px;
            overflow-y: auto;
          }
        `}</style>
      </div>
    );
  };

  // Show loading state when initial products are loading or categories are loading
  if (isLoading || categoriesLoading) {
    return (
      <div className="w-72 bg-white p-4">
        <Skeleton height={24} width="100%" className="mb-4" />
        <Skeleton height={16} width="80%" className="mb-2" />
        <Skeleton height={16} width="70%" className="mb-2" />
        <Skeleton height={16} width="75%" className="mb-4" />
        <Skeleton height={24} width="100%" className="mb-4" />
        <Skeleton height={16} width="60%" className="mb-2" />
        <Skeleton height={16} width="65%" className="mb-2" />
        <Skeleton height={16} width="70%" />
      </div>
    );
  }

  return (
    <div className="w-72 bg-white">
      <div className="p-4 border-b flex justify-between items-center">
        <h2 className="font-medium">Filters</h2>
        {hasActiveFilters() && (
          <button
            onClick={clearFilters}
            className="text-sm text-pink-500 hover:text-pink-700"
          >
            Clear All
          </button>
        )}
      </div>

      <div className="p-4 border-b">
        <h3 className="font-medium mb-3">CATEGORIES</h3>
        {!categoriesLoading && (
          <CategoryTree
            categories={categoriesData?.getCategories || []}
            selectedCategories={selectedCategories}
            onCategorySelect={handleCategoryChange}
          />
        )}
      </div>

      <div className="p-4 border-b">
        <h3 className="font-medium mb-3">PRICE</h3>
        <PriceRangeInputs />
      </div>

      {/* Replace BRAND section with Variants */}
      {Array.from(variantTypes.entries())
        .map(([variantKey, values]) => (
          <div key={variantKey} className="p-4 border-b">
            <h3 className="font-medium mb-3 uppercase">{variantKey}</h3>
            <div className="grid grid-cols-2 gap-2">
              {Array.from(values).map((value) => (
                <div
                  key={`${variantKey}-${value}`}
                  className={`flex items-center p-2 border rounded cursor-pointer transition-all
                    ${selectedFilters.variantFilters[variantKey]?.has(value)
                      ? 'border-pink-500 bg-pink-50 text-pink-600'
                      : 'border-gray-200 hover:border-gray-300'
                    }`}
                  onClick={() => handleFilterChange('variant', value, variantKey)}
                >
                  <input
                    type="checkbox"
                    id={`${variantKey}-${value}`}
                    className="mr-2 rounded border-gray-300 text-pink-500 focus:ring-pink-500 focus:ring-offset-0"
                    checked={selectedFilters.variantFilters[variantKey]?.has(value)}
                    onChange={() => { }} // Handle change through parent div click
                  />
                  <label
                    htmlFor={`${variantKey}-${value}`}
                    className="capitalize text-sm cursor-pointer flex-1"
                  >
                    {value}
                  </label>
                </div>
              ))}
            </div>
          </div>
        ))}

      {/* Ratings section */}
      <div className="p-4 border-b">
        <h3 className="font-medium mb-3">CUSTOMER RATINGS</h3>
        <RatingFilter />
      </div>
    </div>
  );
};

// Add this new mobile filter drawer component
const MobileFilterDrawer = ({ isOpen, onClose, children }) => {
  return (
    <div
      className={`fixed inset-0 z-50 lg:hidden transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
    >
      <div className="absolute inset-0 bg-black bg-opacity-50" onClick={onClose} />
      <div
        className={`absolute right-0 top-0 h-full w-[300px] bg-white transform transition-transform duration-300 ${isOpen ? 'translate-x-0' : 'translate-x-full'
          }`}
      >
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="font-medium text-lg">Filters</h2>
          <button onClick={onClose} className="p-2">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="overflow-y-auto h-[calc(100%-64px)]">
          {children}
        </div>
      </div>
    </div>
  );
};

const ProductListing = () => {
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const navigate = useNavigate();

  // Add pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 24;

  // Get category ID from URL
  const categoryId = window.location.href.split('/').pop();
  const categoryIdNumber = parseInt(categoryId, 10);

  // Add the categories query here
  const { data: categoriesData } = useQuery(getCategoriesQuery);

  const { loading, error, data } = useQuery(getProductByCatId, {
    variables: { categoryId: null },
  });

  const allProducts = data?.getProductsByCategoryId || [];
  // console.log("allProducts1211", allProducts);

  // Add this function to get all child category IDs recursively
  const getAllChildCategoryIds = useCallback((categories, parentId) => {
    const childIds = [];

    const findChildren = (id) => {
      categories?.forEach(category => {
        if (category.parentId === id) {
          childIds.push(category.id);
          findChildren(category.id);
        }
      });
    };

    findChildren(parentId);
    return childIds;
  }, []);

  // Modify the useEffect that filters products by adding proper dependencies
  useEffect(() => {
    if (!isNaN(categoryIdNumber) && allProducts.length > 0 && categoriesData?.getCategories) {
      const childCategoryIds = getAllChildCategoryIds(categoriesData.getCategories, categoryIdNumber);
      const relevantCategoryIds = [categoryIdNumber, ...childCategoryIds];
      
      const filtered = allProducts.filter(product =>
        relevantCategoryIds.includes(product.categoryId)
      );
      setFilteredProducts(filtered);
    } else if (allProducts.length > 0) { // Only set if we have products
      setFilteredProducts(allProducts);
    }
  }, [allProducts, categoryIdNumber, categoriesData, getAllChildCategoryIds]); // Add all dependencies

  // Remove the scroll to top effect or modify it to only run when needed
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [currentPage]); // Only scroll when page changes

  // Add query for offers/slider data
  const { data: offers } = useQuery(getAllOffersQuery, {
    fetchPolicy: 'cache-first'
  });

  const sliderOffers = offers?.getAllOffers?.filter(offer => offer?.banner?.style === "Slider");

  // Calculate pagination values
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  // Add sort state
  const [sortBy, setSortBy] = useState('popularity'); // 'popularity', 'priceLowToHigh', 'priceHighToLow', 'newest'

  // Add sorting function
  const getSortedProducts = useCallback((products) => {
    switch (sortBy) {
      case 'priceLowToHigh':
        return [...products].sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
      case 'priceHighToLow':
        return [...products].sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
      case 'newest':
        return [...products].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      case 'popularity':
      default:
        return products; // Assuming products are already sorted by popularity from the API
    }
  }, [sortBy]);

  const [showMobileFilters, setShowMobileFilters] = useState(false);

  if (loading) {
    return (
      <div className="bg-slate-200 min-h-screen">
        <div className="max-w-7xl mx-auto p-6">
          <div className="flex gap-8">
            <div className="w-64 flex-shrink-0 mr-4">
              <Skeleton height={600} width="100%" className="rounded" />
            </div>
            <div className="flex-grow grid grid-cols-3 gap-6">
              {[...Array(9)].map((_, i) => (
                <div key={i} className="bg-white rounded-lg p-4">
                  <Skeleton height={200} width="100%" className="mb-4" />
                  <Skeleton height={20} width="80%" className="mb-2" />
                  <Skeleton height={16} width="60%" className="mb-2" />
                  <Skeleton height={24} width="40%" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error)
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-slate-200">
        <h2 className="text-xl font-medium text-gray-800 mb-4">Unable to load products</h2>
        <p className="text-gray-600 mb-6">Please try again later</p>
        <button
          onClick={() => navigate(-1)}
          className="px-6 py-2 bg-black text-white rounded-md hover:bg-gray-800"
        >
          Return Home
        </button>
      </div>
    );

  const NoProducts = () => (
    <div className="flex flex-col items-center justify-center py-12">
      <svg
        className="w-16 h-16 text-gray-400 mb-4"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M4 6h16M4 10h16M4 14h16M4 18h16"
        />
      </svg>
      <h3 className="text-xl font-medium text-gray-800 mb-2">No products found</h3>
      <p className="text-gray-600 mb-6">Try adjusting your filters or browse our other categories</p>
      <button
        onClick={() => navigate('/')}
        className="px-6 py-2 bg-black text-white rounded-md hover:bg-gray-800"
      >
        Browse Categories
      </button>
    </div>
  );

  return (
    <div className="bg-gray-50 min-h-screen pt-16 sm:pt-24">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        {/* Breadcrumb - Hide on mobile */}
        <nav className="hidden sm:flex mb-6 overflow-x-auto whitespace-nowrap" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-2">
            <li>
              <a href="/" className="text-gray-500 hover:text-gray-700">Home</a>
            </li>
            <li className="flex items-center">
              <svg className="w-4 h-4 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
              </svg>
              <span className="ml-2 text-gray-500">Products</span>
            </li>
          </ol>
        </nav>

        {/* Header Section - More compact on mobile */}
        <div className="mb-3 sm:mb-8">
          <h1 className="text-lg sm:text-2xl font-bold text-gray-900 mb-1 sm:mb-2">
            All Products
          </h1>
          <p className="text-xs sm:text-sm text-gray-600">
            Showing {indexOfFirstProduct + 1} - {Math.min(indexOfLastProduct, filteredProducts.length)} of {filteredProducts.length} products
          </p>
        </div>

        {/* Main Content */}
        <div className="flex flex-col lg:flex-row gap-4 sm:gap-8">
          {/* Desktop Filters - hidden on mobile */}
          <div className="hidden lg:block w-64 flex-shrink-0">
            <div className="sticky top-24">
              <FilterSection
                products={allProducts}
                onFilterChange={setFilteredProducts}
                isLoading={loading}
              />
            </div>
          </div>

          {/* Mobile Filter Drawer */}
          <MobileFilterDrawer
            isOpen={showMobileFilters}
            onClose={() => setShowMobileFilters(false)}
          >
            <FilterSection
              products={allProducts}
              onFilterChange={setFilteredProducts}
              isLoading={loading}
            />
          </MobileFilterDrawer>

          {/* Products Section */}
          <div className="flex-1">
            {/* Sort Controls - Simplified for mobile */}
            <div className="bg-white rounded-lg shadow-sm p-2 sm:p-4 mb-3 sm:mb-6">
              <div className="flex items-center justify-between gap-2">
                {/* Mobile Sort Dropdown */}
                <div className="flex-1 sm:hidden">
                  <select
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    className="w-full rounded-md border-gray-300 text-sm focus:border-pink-500 focus:ring-pink-500"
                  >
                    <option value="popularity">Popular</option>
                    <option value="priceLowToHigh">Price: Low to High</option>
                    <option value="priceHighToLow">Price: High to Low</option>
                    <option value="newest">Newest</option>
                  </select>
                </div>

                {/* Desktop Sort Buttons - hidden on mobile */}
                <div className="hidden sm:flex flex-1 overflow-x-auto">
                  <div className="flex items-center gap-2 min-w-max">
                    <span className="text-sm font-medium text-gray-700">Sort By:</span>
                    <div className="flex flex-wrap gap-2">
                      {['popularity', 'priceLowToHigh', 'priceHighToLow', 'newest'].map((option) => (
                        <button
                          key={option}
                          onClick={() => setSortBy(option)}
                          className={`px-2 py-1 text-xs sm:text-sm rounded-full transition-colors whitespace-nowrap
                            ${sortBy === option
                              ? 'bg-pink-500 text-white'
                              : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                            }`}
                        >
                          {option === 'popularity' && 'Popular'}
                          {option === 'priceLowToHigh' && 'Price: Low to High'}
                          {option === 'priceHighToLow' && 'Price: High to Low'}
                          {option === 'newest' && 'Newest'}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>

                {/* Mobile Filter Button */}
                <button
                  className="lg:hidden flex items-center justify-center w-10 h-10 rounded-full bg-gray-100 text-gray-700 hover:bg-gray-200"
                  onClick={() => setShowMobileFilters(true)}
                >
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
                  </svg>
                </button>
              </div>
            </div>

            {/* Product Grid - Adjust for mobile */}
            {currentProducts.length > 0 ? (
              <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-2 sm:gap-6">
                {getSortedProducts(currentProducts).map((product) => (
                  <ProductCard
                    key={product.id}
                    product={{
                      ...product,
                      price: product.skus[0]?.price.toFixed(2) || 0,
                      image: product.skus[0]?.images[0]?.split(',')[0] || '',
                      originalPrice: product.skus[0]?.originalPrice || product.skus[0]?.price * 1.2,
                      discount: Math.round(((product.skus[0]?.originalPrice - product.skus[0]?.price) / product.skus[0]?.originalPrice) * 100)
                    }}
                    onClick={() => navigate(`/product/details/${product.id}`, { state: product })}
                    className="bg-white rounded-lg shadow-sm hover:shadow-md transition-all duration-200"
                  />
                ))}
              </div>
            ) : (
              <NoProducts />
            )}

            {/* Pagination - Simplified for mobile */}
            {totalPages > 1 && (
              <div className="mt-4 sm:mt-8 flex justify-center">
                <nav className="flex items-center gap-1 sm:gap-2" aria-label="Pagination">
                  <button
                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                    disabled={currentPage === 1}
                    className="p-2 sm:px-4 sm:py-2 text-xs sm:text-sm font-medium rounded-md border border-gray-300 bg-white text-gray-700 disabled:bg-gray-100 disabled:text-gray-400"
                  >
                    Prev
                  </button>

                  <span className="text-sm text-gray-700">
                    Page {currentPage} of {totalPages}
                  </span>

                  <button
                    onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                    disabled={currentPage === totalPages}
                    className="p-2 sm:px-4 sm:py-2 text-xs sm:text-sm font-medium rounded-md border border-gray-300 bg-white text-gray-700 disabled:bg-gray-100 disabled:text-gray-400"
                  >
                    Next
                  </button>
                </nav>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductListing;