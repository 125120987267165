import React from 'react';
import InfoPage from '../components/InfoPage';

const ContactUs = () => {
  const contactContent = (
    <div>
      <p className="mb-4">Get in touch with us! We'd love to hear from you.</p>
      
      <div className="contact-info mb-6">
        <h2 className="text-xl font-semibold mb-3">Contact Information</h2>
        <p>Email: info@swipstore.com</p> 
        <p>Phone: +91 6392363003</p>
        <p>Address: Rahim nagar,lucknow,up</p> 
      </div>

      <div className="contact-form">
        <h2 className="text-xl font-semibold mb-3">Send us a message</h2>
        <form className="space-y-4">
          <div>
            <label htmlFor="name" className="block mb-1 text-gray-700">Name</label>
            <input 
              type="text" 
              id="name" 
              className="w-full p-2 border border-gray-200 rounded-lg bg-gray-50 text-sm placeholder-gray-500 focus:outline-none focus:border-pink-300 focus:ring-1 focus:ring-pink-300"
              placeholder="Your name"
            />
          </div>
          <div>
            <label htmlFor="email" className="block mb-1 text-gray-700">Email</label>
            <input 
              type="email" 
              id="email" 
              className="w-full p-2 border border-gray-200 rounded-lg bg-gray-50 text-sm placeholder-gray-500 focus:outline-none focus:border-pink-300 focus:ring-1 focus:ring-pink-300"
              placeholder="Your email"
            />
          </div>
          <div>
            <label htmlFor="message" className="block mb-1 text-gray-700">Message</label>
            <textarea 
              id="message" 
              rows="4" 
              className="w-full p-2 border border-gray-200 rounded-lg bg-gray-50 text-sm placeholder-gray-500 focus:outline-none focus:border-pink-300 focus:ring-1 focus:ring-pink-300"
              placeholder="Your message"
            ></textarea>
          </div>
          <button 
            type="submit" 
            className="bg-pink-500 text-white px-6 py-2 rounded-lg hover:bg-pink-600 transition-colors"
          >
            Send Message
          </button>
        </form>
      </div>
    </div>
  );

  return <InfoPage title="Contact Us" content={contactContent} />;
};

export default ContactUs; 