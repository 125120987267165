const configs = {
  development: {
    baseURL: 'http://localhost:5000/graphql',
    uploadImageBaseURL: 'http://localhost:5000',
  },
  production: {
    baseURL: 'https://swipmart.com/graphql',
    uploadImageBaseURL: 'https://swipmart.com',
  },
};

export default configs;
