import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { GET_CART_ITEMS, GET_CART_BY_SESSIONID } from '../actions/query';
import { UPDATE_CART_ITEM, REMOVE_CART_ITEM } from '../actions/mutation';
import { Trash2, ChevronRight, Minus, Plus, Tag } from "lucide-react";
import { useParams } from 'react-router-dom'

const ShoppingCart = () => {
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const navigate = useNavigate();
  const [authState, setAuthState] = useState({
    isLoggedIn: false,
    userId: null,
    email: ''
  });
  const [promoCode, setPromoCode] = useState('');
  const [error, setError] = useState('');
  const [updateCartItem, { loading: updateLoading }] = useMutation(UPDATE_CART_ITEM, {
    onError: (error) => {
      setError(`Failed to update quantity: ${error.message}`);
    }
  });

  const [removeCartItem, { loading: removeLoading }] = useMutation(REMOVE_CART_ITEM, {
    onError: (error) => {
      setError(`Failed to remove item: ${error.message}`);
    }
  });

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    const userEmail = localStorage.getItem('userEmail');
    const userId = localStorage.getItem('userId');

    if (authToken) {
      setAuthState({
        isLoggedIn: true,
        userId: parseInt(userId),
        email: userEmail || ''
      });
    }
  }, []);

  const sessionId = React.useMemo(() => {
    const existingSessionId = localStorage.getItem("sessionId");
    if (existingSessionId) return existingSessionId;

    const newSessionId = 'sess_' + Math.random().toString(36).substr(2, 9);
    localStorage.setItem("sessionId", newSessionId);
    return newSessionId;
  }, []);

  const queryVariables = React.useMemo(() => {
    if (authState.isLoggedIn && authState.userId) {
      return {
        userId: authState.userId,
        pagination: {
          take: 10,
          skip: 0
        }
      };
    }
    return {
      sessionId,
      expiresAt: new Date(Date.now() + 24 * 60 * 60 * 1000)
    };
  }, [authState.isLoggedIn, authState.userId, sessionId]);

  const { loading: cartLoading, error: queryError, data, refetch } = useQuery(
    authState.isLoggedIn && authState.userId ? GET_CART_ITEMS : GET_CART_BY_SESSIONID,
    {
      variables: queryVariables,
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true
    }
  );

  const cart = authState.isLoggedIn
    ? data?.getCarts?.[0]
    : data?.getCartBySessionId;
  const cartItems = cart?.cartItems || [];

  const handleQuantityChange = async (itemId, newQuantity, stock) => {
    if (newQuantity < 1 || newQuantity > stock) return;

    try {
      setError('');
      await updateCartItem({
        variables: { input: { id: itemId, quantity: newQuantity } },
      });
      refetch();
    } catch (err) {
      setError('Failed to update quantity. Please try again.');
    }
  };

  const handleRemoveItem = async (itemId) => {
    try {
      setError('');
      await removeCartItem({ variables: { id: itemId } });
      refetch();
    } catch (err) {
      setError('Failed to remove item. Please try again.');
    }
  };

  const calculateTotals = () => {
    const subtotal = cartItems.reduce((acc, item) => acc + (item.originalPrice * item.quantity), 0);
    const discount = cartItems.reduce((acc, item) => acc + ((item.originalPrice - item.sku.price) * item.quantity), 0);
    const deliveryFee = subtotal > 100 ? 0 : 15;
    const total = subtotal - discount + deliveryFee;

    return {
      subtotal,
      discount,
      deliveryFee,
      total
    };
  };

  const isLoading = cartLoading || updateLoading || removeLoading;

  if (isLoading) {
    return (
      <div className="max-w-4xl mx-auto p-4 md:p-8" style={{ marginTop: 100 }}>
        <div className="flex flex-col md:flex-row gap-6 md:gap-12">
          <div className="flex-grow border rounded-lg p-4">
            {[1, 2, 3].map((i) => (
              <div key={i} className="flex gap-4 mb-6">
                <div className="w-20 h-20 bg-gray-200 animate-pulse rounded-lg" />
                <div className="flex-grow space-y-2">
                  <div className="h-4 bg-gray-200 animate-pulse rounded w-3/4" />
                  <div className="h-4 bg-gray-200 animate-pulse rounded w-1/2" />
                  <div className="h-4 bg-gray-200 animate-pulse rounded w-1/4" />
                </div>
              </div>
            ))}
          </div>
          <div className="w-full md:w-80 border rounded-lg p-4">
            <div className="space-y-4">
              <div className="h-6 bg-gray-200 animate-pulse rounded" />
              <div className="h-4 bg-gray-200 animate-pulse rounded w-3/4" />
              <div className="h-4 bg-gray-200 animate-pulse rounded w-1/2" />
              <div className="h-8 bg-gray-200 animate-pulse rounded mt-4" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (queryError) {
    return (
      <div className="max-w-4xl mx-auto m-8 p-4 bg-pink-50 border border-pink-200 rounded-lg text-pink-600" style={{ marginTop: 100 }}>
        Error loading cart: {queryError.message}
      </div>
    );
  }

  if (!cart || cartItems.length === 0) {
    return (
      <div className="max-w-4xl mx-auto m-8 p-8 border rounded-lg shadow-sm mt-28">
        <div className="text-center">
          <h2 className="text-xl font-medium mb-4">Your cart is empty</h2>
          <button
            onClick={() => navigate('/')}
            className="bg-black text-white px-6 py-2 rounded-lg hover:bg-gray-800 transition-colors"
          >
            Continue Shopping
          </button>
        </div>
      </div>
    );
  }

  const { subtotal, discount, deliveryFee, total } = calculateTotals();

  return (
    <div className="max-w-4xl mx-auto p-4 md:p-8 mt-20">
      <nav className="flex items-center gap-2 text-sm text-gray-500 mb-6">
        <button onClick={() => navigate('/')} className="hover:text-gray-700">Home</button>
        <ChevronRight className="w-4 h-4" />
        <span className="font-medium">Shopping Cart</span>
      </nav>

      {error && (
        <div className="mb-6 p-4 bg-pink-50 border border-pink-200 rounded-lg text-pink-600">
          {error}
        </div>
      )}

      <div className="flex flex-col md:flex-row gap-8">
        <div className="flex-grow space-y-6">
          <h2 className="text-xl font-medium">Cart Items ({cartItems.length})</h2>
          <div className="space-y-6">
            {cartItems.map((item) => (
              <div key={item.id} className="flex gap-6 p-4 border rounded-lg">
                <img
                  src={item.sku.images?.[0]?.split(',')[0] || '/api/placeholder/120/120'}
                  alt={item.name}
                  className="w-24 h-24 object-cover rounded-lg bg-gray-50"
                />
                <div className="flex-grow space-y-3">
                  <div className="flex justify-between">
                    <h3 className="font-medium">{item.name}</h3>
                    <button
                      onClick={() => handleRemoveItem(item.id)}
                      className="p-2 text-gray-400 hover:text-red-500 transition-colors"
                      disabled={removeLoading}
                    >
                      <Trash2 className="w-5 h-5" />
                    </button>
                  </div>

                  <div className="text-sm text-gray-600">
                    {item.sku.variants &&
                      Object.entries(item.sku.variants).map(([key, value]) => (
                        <span key={key} className="capitalize mr-4">
                          {key}: {value}
                        </span>
                      ))
                    }
                  </div>

                  <div className="flex justify-between items-center">
                    <div className="space-x-2">
                      <span className="text-lg font-medium">₹{item.sku.price.toFixed(2)}</span>
                      {item.originalPrice > item.sku.price && (
                        <span className="text-sm text-gray-500 line-through">
                          ₹{item.originalPrice.toFixed(2)}
                        </span>
                      )}
                    </div>

                    <div className="flex items-center border rounded w-32">
                      <button
                        onClick={() => handleQuantityChange(item.id, item.quantity - 1, item.sku.stock)}
                        disabled={item.quantity <= 1 || updateLoading}
                        className="px-4 py-2 hover:bg-gray-50 disabled:text-gray-400 disabled:cursor-not-allowed"
                      >
                        <Minus className="w-4 h-4" />
                      </button>
                      <input
                        type="text"
                        value={item.quantity}
                        readOnly
                        className="w-full text-center"
                      />
                      <button
                        onClick={() => handleQuantityChange(item.id, item.quantity + 1, item.sku.stock)}
                        disabled={item.quantity >= item.sku.stock || updateLoading}
                        className="px-4 py-2 hover:bg-gray-50 disabled:text-gray-400 disabled:cursor-not-allowed"
                      >
                        <Plus className="w-4 h-4" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="w-full md:w-96 h-fit">
          <div className="border rounded-lg p-6 space-y-6">
            <h2 className="text-xl font-medium">Order Summary</h2>

            <div className="space-y-4">
              <div className="flex justify-between text-gray-600">
                <span>Subtotal</span>
                <span>₹{subtotal.toFixed(2)}</span>
              </div>
              {discount > 0 && (
                <div className="flex justify-between text-green-600">
                  <span>Discount</span>
                  <span>-₹{discount.toFixed(2)}</span>
                </div>
              )}
              <div className="flex justify-between text-gray-600">
                <span>Delivery Fee</span>
                <span>₹{deliveryFee.toFixed(2)}</span>
              </div>

              <div className="pt-4 border-t">
                <div className="flex justify-between text-lg font-medium">
                  <span>Total</span>
                  <span>₹{total.toFixed(2)}</span>
                </div>
              </div>
            </div>

            <div className="space-y-4">
              <div className="flex gap-2">
                <input
                  value={promoCode}
                  onChange={(e) => setPromoCode(e.target.value)}
                  placeholder="Promo code"
                  className="flex-grow px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-black"
                />
                <button className="px-6 py-2 bg-pink-500 text-white rounded-lg hover:bg-pink-600 transition-colors">
                  Apply
                </button>
              </div>

              <button
                onClick={() => navigate('/checkout', { state: { data: cartItems } })}
                className="w-full py-3 bg-pink-500 text-white rounded-lg hover:bg-pink-600 transition-colors flex items-center justify-center"
                disabled={isLoading}
              >
                Proceed to Checkout
                <ChevronRight className="w-4 h-4 ml-2" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShoppingCart;