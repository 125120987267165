// client.js
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import { baseURL } from './utils/endpoint';

const getSessionId = () => {
  let sessionId = localStorage.getItem('sessionId');
  if (!sessionId) {
    sessionId = uuidv4();
    localStorage.setItem('sessionId', sessionId);
  }
  return sessionId;
};

const httpLink = createHttpLink({
  uri: baseURL,
  headers: {
    sessionId: getSessionId()
  }
});

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache()
});

export default client;