import React from 'react';

const Terms = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Terms & Conditions</h1>
      <div className="prose max-w-none">
        <p className="mb-4">Please read our terms and conditions carefully.</p>
        {/* Add terms and conditions content here */}
      </div>
    </div>
  );
};

export default Terms; 