import { gql } from '@apollo/client';
const ADD_TO_CART = gql`
mutation CreateOrAddToCart($userId: Int!, $cartItem: CreateOrAddToCartInput!) {
  createOrAddToCart(userId: $userId, cartItem: $cartItem) {
    id
  }
}
`;
const MANAGE_CART = gql`
  mutation ManageTemporaryCart($input: ManageCartInput!) {
    manageTemporaryCart(input: $input) {
      id
      total
      cartItems {
        id
        productId
        skuId
        quantity
        price
        total
        product {
          id
          name
        }
        sku {
          id
          price
        }
      }
    }
  }
`;
// Additional cart mutations
const UPDATE_CART_ITEM = gql`
  mutation UpdateCartItem($input: UpdateCartItemInput!) {
    updateCartItem(input: $input) {
      id
    }
  }
`;

const REMOVE_CART_ITEM = gql`
  mutation RemoveCartItem($id: Int!) {
    removeCartItem(id: $id) {
      id
    }
  }
`;

const LOGIN = gql`
 mutation Login($phone: String!) {
  login(phone: $phone) { 
     token
     user {
      id
      phone
    }
  }
}
`;
export const CREATE_OR_ADD_TO_SESSION_CART = gql`
  mutation CreateOrAddToSessionCart($sessionId: String!, $cartItem: CreateOrAddToCartInput!) {
    createOrAddToSessionCart(sessionId: $sessionId, cartItem: $cartItem) {
      id
      cartItems {
        id
        productId
        skuId
        quantity
        price
        total
      }
    }
  }
`;

export const MERGE_CARTS_ON_LOGIN = gql`
  mutation MergeCartsOnLogin($sessionId: String!, $userId: Int!) {
    mergeCartsOnLogin(sessionId: $sessionId, userId: $userId) {
      id
      cartItems {
        id
        productId
        skuId
        quantity
        price
        total
      }
    }
  }
`;

const VerifyOTP = gql`
mutation VerifyOTP($email: String!, $otp: String!) {
  verifyOTP(email: $email, otp: $otp) {
    message
    token
    userId
  }
}
`;
export const createUserWithEmail = gql`
mutation CreateUserWithEmail($input: CreateUserWithEmailInput!) {
  createUserWithEmail(input: $input) {
    email
  }
}
`
const CLEAR_CART = gql`
  mutation ClearCart($userId: Int!) {
    clearCart(userId: $userId) {
      id
      cartItems {
        id
      }
    }
  }
`;



// Address mutations
const CREATE_ADDRESS = gql`
  mutation CreateAddress($input: CreateAddressInput!) {
    createAddress(input: $input) {
      id
      street
      isDefault
    }
  }
`;

const UPDATE_ADDRESS = gql`
mutation UpdateAddress($updateAddressId: Int!, $input: UpdateAddressInput!) {
  updateAddress(id: $updateAddressId, input: $input) {
      id
    }
  }
`;

const DELETE_ADDRESS = gql`
  mutation DeleteAddress($id: Int!) {
    deleteAddress(id: $id) {
      id
      street
    }
  }
`;

const createOrder = gql`
mutation CreateOrderWithItems($input: CreateOrderWithItemsInput!) {
  createOrderWithItems(input: $input) {
    order {
      id
      orderNumber
      userId
      totalAmount
      status
      discount
      createdAt
      orderItems {
        id
        productId
        skuId
        quantity
        price
      }
    }
  }
}
`

const clearCart = gql`
mutation ClearCart($userId: Int!) {
  clearCart(userId: $userId) {
    userId
  }
}
`
const addProductRating = gql`
mutation AddProductRating($input: AddProductRatingInput!) {
  addProductRating(input: $input) {
    productId
  }
}
`
const UPDATE_USER = gql`
  mutation UpdateUser($updateUserId: Int!, $input: UpdateUserInput!) {
    updateUser(id: $updateUserId, input: $input) {
      name
    }
  }
`;
const IS_TOKEN_VALID = gql`
mutation IsTokenValid($token: String!) {
  isTokenValid(token: $token) {
    isValid
  }
}
`;

export {
  addProductRating,
  clearCart,
  createOrder,
  ADD_TO_CART,
  UPDATE_USER,
  REMOVE_CART_ITEM,
  CLEAR_CART,
  UPDATE_CART_ITEM,
  CREATE_ADDRESS,
  UPDATE_ADDRESS,
  LOGIN,
  VerifyOTP,
  DELETE_ADDRESS,
  MANAGE_CART,
  IS_TOKEN_VALID
};