import React from 'react';

const Privacy = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
      <div className="prose max-w-none">
        <p className="mb-4">Your privacy is important to us.</p>
        {/* Add privacy policy content here */}
      </div>
    </div>
  );
};

export default Privacy; 