import React from 'react';
import InfoPage from '../components/InfoPage';

const AboutUs = () => {
  const aboutUsContent = (
    <div>
      <p>Welcome to our store! We are dedicated to providing the best shopping experience...</p>
      {/* Add more content specific to About Us */}
    </div>
  );

  return <InfoPage title="About Us" content={aboutUsContent} />;
};

export default AboutUs; 