import React from 'react';

const Careers = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Careers</h1>
      <div className="prose max-w-none">
        <p className="mb-4">Join our team and be part of something special.</p>
        {/* Add your careers content here */}
      </div>
    </div>
  );
};

export default Careers; 