import React, { useState, useEffect } from 'react';
import {
  ShoppingBagIcon as BoxIcon,
  UserIcon,
  CreditCardIcon as PaymentIcon,
  ClipboardDocumentListIcon as StuffIcon,
  ArrowRightOnRectangleIcon as LogoutIcon,
  PencilIcon
} from '@heroicons/react/24/outline';
import { ChevronRight } from 'lucide-react';
import { Skeleton } from '../components/SkeltonLoader';
import { useQuery, useMutation } from '@apollo/client';
import { getUserByIdQuery } from '../actions/query';
import { UPDATE_USER } from '../actions/mutation';
import { CREATE_ADDRESS, UPDATE_ADDRESS, DELETE_ADDRESS } from '../actions/mutation';
import AddressForm from '../components/Address/AddressForm';
import { useNavigate, useParams } from 'react-router-dom';
import ProductDeliveryCard from '../components/ProductDeliverCard';
import { baseURL, uploadImageBaseURL } from '../utils/endpoint';
const uploadImage = async (file) => {
  const formData = new FormData();
  formData.append('image', file);

  try {
    const response = await fetch(`${uploadImageBaseURL}/upload-image`, {
      method: 'POST',
      credentials: 'include',  // Important for CORS with credentials
      body: formData,
    });

    if (!response.ok) {
      throw new Error('Failed to upload image');
    }

    const data = await response.json();

    // Return only the `id` from the server's response
    return data.id; // Assuming the server returns { id: 10, message: "Image uploaded successfully" }
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};

export default function ProfilePage() {
  const [activeSection, setActiveSection] = useState('profile');
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const pathId = parseInt(id);
    const storedUserId = localStorage.getItem('userId');

    if (!isNaN(pathId)) {
      setUserId(pathId);
    } else if (storedUserId) {
      setUserId(parseInt(storedUserId));
      navigate(`/profile/${storedUserId}`);
    } else {
      navigate('/');
    }
  }, [id, navigate]);

  const { data, error, loading: queryLoading, refetch } = useQuery(getUserByIdQuery, {
    variables: { id: userId },
    skip: !userId,
    onError: (error) => {
      console.error('Error fetching user data:', error);
      if (error.message.includes('unauthorized')) {
        navigate('/');
      }
    }
  });

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      refetch(); // Refetch user data after update
    }
  });

  const userData = data?.getUserById;
  console.log("ProfilePage userData", userData);

  // Simulate loading
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      try {
        // Upload image and get ID
        const imageId = await uploadImage(file);
        // Update user with the image ID
        await updateUser({
          variables: {
            updateUserId: userId,
            input: {
              image: imageId.toString()
            }
          }
        });
      } catch (error) {
        console.error('Error handling image upload:', error);
      }
    }
  };

  const handleUpdateUser = async (updatedData) => {
    try {
      await updateUser({
        variables: {
          updateUserId: userId,
          input: updatedData
        }
      });
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('sessionId');
    navigate('/');
    window.location.reload();
  };

  // Helper function to render the appropriate content
  const renderContent = () => {
    if (loading) {
      return <LoadingContent />;
    }

    if (error) {
      return (
        <div className="max-w-4xl mx-auto p-4 md:p-8 mt-20">
          <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded">
            Error loading profile data. Please try again later.
          </div>
        </div>
      );
    }

    if (!userData) {
      return (
        <div className="max-w-4xl mx-auto p-4 md:p-8 mt-20">
          <div className="bg-yellow-50 border border-yellow-200 text-yellow-700 px-4 py-3 rounded">
            No user data found. Please log in again.
          </div>
        </div>
      );
    }

    switch (activeSection) {
      case 'orders':
        return <OrdersContent orders={userData?.orders} />;
      case 'profile':
        return <ProfileContent
          userData={userData}
          onUpdateUser={handleUpdateUser}
          onImageChange={handleImageChange}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
        />;
      case 'addresses':
        return <AddressesContent addresses={userData?.addresses} refetch={refetch} />;
      // case 'pan':
      //   return <PanCardContent />;
      case 'giftcards':
        return <GiftCardsContent />;
      case 'upi':
        return <UPIContent />;
      case 'cards':
        return <SavedCardsContent />;
      case 'coupons':
        return <CouponsContent />;
      case 'reviews':
        return <ReviewsContent />;
      case 'notifications':
        return <NotificationsContent />;
      case 'wishlist':
        return <WishlistContent />;
      default:
        return <ProfileContent
          userData={userData}
          onUpdateUser={handleUpdateUser}
          onImageChange={handleImageChange}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
        />;
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-4 md:p-8 mt-20">
      {/* Breadcrumb - Matching CartPage style */}
      <nav className="flex items-center gap-2 text-sm text-gray-500 mb-6">
        <button onClick={() => navigate('/')} className="hover:text-gray-700">Home</button>
        <ChevronRight className="w-4 h-4" />
        <span className="font-medium">My Account</span>
      </nav>

      <div className="flex flex-col md:flex-row gap-8">
        {/* Sidebar - Matching CartPage card style */}
        <div className="w-full md:w-80 h-fit">
          <div className="bg-white border rounded-lg p-6 space-y-6">
            {/* Profile Header */}
            <div className="flex items-center space-x-3 pb-4 border-b">
              <div className="relative">
                <img
                  src={userData?.image ? `${uploadImageBaseURL}/get-image/${userData.image}` : "https://www.pngitem.com/pimgs/m/146-1468479_transparent-avatar-png-default-avatar-icon-png-transparent.png"}
                  alt="Profile"
                  className="w-12 h-12 rounded-full object-cover"
                />
              </div>
              <div>
                <p className="text-sm text-gray-500">Hello,</p>
                <p className="font-medium">{userData?.name}</p>
              </div>
            </div>

            {/* Navigation Menu */}
            <nav className="space-y-6">
              <div className="space-y-3">
                <div onClick={() => setActiveSection('orders')}
                  className="flex items-center space-x-2 cursor-pointer hover:text-red-500 transition-colors">
                  <BoxIcon className="w-5 h-5" />
                  <span className="text-sm font-medium">MY ORDERS</span>
                </div>
              </div>

              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <UserIcon className="w-5 h-5" />
                  <span className="text-sm font-medium">ACCOUNT SETTINGS</span>
                </div>
                <div className="pl-7 space-y-3">
                  <button
                    onClick={() => setActiveSection('profile')}
                    className={`text-sm ${activeSection === 'profile'
                      ? 'text-red-500'
                      : 'text-gray-600 hover:text-red-500'
                      } transition-colors w-full text-left`}
                  >
                    Profile Information
                  </button>
                  <button
                    onClick={() => setActiveSection('addresses')}
                    className={`text-sm ${activeSection === 'addresses'
                      ? 'text-red-500'
                      : 'text-gray-600 hover:text-red-500'
                      } transition-colors w-full text-left`}
                  >
                    Manage Addresses
                  </button>
                </div>
              </div>

              <div className="space-y-3">
                <div className="flex items-center space-x-2">
                  <StuffIcon className="w-5 h-5" />
                  <span className="text-sm font-medium">MY STUFF</span>
                </div>
                <div className="pl-7 space-y-3">
                  {['My Coupons', 'My Reviews & Ratings', 'All Notifications', 'My Wishlist'].map((item) => (
                    <button
                      key={item}
                      className="text-sm text-gray-600 hover:text-red-500 transition-colors w-full text-left"
                    >
                      {item}
                    </button>
                  ))}
                </div>
              </div>

              <button
                onClick={() => setShowLogoutConfirm(true)}
                className="flex items-center space-x-2 text-gray-600 hover:text-red-500 transition-colors w-full"
              >
                <LogoutIcon className="w-5 h-5" />
                <span className="text-sm font-medium">Logout</span>
              </button>
            </nav>
          </div>
        </div>

        {/* Main Content Area - Matching CartPage style */}
        <div className="flex-grow">
          <div className="bg-white border rounded-lg p-6">
            {renderContent()}
          </div>
        </div>
      </div>

      {/* Logout Confirmation Modal */}
      {showLogoutConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-6 max-w-sm mx-4">
            <h3 className="text-lg font-medium mb-4">Confirm Logout</h3>
            <p className="text-gray-600 mb-6">Are you sure you want to logout?</p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowLogoutConfirm(false)}
                className="px-4 py-2 text-gray-600 hover:text-gray-800 transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={handleLogout}
                className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

// Loading Content Component
function LoadingContent() {
  return (
    <div className="max-w-2xl bg-white rounded-lg shadow-sm p-4 md:p-6">
      <div className="space-y-6">
        <Skeleton height={24} width={200} />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {[...Array(2)].map((_, i) => (
            <Skeleton key={i} height={40} />
          ))}
        </div>
        <div className="space-y-2">
          <Skeleton height={20} width={120} />
          <div className="flex space-x-4">
            {[...Array(2)].map((_, i) => (
              <Skeleton key={i} height={24} width={80} />
            ))}
          </div>
        </div>
        {[...Array(2)].map((_, i) => (
          <div key={i} className="space-y-2">
            <div className="flex justify-between">
              <Skeleton height={20} width={120} />
              <Skeleton height={20} width={40} />
            </div>
            <Skeleton height={40} />
          </div>
        ))}
      </div>
    </div>
  );
}

// Modified SidebarLink component
function SidebarLink({ text, active, onClick }) {
  return (
    <a
      href="#"
      onClick={(e) => {
        e.preventDefault();
        onClick && onClick();
      }}
      className={`text-sm block ${active ? 'text-blue-600' : 'text-gray-600'
        } hover:text-blue-600`}
    >
      {text}
    </a>
  );
}

// Add this new component near the other component definitions
function LogoutConfirmDialog({ isOpen, onClose, onConfirm }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4">
        <h3 className="text-lg font-medium mb-4">Confirm Logout</h3>
        <p className="text-gray-600 mb-6">Are you sure you want to logout?</p>
        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
}

// Modify the SidebarItem component
function SidebarItem({ icon, text }) {
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  const navigate = useNavigate();
  const isLogout = text === 'Logout';

  const handleLogout = () => {
    localStorage.clear(); // Clear all localStorage items

    // Dispatch a custom event for the header
    window.dispatchEvent(new Event('logout'));

    navigate('/'); // Redirect to login page
  };

  const handleClick = () => {
    if (isLogout) {
      setShowLogoutConfirm(true);
    }
  };

  return (
    <>
      <div
        className={`flex items-center space-x-2 ${isLogout ? 'text-gray-700 hover:text-red-600 active:text-red-700 cursor-pointer transition-colors duration-200' : 'text-gray-700'
          }`}
        onClick={handleClick}
      >
        {icon}
        <span className="text-sm font-medium">{text}</span>
      </div>

      <LogoutConfirmDialog
        isOpen={showLogoutConfirm}
        onClose={() => setShowLogoutConfirm(false)}
        onConfirm={handleLogout}
      />
    </>
  );
}

function FaqItem({ question, answer }) {
  return (
    <div>
      <h4 className="text-sm font-medium mb-1">{question}</h4>
      <p className="text-sm text-gray-600">{answer}</p>
    </div>
  );
}

// Add this component to handle individual order items
function OrderCard({ order }) {
  return (
    <div className="bg-white border rounded-lg p-4 mb-4">
      <div className="flex justify-between items-center mb-4">
        <div>
          <div className="flex items-center gap-2">
            <span className="text-sm font-medium">Order #{order.id}</span>
            <span className={`px-2 py-0.5 text-xs rounded-full ${order.status === 'DELIVERED' ? 'bg-green-100 text-green-700' :
              order.status === 'PROCESSING' ? 'bg-blue-100 text-blue-700' :
                'bg-gray-100 text-gray-700'
              }`}>
              {order.status}
            </span>
          </div>
          <p className="text-sm text-gray-500">
            Total Amount: ₹{order.totalAmount?.toFixed(2)}
          </p>
        </div>
      </div>

      {/* Order Timeline */}
      <div className="relative mb-6">
        <div className="absolute left-0 right-0 h-0.5 top-4 bg-gray-200">
          <div className={`h-full bg-blue-500 transition-all duration-500 ${order.status === 'PROCESSING' || order.status === 'SHIPPED' || order.status === 'DELIVERED'
            ? 'w-full' :
            order.status === 'SHIPPED' ? 'w-2/3' :
              'w-1/3'
            }`} />
        </div>

        <div className="relative flex justify-between">
          <div className="text-center flex-1">
            <div className={`w-8 h-8 mx-auto rounded-full flex items-center justify-center border-2 ${order.status === 'PROCESSING' || order.status === 'SHIPPED' || order.status === 'DELIVERED'
              ? 'bg-blue-500 border-blue-500'
              : 'bg-white border-gray-300'
              }`}>
              {(order.status === 'PROCESSING' || order.status === 'SHIPPED' || order.status === 'DELIVERED') && (
                <svg className="w-4 h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
              )}
            </div>
            <p className="mt-1 text-xs font-medium">Processing</p>
          </div>

          <div className="text-center flex-1">
            <div className={`w-8 h-8 mx-auto rounded-full flex items-center justify-center border-2 ${order.status === 'SHIPPED' || order.status === 'DELIVERED'
              ? 'bg-blue-500 border-blue-500'
              : 'bg-white border-gray-300'
              }`}>
              {(order.status === 'SHIPPED' || order.status === 'DELIVERED') && (
                <svg className="w-4 h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
              )}
            </div>
            <p className="mt-1 text-xs font-medium">Shipped</p>
          </div>

          <div className="text-center flex-1">
            <div className={`w-8 h-8 mx-auto rounded-full flex items-center justify-center border-2 ${order.status === 'DELIVERED'
              ? 'bg-blue-500 border-blue-500'
              : 'bg-white border-gray-300'
              }`}>
              {order.status === 'DELIVERED' && (
                <svg className="w-4 h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
              )}
            </div>
            <p className="mt-1 text-xs font-medium">Delivered</p>
          </div>
        </div>
      </div>

      {/* Addresses */}
      <div className="space-y-3">
        <div className="border-t pt-3">
          <p className="text-sm font-medium mb-1">Shipping Address:</p>
          <p className="text-sm text-gray-600">
            {order.shippingAddress ?
              `${order.shippingAddress.street}, ${order.shippingAddress.city}, ${order.shippingAddress.state}, ${order.shippingAddress.postalCode}` :
              'Address not available'
            }
          </p>
        </div>
        <div className="border-t pt-3">
          <p className="text-sm font-medium mb-1">Billing Address:</p>
          <p className="text-sm text-gray-600">
            {order.billingAddress ?
              `${order.billingAddress.street}, ${order.billingAddress.city}, ${order.billingAddress.state}, ${order.billingAddress.postalCode}` :
              'Address not available'
            }
          </p>
        </div>
      </div>
    </div>
  );
}

// Update the OrdersContent component
function OrdersContent({ orders }) {
  const navigate = useNavigate();

  if (!orders || orders.length === 0) {
    return (
      <div className="text-center py-8">
        <h3 className="text-lg font-medium mb-2">No Orders Found</h3>
        <p className="text-gray-600 mb-4">You haven't placed any orders yet.</p>
        <button
          onClick={() => navigate('/')}
          className="bg-red-500 text-white px-6 py-2 rounded-lg hover:bg-red-600 transition-colors"
        >
          Start Shopping
        </button>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <h2 className="text-xl font-medium mb-6">My Orders ({orders.length})</h2>
      {orders.map((order) => (
        <OrderCard key={order.id} order={order} />
      ))}
    </div>
  );
}

function ProfileContent({ userData, onUpdateUser, onImageChange, isEditing, setIsEditing }) {
  const fileInputRef = React.useRef(null);
  const [formData, setFormData] = useState({
    firstName: userData?.name ? userData.name.split(' ')[0] : '',
    lastName: userData?.name ? userData.name.split(' ')[1] || '' : '',
    gender: userData?.gender || '',
    email: userData?.email || ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async () => {
    if (userData?.id) {
      // Combine firstName and lastName into name before sending to server
      const updatedData = {
        name: `${formData.firstName} ${formData.lastName}`.trim(),
        gender: formData.gender,
        email: formData.email
      };
      await onUpdateUser(updatedData);
      setIsEditing(false);
    }
  };

  const handleImageEditClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-medium">Personal Information</h2>
        <button
          onClick={() => setIsEditing(!isEditing)}
          className="flex items-center space-x-2 px-4 py-2 text-red-500 hover:bg-red-50 rounded-lg transition-colors"
        >
          <PencilIcon className="w-4 h-4" />
          <span>{isEditing ? 'Cancel' : 'Edit'}</span>
        </button>
      </div>

      {/* Profile Image */}
      <div className="mb-6 relative w-fit">
        <img
          src={userData?.image ? `${uploadImageBaseURL}/get-image/${userData.image}` : "https://www.pngitem.com/pimgs/m/146-1468479_transparent-avatar-png-default-avatar-icon-png-transparent.png"}
          alt="Profile"
          className="w-20 h-20 rounded-full object-cover"
        />
        <button
          onClick={handleImageEditClick}
          className="absolute -top-2 -right-2 p-1.5 bg-white rounded-full shadow-md hover:bg-gray-100"
        >
          <PencilIcon className="w-4 h-4 text-gray-600" />
        </button>
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          onChange={onImageChange}
          className="hidden"
        />
      </div>

      {/* Form */}
      <div className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">First Name</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className="w-full p-2 border rounded-md"
              disabled={!isEditing}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Last Name</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className="w-full p-2 border rounded-md"
              disabled={!isEditing}
            />
          </div>
        </div>

        <div>
          <p className="mb-2 text-sm md:text-base">Your Gender</p>
          <div className="flex space-x-4">
            <label className="flex items-center">
              <input
                type="radio"
                name="gender"
                value="male"
                onChange={handleChange}
                className="mr-2"
                checked={formData.gender === 'male'}
                disabled={!isEditing}
              />
              <span>Male</span>
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                name="gender"
                value="female"
                onChange={handleChange}
                className="mr-2"
                checked={formData.gender === 'female'}
                disabled={!isEditing}
              />
              <span>Female</span>
            </label>
          </div>
        </div>

        <div>
          <div className="flex justify-between items-center mb-2">
            <p>Email Address</p>
          </div>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className={`w-full p-2 border rounded-md ${!isEditing ? 'bg-gray-100' : ''}`}
            disabled={!isEditing}
          />
        </div>

        {isEditing && (
          <div className="flex justify-end space-x-4">
            <button
              onClick={() => setIsEditing(false)}
              className="px-4 py-2 rounded border hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
            >
              Save Changes
            </button>
          </div>
        )}

        {/* FAQs Section */}
        <div className="mt-8">
          <h3 className="text-base md:text-lg font-medium mb-4">FAQs</h3>
          <div className="space-y-4">
            <FaqItem
              question="What happens when I update my email address (or mobile number)?"
              answer="Your login email id (or mobile number) changes, likewise. You'll receive all your account related communication on your updated email address (or mobile number)."
            />
            <FaqItem
              question="When will my Flipkart account be updated with the new email address (or mobile number)?"
              answer="It happens as soon as you confirm the verification code sent to your email (or mobile) and save the changes."
            />
          </div>
        </div>

        {/* <div className="border-t pt-6 space-y-2">
          <button className="text-blue-600 block hover:underline">
            Deactivate Account
          </button>
          <button className="text-red-600 block hover:underline">
            Delete Account
          </button>
        </div> */}
      </div>
    </div>
  );
}

// Update the AddressesContent component
function AddressesContent({ addresses, refetch }) {
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [editingAddress, setEditingAddress] = useState(null);
  const [addressFormData, setAddressFormData] = useState({
    street: '',
    city: '',
    state: '',
    postalCode: '',
    country: 'India',
    isDefault: false
  });
  const [pincodeData, setPincodeData] = useState(null);
  const [pincodeError, setPincodeError] = useState('');
  const [isPincodeLoading, setIsPincodeLoading] = useState(false);

  const [createAddress] = useMutation(CREATE_ADDRESS);
  const [updateAddress] = useMutation(UPDATE_ADDRESS);
  const [deleteAddress] = useMutation(DELETE_ADDRESS);

  const handlePincodeChange = async (name, value) => {
    if (name === 'postalCode' && value.length === 6) {
      setIsPincodeLoading(true);
      setPincodeError('');
      try {
        const response = await fetch(`https://api.postalpincode.in/pincode/${value}`);
        const [data] = await response.json();

        if (data.Status === 'Error') {
          setPincodeError('Invalid pincode');
          setPincodeData(null);
          return;
        }

        const postOffice = data.PostOffice?.[0];
        if (postOffice) {
          setAddressFormData(prev => ({
            ...prev,
            city: postOffice.District || prev.city,
            state: postOffice.State || prev.state,
            postalCode: value
          }));
        }
        setPincodeData(data);
      } catch (error) {
        setPincodeError('Failed to fetch pincode details');
        setPincodeData(null);
      } finally {
        setIsPincodeLoading(false);
      }
    } else {
      // Handle other form field changes
      setAddressFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleAddressSubmit = async (formData) => {
    try {
      if (editingAddress) {
        // Update existing address
        const { __typename, createdAt, updatedAt, ...updateData } = formData;
        await updateAddress({
          variables: {
            updateAddressId: editingAddress.id,
            input: updateData,
          },
        });
      } else {
        // Create new address
        await createAddress({
          variables: {
            input: {
              userId: parseInt(localStorage.getItem('userId')),
              ...formData,
            },
          },
        });
      }
      refetch();
      setShowAddressForm(false);
      setEditingAddress(null);
      setAddressFormData({
        street: '',
        city: '',
        state: '',
        postalCode: '',
        country: 'India',
        isDefault: false,
      });
      setPincodeData(null);
    } catch (error) {
      console.error('Error saving address:', error);
    }
  };

  const handleAddressDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this address?')) {
      try {
        await deleteAddress({
          variables: { id },
        });
        refetch();
      } catch (error) {
        console.error('Error deleting address:', error);
      }
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-medium">Manage Addresses</h2>
        {!showAddressForm && (
          <button
            onClick={() => setShowAddressForm(true)}
            className="flex items-center space-x-2 px-4 py-2 text-red-500 hover:bg-red-50 rounded-lg transition-colors"
          >
            <span>+ Add New Address</span>
          </button>
        )}
      </div>

      {showAddressForm && (
        <AddressForm
          initialData={editingAddress || addressFormData}
          onSubmit={handleAddressSubmit}
          onCancel={() => {
            setShowAddressForm(false);
            setEditingAddress(null);
            setAddressFormData({
              street: '',
              city: '',
              state: '',
              postalCode: '',
              country: 'India',
              isDefault: false,
            });
            setPincodeData(null);
          }}
          isPincodeLoading={isPincodeLoading}
          pincodeError={pincodeError}
          pincodeData={pincodeData}
          onChange={handlePincodeChange}
        />
      )}

      {addresses && addresses.length > 0 ? (
        <div className="space-y-4">
          {addresses.map(address => (
            <div
              key={address.id}
              className="border p-6 rounded-lg hover:shadow-sm transition-shadow relative"
            >
              <div className="flex justify-between items-start">
                <div className="space-y-2">
                  <p className="font-medium">{address.street}</p>
                  <p className="text-gray-600">
                    {address.city}, {address.state} {address.postalCode}
                  </p>
                  <p className="text-gray-600">{address.country}</p>
                  {address.isDefault && (
                    <span className="inline-block px-2 py-1 text-xs text-red-500 bg-red-50 rounded-full">
                      Default Address
                    </span>
                  )}
                </div>
                <div className="flex gap-4">
                  <button
                    onClick={() => {
                      setEditingAddress(address);
                      setShowAddressForm(true);
                    }}
                    className="text-sm text-blue-600 hover:text-blue-700"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleAddressDelete(address.id)}
                    className="text-sm text-red-500 hover:text-red-600"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center py-8 border rounded-lg">
          <div className="mb-4">
            <svg className="w-16 h-16 text-gray-400 mx-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
          </div>
          <p className="text-gray-600 mb-4">No saved addresses</p>
          <button
            onClick={() => setShowAddressForm(true)}
            className="text-red-500 hover:text-red-600 font-medium"
          >
            + Add New Address
          </button>
        </div>
      )}
    </div>
  );
}

// function PanCardContent() {
//   return (
//     <div className="max-w-2xl bg-white rounded-lg shadow-sm p-4 md:p-6">
//       <h2 className="text-xl font-medium mb-4">PAN Card Information</h2>
//       <p className="text-gray-600">No PAN card details added</p>
//     </div>
//   );
// }

function GiftCardsContent() {
  return (
    <div className="max-w-2xl bg-white rounded-lg shadow-sm p-4 md:p-6">
      <h2 className="text-xl font-medium mb-4">Gift Cards</h2>
      <p className="text-gray-600">No gift cards available</p>
    </div>
  );
}

function UPIContent() {
  return (
    <div className="max-w-2xl bg-white rounded-lg shadow-sm p-4 md:p-6">
      <h2 className="text-xl font-medium mb-4">Saved UPI</h2>
      <p className="text-gray-600">No UPI IDs saved</p>
    </div>
  );
}

function SavedCardsContent() {
  return (
    <div className="max-w-2xl bg-white rounded-lg shadow-sm p-4 md:p-6">
      <h2 className="text-xl font-medium mb-4">Saved Cards</h2>
      <p className="text-gray-600">No cards saved</p>
    </div>
  );
}

function CouponsContent() {
  return (
    <div className="max-w-2xl bg-white rounded-lg shadow-sm p-4 md:p-6">
      <h2 className="text-xl font-medium mb-4">My Coupons</h2>
      <p className="text-gray-600">No coupons available</p>
    </div>
  );
}

function ReviewsContent() {
  return (
    <div className="max-w-2xl bg-white rounded-lg shadow-sm p-4 md:p-6">
      <h2 className="text-xl font-medium mb-4">My Reviews & Ratings</h2>
      <p className="text-gray-600">No reviews yet</p>
    </div>
  );
}

function NotificationsContent() {
  return (
    <div className="max-w-2xl bg-white rounded-lg shadow-sm p-4 md:p-6">
      <h2 className="text-xl font-medium mb-4">All Notifications</h2>
      <p className="text-gray-600">No notifications</p>
    </div>
  );
}

function WishlistContent() {
  return (
    <div className="max-w-2xl bg-white rounded-lg shadow-sm p-4 md:p-6">
      <h2 className="text-xl font-medium mb-4">My Wishlist</h2>
      <p className="text-gray-600">Your wishlist is empty</p>
    </div>
  );
}
