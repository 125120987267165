import React from 'react';

const Returns = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Returns & Refunds</h1>
      <div className="prose max-w-none">
        <p className="mb-4">Understanding our return and refund policies.</p>
        {/* Add returns policy content here */}
      </div>
    </div>
  );
};

export default Returns; 