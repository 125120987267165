import React from 'react'
import NavLink from '../components/Header/Navlink';
import { useQuery } from '@apollo/client';
import { getCategoriesQuery, getProductByIdQuery, getProductsQuery, getAllOffersQuery, getParentCategoriesQuery } from '../actions/query';
import { Skeleton } from '../components/SkeltonLoader';
import DealsGrid from '../components/Deals/DealsGrid';
import PopularCategories from './Categories';
import Carousel from '../components/Carosel/Carosel';
import ProductSection from '../components/ProductSection/ProductSection';
import NewsLetterModal from '../components/NewsLetterModal/NewsLetterModal';
import InstagramShop from '../components/InstagramShop/InstagramShop';
import { getAllInstagramPostsQuery } from '../actions/query';

function App() {
  const [showSubscribe, setShowSubscribe] = React.useState(false);

  const handleSubscribe = () => {
    setShowSubscribe(true);
  };

  React.useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const totalHeight = document.documentElement.scrollHeight - window.innerHeight;

      if (scrollPosition > totalHeight * 0.6 && !showSubscribe) {
        setShowSubscribe(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [showSubscribe]);

  // Queries
  const { loading: categoriesLoading, error: categoriesError, data: categories, refetch: refetchCategories } = useQuery(getCategoriesQuery, {
    fetchPolicy: 'cache-and-network'
  });

  const { data: products, loading: productLoading, error: productError, refetch: refetchProducts } = useQuery(getProductsQuery, {
    fetchPolicy: 'cache-and-network'
  });

  const { data: offers, loading: offersLoading, error: offersError, refetch: refetchOffers } = useQuery(getAllOffersQuery, {
    fetchPolicy: 'cache-first'
  });

  const { data: instagramPosts, loading: instagramLoading, error: instagramError } = useQuery(getAllInstagramPostsQuery);

  // Error handling
  React.useEffect(() => {
    if (categoriesError) refetchCategories();
    if (productError) refetchProducts();
    if (offersError) refetchOffers();
  }, [categoriesError, productError, offersError]);

  // Scroll to top
  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  // Calculate leaf categories
  const leafCategories = React.useMemo(() => {
    if (!categories?.getCategories) return [];
    const parentIds = new Set();
    categories.getCategories.forEach(cat => cat.parentId !== null && parentIds.add(cat.parentId));
    return categories.getCategories.filter(cat => !parentIds.has(cat.id));
  }, [categories?.getCategories]);

  // Loading state
  const isLoading = [categoriesLoading, productLoading, offersLoading].some(Boolean) &&
    !(categories && products && offers);

  if (isLoading) {
    return (
      <div className="min-h-screen">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="w-full h-[400px] bg-gray-200 rounded-lg animate-pulse mb-12"></div>
          <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4 mb-12">
            {[...Array(6)].map((_, i) => (
              <div key={i} className="flex flex-col items-center space-y-2">
                <div className="w-24 h-24 bg-gray-200 rounded-full animate-pulse"></div>
                <div className="w-20 h-4 bg-gray-200 rounded animate-pulse"></div>
              </div>
            ))}
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            {[...Array(4)].map((_, i) => (
              <div key={i} className="space-y-4">
                <div className="w-full h-48 bg-gray-200 rounded-lg animate-pulse"></div>
                <div className="w-2/3 h-4 bg-gray-200 rounded animate-pulse"></div>
                <div className="w-1/3 h-4 bg-gray-200 rounded animate-pulse"></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  // Data processing
  const deals = offers?.getAllOffers?.filter(offer => offer?.banner?.style !== "Slider") || [];
  const sliderOffers = offers?.getAllOffers?.filter(offer => offer?.banner?.style === "Slider") || [];
  const allProducts = products?.getProducts || [];
  const featuredProducts = allProducts.filter(product => product.isFeatured);

  return (
    <div className="App px-2 sm:px-5 bg-slate-200 min-h-screen">
      {sliderOffers.length > 0 && (
        <div className="carousel-wrapper pt-[126px] -mx-2 sm:-mx-5">
          <Carousel
            slides={sliderOffers}
            loop={false}
            slidesPerView={1}
            slidesPerGroup={1}
          />
        </div>
      )}

      {categories?.getCategories && (
        <div className="mt-6 sm:mt-8">
          <PopularCategories categories={categories.getCategories} />
        </div>
      )}

      {featuredProducts.length > 0 && (
        <ProductSection
          title="Featured Products"
          products={featuredProducts}
        />
      )}

      {allProducts.length > 0 && (
        <ProductSection
          title="All Products"
          products={allProducts}
        />
      )}

      {/* Leaf category product sections */}
      {leafCategories.map(leaf => {
        const categoryProducts = allProducts.filter(p => p.categoryId === leaf.id);
        return categoryProducts.length > 0 ? (
          <ProductSection
            key={leaf.id}
            title={leaf.name}
            products={categoryProducts}
            categoryId={leaf.id}
          />
        ) : null;
      })}

      {deals.length > 0 && <DealsGrid deals={deals} />}

      {instagramPosts?.getAllInstagramPosts?.length > 0 && (
        <InstagramShop posts={instagramPosts.getAllInstagramPosts} />
      )}

      {/* {showSubscribe && (
        <NewsLetterModal
          showSubscribe={showSubscribe}
          setShowSubscribe={setShowSubscribe}
        />
      )} */}
    </div>
  );
}

export default App;