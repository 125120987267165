import React from 'react';

const OrderDetail = () => {
    return (
        <div className="p-6 bg-gray-100 min-h-screen mt-16">
            {/* Breadcrumb */}
            <div className="text-sm text-gray-600 mb-4">
                Home &gt; My Account &gt; My Orders &gt; 0033337804723846000
            </div>

            {/* Main Container for Left and Right Sections */}
            <div className="flex flex-col lg:flex-row gap-6">
                {/* Left Side: Order Information and Installation Details */}
                <div className="lg:w-2/3">
                    {/* Order Information */}
                    <div className="bg-white p-6 border rounded mb-6">
                        <h2 className="text-xl font-bold mb-2">Orient Electric 5.5 L Instant Water Geyser (Calidus Pro, White)</h2>
                        <p className="text-gray-600 mb-2">White</p>
                        <p className="text-gray-600 mb-4">Seller: KLPLWORLDRetail</p>
                        <p className="text-2xl font-bold mb-4">₹3,302</p>
                        <p className="text-sm text-gray-600 mb-4">Item was opened and verified at the time of delivery.</p>

                        {/* Order Timeline */}
                        <div className="mb-4">
                            <p className="text-sm">Order Confirmed, Jan 07</p>
                            <p className="text-sm">Delivered, Jan 09</p>
                        </div>

                        {/* Buttons */}
                        <div className="flex space-x-4">
                            <button className="text-blue-500">See All Updates</button>
                            <button className="text-blue-500">Chat with us</button>
                        </div>

                        {/* Return Policy */}
                        <p className="text-sm text-gray-600 mt-4">Return policy ended on Jan 16</p>
                    </div>

                    {/* Installation Details */}
                    <div className="bg-white p-6 border rounded mb-6">
                        <h2 className="text-xl font-bold mb-4">Installation and Demo from Jeeves Expert</h2>
                        <p className="text-2xl font-bold mb-4">₹399</p>
                        <p className="text-sm text-gray-600 mb-4">This price is based on standard fittings. <span className="text-blue-500">View Details</span></p>
                        <p className="text-sm text-gray-600 mb-4">Cancelled on Jan 10</p>
                        <p className="text-sm text-gray-600 mb-4">7:00 AM - 11:00 PM</p>
                        <button className="text-blue-500">Change</button>
                        <button className="text-blue-500 ml-4">Chat with us</button>
                    </div>
                </div>

                {/* Right Side: Invoice, Shipping Details, Price Details, and Offers */}
                <div className="lg:w-1/3">
                    {/* Invoice Download */}
                    <div className="bg-white p-6 border rounded mb-6">
                        <button className="text-blue-500">Invoice download</button>
                    </div>

                    {/* Shipping Details */}
                    <div className="bg-white p-6 border rounded mb-6">
                        <h2 className="text-xl font-bold mb-4">Shipping Details</h2>
                        <p className="text-gray-600">Ajay</p>
                        <p className="text-gray-600">Awadh apartment</p>
                        <p className="text-gray-600">Rahim Nagar, Mahanagar, Star Car wash</p>
                        <p className="text-gray-600">Lucknow</p>
                        <p className="text-gray-600">Uttar Pradesh - 226006</p>
                        <p className="text-gray-600">Phone number: 6392363003</p>
                    </div>

                    {/* Price Details */}
                    <div className="bg-white p-6 border rounded mb-6">
                        <h2 className="text-xl font-bold mb-4">Price Details</h2>
                        <div className="space-y-2">
                            <p className="text-gray-600">List price ₹7,990</p>
                            <p className="text-gray-600">Selling price ₹3,399</p>
                            <p className="text-gray-600">Extra Discount - ₹100</p>
                            <p className="text-gray-600">Special Price ₹3,299</p>
                            <p className="text-gray-600">Delivery Charge ₹40 Free</p>
                            <p className="text-gray-600">Platform fee ₹3</p>
                        </div>
                        <p className="text-2xl font-bold mt-4">Total Amount ₹3,302</p>
                    </div>

                    {/* Offers Section */}
                    <div className="bg-white p-6 border rounded">
                        <h2 className="text-xl font-bold mb-4">2 offers:</h2>
                        <div className="space-y-2">
                            <p className="text-gray-600">Get Extra ₹650 off on Round Trip Flight booking <span className="text-blue-500">Know more</span></p>
                            <p className="text-gray-600">Get Flat ₹500 off on Hotel booking <span className="text-blue-500">Know more</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderDetail;