import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import client from './client.jsx';
import { Provider } from 'react-redux';
import store from './store';
// Mutation imports
import { LOGIN, VerifyOTP, IS_TOKEN_VALID } from './actions/mutation.jsx';

// Page imports
import Home from './pages/HomePage';
import ProductDetail from './pages/ProductDetail';
import NotFound from './pages/NotFound.jsx';
import ShoppingCart from './pages/CartPage.jsx';
import AllProduct from './pages/AllProduct.jsx';
import CheckoutPage from './pages/CheckoutPage.jsx';
// import EmailOtpModal from './pages/Login.jsx';
import OrderList from './pages/OrdersList.jsx';
import OrderDetail from './pages/OrderDetails.jsx';
// Component imports
import Header from './components/Header/Header.js';
import Footer from './components/Footer/Footer.jsx';
import Profile from './pages/Profile';
import AllCategories from './pages/AllCategories';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import Careers from './pages/Careers';
import MyAccount from './pages/MyAccount';
import Shipping from './pages/Shipping';
import Returns from './pages/Returns';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import FAQs from './pages/FAQs';
import Wishlist from './pages/Wishlist';
// Protected Route Component
const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const authToken = localStorage.getItem('authToken');

  return authToken ? (
    children
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

function AppContent() {
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userId, setuserId] = useState(false);
  const [emailOrMobile, setEmailOrMobile] = useState('');
  const queryClient = new QueryClient();
  React.useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    const userEmail = localStorage.getItem('userEmail');
    const userId = localStorage.getItem('userId');

    const validateToken = async () => {
      if (authToken) {
        try {
          const response = await client.mutate({
            mutation: IS_TOKEN_VALID,
            variables: { token: authToken },
          });

          const isValid = response.data.isTokenValid.isValid;

          if (isValid) {
            setIsTokenValid(true);
            setIsLoggedIn(true);
            setuserId(parseInt(userId));
            setEmailOrMobile(userEmail || '');
          } else {
            // Clear invalid token and user data
            localStorage.removeItem('authToken');
            localStorage.removeItem('userEmail');
            localStorage.removeItem('userId');
            setIsLoggedIn(false);
            setuserId(false);
            setEmailOrMobile('');
          }
        } catch (error) {
          console.error('Token validation error:', error);
          // Handle error case similar to invalid token
          localStorage.removeItem('authToken');
          localStorage.removeItem('userEmail');
          localStorage.removeItem('userId');
          setIsLoggedIn(false);
          setuserId(false);
          setEmailOrMobile('');
        }
      }
    };

    validateToken();
  }, []);

  const handleLogin = () => {
    console.log('Login handler called');
    setIsModalOpen(true);
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Header onLogin={handleLogin} isLoggedIn={!!userId} />

      <main className="flex-grow">
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/products/:id" element={<AllProduct />} />
            <Route path="/categories" element={<AllCategories />} />
            <Route path="/product/details/:id" element={<ProductDetail />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/account" element={<MyAccount />} />
            <Route path="/shipping" element={<Shipping />} />
            <Route path="/returns" element={<Returns />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/faqs" element={<FAQs />} />
            <Route path="/wishlist" element={<Wishlist />} />

            {/* Protected Routes */}
            <Route
              path="/orders/:id"
              element={
                <ProtectedRoute>
                  <OrderList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/order/details/:id"
              element={
                <ProtectedRoute>
                  <OrderDetail />
                </ProtectedRoute>
              }
            />
            <Route path="/cart/:userId" element={<ShoppingCart />} />
            <Route path="/checkout" element={<CheckoutPage />} />
            <Route
              path="/profile/:id"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </QueryClientProvider>
      </main>
      {/* <EmailOtpModal open={isModalOpen} setOpen={setIsModalOpen} /> */}
      <div className="w-full h-[1px] bg-gray-200"></div>
      <Footer />
    </div>
  );
}

function App() {
  const [showScrollTop, setShowScrollTop] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // Add scroll event listener for both subscribe modal and scroll-to-top button
  React.useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <Router>
          <AppContent />
        </Router>
      </Provider>
      {showScrollTop && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-8 right-8 bg-black text-white w-12 h-12 rounded-full shadow-lg flex items-center justify-center hover:bg-gray-800 transition-all duration-300 z-50"
          aria-label="Scroll to top"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 10l7-7m0 0l7 7m-7-7v18"
            />
          </svg>
        </button>
      )}
    </ApolloProvider>
  );
}

export default App;
