import React from 'react';

const Shipping = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Shipping Information</h1>
      <div className="prose max-w-none">
        <p className="mb-4">Learn about our shipping policies and delivery options.</p>
        {/* Add shipping information content here */}
      </div>
    </div>
  );
};

export default Shipping; 