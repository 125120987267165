import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { VerifyOTP, createUserWithEmail } from '../actions/mutation';
import { X } from 'lucide-react';

const EmailOtpModal = ({ open, setOpen }) => {
  const [step, setStep] = useState('email'); // 'email' or 'otp'
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [loginLoading, setLoginLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);

  // Initialize GraphQL mutations
  const [verifyOTP] = useMutation(VerifyOTP);
  const [createUser] = useMutation(createUserWithEmail);

  const handleClose = () => setOpen(false);

  // Handle email submission
  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setLoginLoading(true);

    try {
      await createUser({ variables: { input: { email } } });
      setLoginLoading(false);
      setStep('otp');
    } catch (error) {
      console.error('Error creating user with email:', error);
      setLoginLoading(false);
    }
  };

  // Handle OTP submission
  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setOtpLoading(true);

    try {
      const { data } = await verifyOTP({ variables: { email, otp } });
      localStorage.setItem('authToken', data.verifyOTP.token);
      localStorage.setItem('userId', data.verifyOTP.userId);
      localStorage.setItem('userEmail', email);
      alert(`OTP Verified! Message: ${data.verifyOTP.message}`);
      window.location.reload();
      handleClose();
    } catch (error) {
      console.error('Error verifying OTP:', error);
      alert('Failed to verify OTP. Please try again.');
    } finally {
      setOtpLoading(false);
    }
  };

  if (!open) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg w-96 p-8 relative mx-4 shadow-lg">
        {/* Close Button */}
        <button
          onClick={handleClose}
          className="absolute right-4 top-4 p-1 rounded-sm hover:bg-pink-50 transition-colors duration-200"
        >
          <X className="h-4 w-4 text-gray-500 hover:text-pink-500" />
        </button>

        {/* Header Section */}
        <div className="mb-8 text-center">
          <h2 className="text-2xl font-semibold text-gray-900 mb-2">Welcome</h2>
          <p className="text-sm text-gray-500">
            {step === 'email'
              ? 'Enter your email to continue'
              : 'Enter the OTP sent to your email'}
          </p>
        </div>

        {/* Form Section */}
        {step === 'email' ? (
          <form onSubmit={handleEmailSubmit} className="space-y-6">
            <div>
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-pink-500 focus:ring-1 focus:ring-pink-500"
              />
            </div>
            <button
              type="submit"
              className="w-full bg-pink-500  text-white rounded-lg py-2 hover:bg-pink-600 transition-colors duration-200"
            >
              {loginLoading ? 'Sending...' : 'Get OTP'}
            </button>
          </form>
        ) : (
          <form onSubmit={handleOtpSubmit} className="space-y-6">
            <div>
              <input
                type="text"
                placeholder="Enter OTP"
                maxLength={6}
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
                className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-pink-500 focus:ring-1 focus:ring-pink-500"
              />
              <p className="mt-2 text-sm text-gray-500 text-center">
                Didn't receive OTP?{' '}
                <button
                  type="button"
                  onClick={() => setStep('email')}
                  className="text-pink-500 hover:text-pink-600 hover:underline"
                >
                  Resend
                </button>
              </p>
            </div>
            <button
              type="submit"
              className="w-full bg-pink-500 text-white rounded-lg py-2 hover:bg-pink-600 transition-colors duration-200"
            >
              {otpLoading ? 'Verifying...' : 'Verify & Continue'}
            </button>
          </form>
        )}

        {/* Terms and Conditions */}
        <div className="mt-6 text-center text-sm text-gray-500">
          By continuing, you agree to our{' '}
          <button className="text-pink-500 hover:text-pink-600 hover:underline">
            Terms & Conditions
          </button>{' '}
          and{' '}
          <button className="text-pink-500 hover:text-pink-600 hover:underline">
            Privacy Policy
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailOtpModal;
