import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  globalSearchQuery,
  GET_CART_ITEMS,
  GET_CART_BY_SESSIONID,
} from '../../actions/query';
import TopHeader from '../TopHeader/TopHeader';
import { useMutation } from '@apollo/client';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { auth } from '../../firebase';
import { LOGIN } from '../../actions/mutation';
import { connect } from 'react-redux';
import {
  loginStart,
  loginSuccess,
  loginFailure,
  logout,
  setIsLoggedIn,
} from '../../store/slice/auth-slice';
const TextLogo = require('../../assets/text-logo.png');
const USER_ID_KEY = 'userId';
const Header = ({
  onLogin,
  isLoggedIn,
  loginStart,
  loginSuccess,
  loginFailure,
  logout,
  user,
  loading,
  error,
  setIsLoggedIn,
}) => {
  const [phone, setPhone] = useState('');
  const [loginMutation, { loading: loginLoading }] = useMutation(LOGIN);
  const [hasFilled, setHasFilled] = useState(false);
  const [otp, setOtp] = useState('');
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  const navigate = useNavigate();

  const generateRecaptcha = () => {
    // Check if recaptcha container exists
    const recaptchaContainer = document.getElementById('recaptcha');
    if (!recaptchaContainer) {
      console.error('Recaptcha container not found');
      return;
    }

    // Clear any existing verifier and container content
    if (window.recaptchaVerifier) {
      window.recaptchaVerifier.clear();
      // Remove any child elements from the container
      while (recaptchaContainer.firstChild) {
        recaptchaContainer.removeChild(recaptchaContainer.firstChild);
      }
    }

    // Create new verifier
    window.recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha',
      {
        size: 'invisible',
        callback: (response) => {
          // reCAPTCHA solved
        },
      },
      auth
    );
  };

  const handleSend = (event) => {
    event.preventDefault();
    if (!phone) {
      alert('Please enter a phone number');
      return;
    }
    setHasFilled(true);

    // Small delay to ensure DOM is ready
    setTimeout(() => {
      generateRecaptcha();
      let appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(auth, phone, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
        })
        .catch((error) => {
          console.log(error);
          alert('Error sending OTP. Please try again.');
          setHasFilled(false);
        });
    }, 100);
  };

  const verifyOtp = (event) => {
    let otp = event.target.value;
    setOtp(otp);

    if (otp.length === 6) {
      if (!window.confirmationResult) {
        console.error('No confirmation result found');
        alert('Session expired. Please request OTP again.');
        setHasFilled(false);
        return;
      }

      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(otp)
        .then((result) => {
          console.log('Firebase auth successful:', result);
          const user = result.user;
          localStorage.setItem('authToken', user.accessToken);

          loginStart();
          loginMutation({
            variables: { phone },
            onCompleted: (data) => {
              console.log('Login mutation response:', data);

              if (!data?.login?.token) {
                console.error('Invalid login response structure:', data);
                throw new Error('Invalid login response');
              }

              // Store the JWT token from our backend
              localStorage.setItem('authToken', data.login.token);
              localStorage.setItem('userId', data?.login?.user?.id);

              // Create a user object from the available data
              const userData = {
                phone: data.login.user.phone,
                token: data.login.token,
                userId: data?.login?.user?.id,
              };

              console.log('Login successful, user data:', userData);
              loginSuccess(userData);
              setShowLoginModal(false);

              setHasFilled(false);
              setOtp('');
              setPhone('');
              if (onLogin) onLogin();
            },
            onError: (error) => {
              console.error('Login mutation error:', error);
              console.error('Full error object:', {
                message: error.message,
                networkError: error.networkError,
                graphQLErrors: error.graphQLErrors,
              });

              alert('Error logging in. Please try again.');
              loginFailure(error.message || 'Login failed');
              setShowLoginModal(false);
              setHasFilled(false);
            },
          });
        })
        .catch((error) => {
          console.error('OTP verification error:', error);
          console.error('Full OTP error:', {
            code: error.code,
            message: error.message,
            stack: error.stack,
          });

          loginFailure(error.message || 'OTP verification failed');
          alert('Invalid OTP. Please try again.');
          setOtp('');
        });
    }
  };
  const userId = localStorage.getItem(USER_ID_KEY) || 0;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const searchResultsRef = useRef(null);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const sessionId = React.useMemo(() => {
    const existingSessionId = localStorage.getItem('sessionId');
    if (existingSessionId) return existingSessionId;

    const newSessionId = 'sess_' + Math.random().toString(36).substr(2, 9);
    localStorage.setItem('sessionId', newSessionId);
    return newSessionId;
  }, []);

  const queryVariables = React.useMemo(() => {
    return isLoggedIn
      ? {
          userId: parseInt(userId),
          pagination: {
            take: 10,
            skip: 0,
          },
        }
      : {
          sessionId,
          expiresAt: new Date(Date.now() + 24 * 60 * 60 * 1000),
        };
  }, [isLoggedIn, userId, sessionId]);

  const { data: cartData } = useQuery(
    isLoggedIn ? GET_CART_ITEMS : GET_CART_BY_SESSIONID,
    {
      variables: queryVariables,
      fetchPolicy: 'cache-and-network',
    }
  );

  const cartItems = isLoggedIn
    ? cartData?.getCarts?.[0]?.cartItems || []
    : cartData?.getCartBySessionId?.cartItems || [];

  const cartCount = cartItems.length;

  const [executeSearch, { loading: searchLoading }] = useLazyQuery(
    globalSearchQuery,
    {
      onCompleted: (data) => {
        setSearchResults(data.globalSearch);
        setShowResults(true);
      },
      onError: (error) => {
        console.error('Search error:', error);
        setSearchResults([]);
      },
    }
  );

  // Add useEffect to check auth state
  useEffect(() => {
    const checkAuth = () => {
      const authToken = localStorage.getItem('authToken');
      setIsLoggedIn(!!authToken);
    };

    // Check on mount
    checkAuth();

    // Add event listener for storage changes
    window.addEventListener('storage', checkAuth);

    // Custom event listener for logout
    const handleLogout = () => {
      setIsLoggedIn(false);
    };
    window.addEventListener('logout', handleLogout);

    return () => {
      window.removeEventListener('storage', checkAuth);
      window.removeEventListener('logout', handleLogout);
    };
  }, []);

  // Add a ref to track if we're processing a click
  const isProcessingRef = useRef(false);

  // Update the search toggle handler
  const handleSearchToggle = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    // Prevent multiple rapid clicks
    if (isProcessingRef.current) return;
    isProcessingRef.current = true;

    // Toggle search UI
    setIsSearchOpen((prev) => !prev);

    // Focus search input if opening
    if (!isSearchOpen) {
      setTimeout(() => {
        const searchInput = document.querySelector('[data-search-input]');
        if (searchInput) searchInput.focus();
      }, 100);
    }

    // Reset processing flag after a delay
    setTimeout(() => {
      isProcessingRef.current = false;
    }, 300);
  };

  // Update the useEffect for click outside handling
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Don't handle clicks if we're processing a click
      if (isProcessingRef.current) return;

      // Don't handle clicks if the search UI isn't open
      if (!isSearchOpen) return;

      // Check if click is outside search area and not on search button
      if (
        searchResultsRef.current &&
        !searchResultsRef.current.contains(event.target) &&
        !event.target.closest('[data-search-button]')
      ) {
        setShowResults(false);
        setIsSearchOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [isSearchOpen]);

  const toggleDropdown = (event) => {
    // Prevent event bubbling
    event.stopPropagation();
    setUserDropdownOpen((prev) => !prev);
  };

  const handleSelectOption = (option) => {
    console.log('handleSelectOption called with:', option);

    const currentUserId = localStorage.getItem('userId');
    console.log('Current userId:', currentUserId);

    if (!currentUserId) {
      console.error('No user ID found');
      return;
    }

    try {
      switch (option) {
        case 'Profile':
          console.log('Navigating to profile');
          navigate(`/profile/${currentUserId}`);
          break;
        case 'Settings':
          console.log('Navigating to settings');
          navigate(`/settings/${currentUserId}`);
          break;
        case 'Orders':
          console.log('Navigating to orders');
          navigate(`/orders/${currentUserId}`);
          break;
        default:
          console.warn('Unknown option selected:', option);
      }
    } catch (error) {
      console.error('Navigation error:', error);
    }

    setUserDropdownOpen(false);
    setIsMenuOpen(false);
  };

  const onAddToCart = () => {
    navigate(`/cart/${userId}`);
  };

  const handleLoginClick = () => {
    console.log('Login button clicked');
    // Reset all states when opening login modal
    setPhone('');
    setOtp('');
    setHasFilled(false);
    setShowLoginModal(true);
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.length >= 2) {
      executeSearch({ variables: { query } });
    } else {
      setShowResults(false);
    }
  };

  const handleResultClick = (result) => {
    navigate(`/product/details/${result.id}`);
    setShowResults(false);
    setSearchQuery('');
  };

  const handleLogoutClick = () => {
    setShowLogoutConfirm(true);
  };

  const handleLogoutConfirm = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('userEmail');
    setIsLoggedIn(false);
    setShowLogoutConfirm(false);
  };

  // Update button handlers with debounce
  const handleButtonClick = (callback) => (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    // Prevent multiple clicks
    if (e.target.dataset.processing) return;
    e.target.dataset.processing = 'true';

    // Execute callback after a small delay
    setTimeout(() => {
      callback();
      delete e.target.dataset.processing;
    }, 100);
  };

  const loginFormJSX = (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold">Login</h2>
            <button
              onClick={() => {
                setShowLoginModal(false);
                setHasFilled(false);
              }}
              className="text-gray-500 hover:text-gray-700"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          {!hasFilled ? (
            <div className="space-y-4">
              <PhoneInput
                international
                defaultCountry="IN"
                value={phone}
                onChange={setPhone}
                className="w-full p-2 border rounded"
              />
              <button
                onClick={handleSend}
                className="w-full bg-pink-500 text-white py-2 rounded hover:bg-pink-600"
              >
                Send OTP
              </button>
            </div>
          ) : (
            <div className="space-y-4">
              <input
                type="text"
                value={otp}
                onChange={verifyOtp}
                placeholder="Enter OTP"
                maxLength={6}
                className="w-full p-2 border rounded"
                autoFocus
                onBlur={(e) => e.target.focus()}
              />
              <p className="text-sm text-gray-600">
                Please enter the 6-digit code sent to {phone}
              </p>
            </div>
          )}
        </div>
      </div>
      <div id="recaptcha" className="!invisible fixed top-0 left-0"></div>
    </>
  );

  const logoutConfirmJSX = (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
        <h2 className="text-xl font-semibold mb-4">Confirm Logout</h2>
        <p className="text-gray-600 mb-6">Are you sure you want to logout?</p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={() => setShowLogoutConfirm(false)}
            className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={handleLogoutConfirm}
            className="px-4 py-2 bg-pink-500 text-white rounded-lg hover:bg-pink-600 transition-colors"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <header className="fixed top-0 left-0 w-full z-50 border-b border-gray-200 bg-white">
      {/* <TopHeader /> */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Mobile View */}
        <div className="flex items-center justify-between h-16 lg:hidden">
          <Link
            to="/"
            onClick={(e) => {
              e.preventDefault();
              navigate('/');
              setIsMenuOpen(false);
              setUserDropdownOpen(false);
              setShowResults(false);
              setIsSearchOpen(false);
            }}
            onTouchStart={(e) => {
              e.preventDefault();
              e.stopPropagation();
              navigate('/');
              setIsMenuOpen(false);
              setUserDropdownOpen(false);
              setShowResults(false);
              setIsSearchOpen(false);
            }}
            className="font-bold cursor-pointer flex items-center flex-row touch-manipulation"
          >
            <img
              src={TextLogo}
              alt="Logo"
              className="h-6 w-auto"
              style={{
                WebkitTapHighlightColor: 'transparent',
                WebkitTouchCallout: 'none',
                touchAction: 'manipulation',
              }}
            />
          </Link>

          <div className="flex items-center space-x-4">
            <div className="relative" ref={searchResultsRef}>
              <button
                data-search-button
                onClick={handleSearchToggle}
                onTouchStart={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleSearchToggle(e);
                }}
                className="p-2 text-gray-700 touch-manipulation select-none"
                style={{
                  WebkitTapHighlightColor: 'transparent',
                  WebkitTouchCallout: 'none',
                  touchAction: 'manipulation',
                  userSelect: 'none',
                  outline: 'none',
                }}
              >
                <svg
                  className={`h-6 w-6 ${
                    loading || searchLoading
                      ? 'text-purple-500'
                      : 'text-gray-700'
                  }`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </button>

              {isSearchOpen && (
                <div
                  className="fixed top-16 left-0 right-0 bg-white z-50 p-4 shadow-lg max-h-[80vh] overflow-auto"
                  onClick={(e) => e.stopPropagation()}
                  onTouchStart={(e) => e.stopPropagation()}
                  data-search-container
                >
                  <div className="flex items-center justify-between mb-4">
                    <div className="flex-1 relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <svg
                          className={`h-5 w-5 ${
                            loading || searchLoading
                              ? 'text-purple-500'
                              : 'text-gray-400'
                          }`}
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                          />
                        </svg>
                      </div>
                      <input
                        type="text"
                        value={searchQuery}
                        onChange={handleSearch}
                        placeholder="Search for products..."
                        className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-full bg-gray-50 text-sm placeholder-gray-500 focus:outline-none focus:border-gray-300 focus:ring-1 focus:ring-gray-300"
                        data-search-input
                        style={{
                          WebkitTapHighlightColor: 'transparent',
                          WebkitAppearance: 'none',
                        }}
                      />
                    </div>
                    <button
                      onClick={handleButtonClick(() => {
                        setIsSearchOpen(false);
                        setSearchQuery('');
                        setShowResults(false);
                      })}
                      onTouchStart={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleButtonClick(() => {
                          setIsSearchOpen(false);
                          setSearchQuery('');
                          setShowResults(false);
                        })(e);
                      }}
                      className="ml-4 p-2 text-gray-500 touch-manipulation select-none"
                      style={{
                        WebkitTapHighlightColor: 'transparent',
                        WebkitTouchCallout: 'none',
                        touchAction: 'manipulation',
                        userSelect: 'none',
                      }}
                    >
                      <svg
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>

                  {showResults && searchResults.length > 0 && (
                    <div className="mt-4">
                      {searchResults.map((result) => (
                        <button
                          key={result.id}
                          onClick={handleButtonClick(() =>
                            handleResultClick(result)
                          )}
                          onTouchStart={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleButtonClick(() => handleResultClick(result))(
                              e
                            );
                          }}
                          className="block w-full text-left px-4 py-2 hover:bg-gray-100 border-b border-gray-100 last:border-0 touch-manipulation select-none"
                          style={{
                            WebkitTapHighlightColor: 'transparent',
                            WebkitTouchCallout: 'none',
                            touchAction: 'manipulation',
                            userSelect: 'none',
                          }}
                        >
                          <div className="flex items-center">
                            {result.skus[0]?.images[0] && (
                              <img
                                src={result.skus[0].images[0]}
                                alt={result.name}
                                className="w-12 h-12 object-cover rounded mr-3"
                              />
                            )}
                            <div>
                              <div className="font-medium text-gray-900">
                                {result.name}
                              </div>
                              <div className="text-sm text-gray-500">
                                {result.category?.name}
                              </div>
                              <div className="text-sm font-medium text-pink-500">
                                ₹{result.price}
                              </div>
                            </div>
                          </div>
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>

            <button
              onClick={handleButtonClick(onAddToCart)}
              onTouchStart={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleButtonClick(onAddToCart)(e);
              }}
              className="p-2 text-gray-700 relative touch-manipulation select-none"
              style={{
                WebkitTapHighlightColor: 'transparent',
                WebkitTouchCallout: 'none',
                touchAction: 'manipulation',
                userSelect: 'none',
              }}
            >
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                />
              </svg>
              {cartCount > 0 && (
                <span className="absolute -top-1 -right-1 bg-pink-500 text-white text-xs font-bold rounded-full h-5 w-5 flex items-center justify-center animate-pulse">
                  {cartCount}
                </span>
              )}
            </button>

            {isLoggedIn ? (
              <div ref={dropdownRef} className="relative">
                <button
                  onClick={handleButtonClick(() =>
                    setUserDropdownOpen((prev) => !prev)
                  )}
                  onTouchStart={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleButtonClick(() =>
                      setUserDropdownOpen((prev) => !prev)
                    )(e);
                  }}
                  className="p-2 text-gray-700 touch-manipulation select-none"
                  style={{
                    WebkitTapHighlightColor: 'transparent',
                    WebkitTouchCallout: 'none',
                    touchAction: 'manipulation',
                    userSelect: 'none',
                  }}
                >
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                    />
                  </svg>
                </button>

                {userDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-[9999] lg:hidden">
                    <div className="py-1 bg-white">
                      {[
                        { label: 'Profile', path: '/profile' },
                        { label: 'Settings', path: '/settings' },
                        { label: 'Orders', path: '/orders' },
                      ].map((item) => (
                        <button
                          key={item.label}
                          className="block w-full text-left px-4 py-2 text-sm text-gray-700 
                            hover:bg-gray-100 active:bg-gray-200 
                            cursor-pointer touch-manipulation"
                          onTouchStart={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            const currentUserId =
                              localStorage.getItem('userId');
                            if (currentUserId) {
                              e.currentTarget.classList.add('bg-gray-200');
                              setTimeout(() => {
                                navigate(`${item.path}/${currentUserId}`);
                                setUserDropdownOpen(false);
                                setIsMenuOpen(false);
                              }, 150);
                            }
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            const currentUserId =
                              localStorage.getItem('userId');
                            if (currentUserId) {
                              navigate(`${item.path}/${currentUserId}`);
                              setUserDropdownOpen(false);
                              setIsMenuOpen(false);
                            }
                          }}
                        >
                          {item.label}
                        </button>
                      ))}
                      <button
                        className="block w-full text-left px-4 py-2 text-sm text-red-600 
                          hover:bg-gray-100 active:bg-gray-200 
                          cursor-pointer touch-manipulation"
                        onTouchStart={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          e.currentTarget.classList.add('bg-gray-200');
                          setTimeout(() => {
                            handleLogoutClick();
                            setUserDropdownOpen(false);
                            setIsMenuOpen(false);
                          }, 150);
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleLogoutClick();
                          setUserDropdownOpen(false);
                          setIsMenuOpen(false);
                        }}
                      >
                        Logout
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <button
                onClick={handleButtonClick(handleLoginClick)}
                onTouchStart={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleButtonClick(handleLoginClick)(e);
                }}
                className="text-sm text-gray-700 hover:bg-gray-100 px-3 py-2 rounded-lg transition-colors touch-manipulation select-none"
                style={{
                  WebkitTapHighlightColor: 'transparent',
                  WebkitTouchCallout: 'none',
                  touchAction: 'manipulation',
                  userSelect: 'none',
                }}
              >
                Login
              </button>
            )}
          </div>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="lg:hidden border-t border-gray-200">
            <div className="pt-2 pb-3 space-y-1">
              {['Shop', 'On Sale', 'New Arrivals', 'Brands'].map((item) => (
                <Link
                  key={item}
                  to={`/${item.toLowerCase().replace(' ', '-')}`}
                  className="block px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50"
                >
                  {item}
                </Link>
              ))}
            </div>
          </div>
        )}

        {/* Desktop View */}
        <div className="hidden lg:flex items-center justify-between h-16">
          <div className="flex items-center space-x-8">
            <Link
              to="/"
              onClick={() => {
                setIsMenuOpen(false);
                setUserDropdownOpen(false);
                setShowResults(false);
              }}
              className="font-bold cursor-pointer flex items-center flex-row"
            >
              <img src={TextLogo} alt="Logo" className="h-6 w-auto" />
            </Link>
          </div>

          <div className="flex flex-1 justify-center max-w-xl px-6">
            <div className="w-full relative" ref={searchResultsRef}>
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg
                  className={`h-5 w-5 ${
                    loading
                      ? 'text-purple-500'
                      : 'text-gray-400' || searchLoading
                      ? 'text-purple-500'
                      : 'text-gray-400'
                  }`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearch}
                placeholder="Search for products..."
                className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-full bg-gray-50 text-sm placeholder-gray-500 focus:outline-none focus:border-pink-300 focus:ring-1 focus:ring-pink-300"
              />

              {showResults && searchResults.length > 0 && (
                <div className="absolute mt-2 w-full bg-white border border-gray-200 rounded-lg shadow-lg z-50 max-h-96 overflow-y-auto">
                  {searchResults.map((result) => (
                    <button
                      key={result.id}
                      onClick={() => handleResultClick(result)}
                      className="block w-full text-left px-4 py-2 hover:bg-gray-50 border-b border-gray-100 last:border-0"
                    >
                      <div className="flex items-center">
                        {result.skus[0]?.images[0] && (
                          <img
                            src={result.skus[0].images[0]}
                            alt={result.name}
                            className="w-12 h-12 object-cover rounded mr-3"
                          />
                        )}
                        <div>
                          <div className="font-medium text-gray-900">
                            {result.name}
                          </div>
                          <div className="text-sm text-gray-500">
                            {result.category?.name}
                          </div>
                          <div className="text-sm font-medium text-pink-500">
                            ₹{result.price}
                          </div>
                        </div>
                      </div>
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="flex items-center space-x-4">
            <button
              onClick={handleButtonClick(onAddToCart)}
              className="p-2 text-gray-700 hover:text-gray-900 rounded-lg hover:bg-gray-100 relative transition-colors"
            >
              <svg
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                />
              </svg>
              {cartCount > 0 && (
                <span className="absolute -top-1 -right-1 bg-pink-500 text-white text-xs font-bold rounded-full h-5 w-5 flex items-center justify-center animate-pulse">
                  {cartCount}
                </span>
              )}
            </button>

            {isLoggedIn ? (
              <div ref={dropdownRef} className="relative">
                <button
                  onClick={handleButtonClick(() =>
                    setUserDropdownOpen((prev) => !prev)
                  )}
                  onTouchStart={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleButtonClick(() =>
                      setUserDropdownOpen((prev) => !prev)
                    )(e);
                  }}
                  className="p-2 text-gray-700 hover:text-gray-900 rounded-lg hover:bg-gray-100 transition-colors"
                >
                  <svg
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                    />
                  </svg>
                </button>

                {userDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg">
                    <div className="py-1">
                      {['Profile', 'Settings', 'Orders'].map((option) => (
                        <button
                          key={option}
                          onClick={() => handleSelectOption(option)}
                          className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 transition-colors"
                        >
                          {option}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <button
                onClick={handleButtonClick(handleLoginClick)}
                onTouchStart={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleButtonClick(handleLoginClick)(e);
                }}
                className="text-sm text-gray-700 hover:bg-gray-100 px-3 py-2 rounded-lg transition-colors touch-manipulation select-none"
                style={{
                  WebkitTapHighlightColor: 'transparent',
                  WebkitTouchCallout: 'none',
                  touchAction: 'manipulation',
                  userSelect: 'none',
                }}
              >
                Login
              </button>
            )}
          </div>
        </div>
      </div>
      {showLoginModal && loginFormJSX}
      {showLogoutConfirm && logoutConfirmJSX}
    </header>
  );
};
const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isAuthenticated,
  user: state.auth.user,
  loading: state.auth.loading,
  error: state.auth.error,
});

const mapDispatchToProps = {
  loginStart: loginStart,
  loginSuccess: loginSuccess,
  loginFailure: loginFailure,
  logout: logout,
  setIsLoggedIn: setIsLoggedIn,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
