import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import back from '../../assets/background/back.jpg';
import LoginModal from '../../pages/Login';
import { useMutation, useQuery } from '@apollo/client';
import { LOGIN, VerifyOTP } from '../../actions/mutation';
import { getCategoryByIdQuery } from '../../actions/query';
const ProductDescription = ({ description }) => (
  <div className="mt-6">
    <div
      dangerouslySetInnerHTML={{ __html: description }}
      className="product-description prose prose-sm max-w-none"
    />
  </div>
);

const ProductInfo = ({ product, selectedSku, setSelectedSku, handleAddToCart, uploadImage }) => {

  console.log("ProductInfo1211", product);
  const [quantity, setQuantity] = useState(1);
  const [email, setEmail] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [step, setStep] = useState('email');
  const [selectedVariants, setSelectedVariants] = useState({});

  // GraphQL mutations
  const [login, { loading: loginLoading }] = useMutation(LOGIN);
  const [verifyOTP, { loading: otpLoading }] = useMutation(VerifyOTP);

  // Set the default selected SKU when the product changes
  useEffect(() => {
    if (product?.skus?.length > 0 && !selectedSku) {
      setSelectedVariants(product.skus[0].variants);
      setSelectedSku(product.skus[0]);
    }
  }, [product, selectedSku, setSelectedSku]);

  // Get unique variant types from the first SKU
  const variantTypes = product?.skus?.length > 0
    ? Object.keys(product.skus[0].variants)
    : [];

  // Get unique values for each variant type
  const getUniqueVariantValues = (variantType) => {
    const values = new Set();
    product?.skus?.forEach(sku => {
      if (sku.variants[variantType]) {
        values.add(sku.variants[variantType]);
      }
    });
    return Array.from(values);
  };

  // Handle variant selection
  const handleVariantChange = (variantType, value) => {
    const newSelectedVariants = {
      ...selectedVariants,
      [variantType]: value
    };
    setSelectedVariants(newSelectedVariants);

    // Find matching SKU
    const matchingSku = product.skus.find(sku =>
      Object.entries(newSelectedVariants).every(([key, value]) =>
        sku.variants[key] === value
      )
    );

    if (matchingSku) {
      setSelectedSku(matchingSku);
    }
  };

  const SizeGuideModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
      <>
        {/* Modal Overlay */}
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50" onClick={onClose} />

        {/* Modal Content */}
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 z-50 w-full max-w-2xl">
          {/* Header */}
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-medium">Size Guide</h2>
            <button onClick={onClose} className="p-1">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          {/* Size Chart Image */}
          <div className="overflow-x-auto">
            <img
              src={product.sizeChartImage}
              alt="Size Chart"
              className="w-full h-auto"
            />
          </div>
        </div>
      </>
    );
  };
  const calculateDiscount = () => {
    if (product?.originalPrice && selectedSku?.price) {
      return Math.round(((product.originalPrice - selectedSku.price) / product.originalPrice) * 100);
    }
    return 0;
  };

  const onAddToCart = () => {
    console.log("selectedSku", selectedSku);

    if (!selectedSku) {
      alert('Please select product variants');
      return;
    }
    handleAddToCart(selectedSku.id, quantity);
  };

  // Update uniqueVariants calculation to handle the new structure
  const uniqueVariants = {
    color: new Set(product?.variants?.colors || []),
    size: new Set(product?.variants?.sizes || [])
  };

  // Update isOutOfStock calculation to only use SKU stock
  const isOutOfStock = selectedSku?.stock <= 0;

  // Add a function to check if SKU is selected
  const isSkuSelected = Boolean(selectedSku);

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await login({ variables: { email } });
      console.log(data); // Handle response as needed
      setStep('otp');
    } catch (error) {
      console.error("Login error:", error);
      alert("Login failed. Please try again.");
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    const otp = e.target[0].value; // Capture OTP from the input field
    try {
      const { data } = await verifyOTP({ variables: { email, otp } });
      setIsModalOpen(false);

      // Store user data in local storage
      localStorage.setItem("user", data.verifyOTP);

      handleAddToCart(selectedSku.id, quantity, data.verifyOTP.userId);

      // Optionally redirect or perform another action after successful verification
    } catch (error) {
      console.error("OTP verification error:", error);
      alert("OTP verification failed. Please try again.");
    }
  };
  const [isSizeGuideOpen, setIsSizeGuideOpen] = useState(false);
  const handleSizeGuideClick = () => {
    console.log('Size guide clicked');
    setIsSizeGuideOpen(true);
  };

  // Updated ratings calculation
  const calculateAverageRating = (ratings) => {
    if (!Array.isArray(ratings) || ratings.length === 0) return 0;
    const sum = ratings.reduce((acc, rating) => acc + rating.rating, 0);
    return (sum / ratings.length).toFixed(1);
  };
  console.log("product12111", product);

  const hasColors = product.skus.some(sku => sku.color);
  const { data: categoryData } = useQuery(getCategoryByIdQuery, {
    variables: { id: product?.categoryId },
  });
  const category = categoryData?.getCategoryById;
  return (
    <div className="space-y-6">
      {/* Category */}
      <div className="uppercase text-sm text-gray-500">
        {category?.name}
      </div>

      {/* Product Title */}
      <h1 className="text-2xl font-medium">{product?.name}</h1>


      {/* Ratings */}
      <div className="flex items-center gap-4 text-sm">
        <div className="flex items-center">
          <span>
            {calculateAverageRating(product?.ratings)} ({product?.ratings?.length || 0} reviews)
          </span>
        </div>
      </div>

      {/* Price */}
      <div className="text-xl font-medium">
        ₹{selectedSku?.price?.toFixed(2) || product?.price?.toFixed(2)}
      </div>
      <ProductDescription description={product?.description} />

      {/* Real-time Viewers */}
      <div className="text-sm text-gray-600">
        {product?.currentViewers || 28} people are viewing this right now
      </div>

      {/* Only show color selection if colors exist */}
      {hasColors && (
        <div className="space-y-3">
          <div className="flex justify-between items-center">
            <span>Colors: {selectedSku?.variants?.color || product?.variants?.colors?.[0]}</span>
          </div>
          <div className="flex gap-2">
            {Array.from(uniqueVariants.color || []).map((value) => {
              const skuWithVariant = product.skus.find(
                sku => sku.variants.color === value
              );
              return (
                <button
                  key={value}
                  className={`w-8 h-8 rounded-full border ${isOutOfStock ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                  onClick={() => !isOutOfStock && handleVariantChange('color', value)}
                  disabled={isOutOfStock}
                  style={{
                    border: selectedVariants.color === value ? '2px solid black' : '1px solid #ddd'
                  }}
                >
                  <img
                    src={skuWithVariant?.images?.[0]}
                    alt={value}
                    className="w-full h-full object-cover rounded-full"
                  />
                </button>
              );
            })}
          </div>
        </div>
      )}
      {/* Make sure the modal is rendered at the root level of the component */}
      <SizeGuideModal
        isOpen={isSizeGuideOpen}
        onClose={() => setIsSizeGuideOpen(false)}
      />
      {/* Size Selection - Updated */}
      {product?.variants?.sizes?.length > 0 && (
        <div className="space-y-3">
          <div className="flex justify-between items-center">
            <span>Selected size: {selectedSku?.variants?.size || product?.variants?.sizes?.[0]}</span>
            <button className="text-sm underline" onClick={handleSizeGuideClick}>Size Guide</button>
          </div>
          <div className="flex gap-2">
            {Array.from(uniqueVariants.size || []).map((value) => {
              const skuWithVariant = product.skus.find(
                sku => sku.variants.size === value
              );
              return (
                <button
                  key={value}
                  className={`w-10 h-10 rounded-full flex items-center justify-center ${selectedVariants.size === value
                    ? 'bg-black text-white'
                    : 'bg-white border hover:border-black'
                    } ${isOutOfStock ? 'opacity-50 cursor-not-allowed' : ''}`}
                  onClick={() => !isOutOfStock && handleVariantChange('size', value)}
                  disabled={isOutOfStock}
                >
                  {value}
                </button>
              );
            })}
          </div>
        </div>
      )}

      {/* Dynamic Variant Selectors */}
      {variantTypes.map(variantType => (
        <div key={variantType} className="space-y-3">
          <div className="flex justify-between items-center">
            <span className="text-sm font-medium">
              {variantType}: {selectedVariants[variantType] || 'Select ' + variantType}
            </span>
          </div>
          <div className="flex gap-2 flex-wrap">
            {getUniqueVariantValues(variantType).map(value => {
              const isSelected = selectedVariants[variantType] === value;
              return (
                <button
                  key={value}
                  onClick={() => handleVariantChange(variantType, value)}
                  className={`px-4 py-2 border rounded-md ${isSelected
                    ? 'border-black bg-black text-white'
                    : 'border-gray-300 hover:border-gray-400'
                    }`}
                >
                  {value}
                </button>
              );
            })}
          </div>
        </div>
      ))}

      {/* Quantity and Add to Cart section */}
      <div className="space-y-4">
        <div className="space-y-2">
          <span>Quantity:</span>
          <div className="flex items-center border rounded w-32">
            <button
              className="px-4 py-2 text-xl"
              onClick={() => setQuantity(Math.max(1, quantity - 1))}
            >
              -
            </button>
            <span className="flex-1 text-center">{quantity}</span>
            <button
              className="px-4 py-2 text-xl"
              onClick={() => setQuantity(quantity + 1)}
            >
              +
            </button>
          </div>
        </div>

        <button
          onClick={onAddToCart}
          disabled={!selectedSku}
          className={`w-full py-3 ${!selectedSku ? 'bg-gray-400' : 'bg-black'
            } text-white rounded`}
        >
          {!selectedSku ? 'SELECT VARIANT' : 'ADD TO CART'}
        </button>

        <button
          className="w-full py-3 bg-pink-500 text-white rounded"
          onClick={() => setIsModalOpen(true)}
          disabled={!selectedSku}
        >
          BUY IT NOW
        </button>
      </div>

      {/* Additional Info */}
      <div className="space-y-2 text-sm">
        <button className="flex items-center gap-2">
          <span>Delivery & Return</span>
        </button>
        <button className="flex items-center gap-2">
          <span>Ask A Question</span>
        </button>
        <button className="flex items-center gap-2">
          <span>Share</span>
        </button>
      </div>

      {/* Product Details */}
      {/* <div className="space-y-2 text-sm text-gray-600">
        <p>Estimated Delivery: 12-26 days(International) 3-6 days (United States)</p>
        <p>Return within 45 days of purchase. Duties & taxes are non-refundable.</p>
        <button className="underline">View Store Information</button>
      </div> */}

      {/* SKU Information */}
      <div className="space-y-1 text-sm text-gray-600">
        <p>SKU: {selectedSku?.sku || product?.skus?.[0]?.sku}</p>
        <p>Available: {selectedSku?.stock > 0 ? 'In Stock' : 'Out of Stock'}</p>
        {category && <p>Category: {category.name}</p>}
      </div>

      {/* Payment Methods */}
      <div className="space-y-2">
        <p className="text-sm">Guaranteed safe checkout:</p>
        <div className="flex gap-2">
          {/* Add payment method icons here */}
        </div>
      </div>

      {/* Login Modal */}
      <LoginModal
        open={isModalOpen}
        email={email}
        setEmail={setEmail}
        handleEmailSubmit={handleEmailSubmit}
        setOpen={() => setIsModalOpen(false)}
        handleOtpSubmit={handleOtpSubmit}
        step={step}
        loginLoading={loginLoading}
        otpLoading={otpLoading}
        setStep={setStep}
      />
    </div>
  );
};

export default ProductInfo;
