import React from 'react';

const Skeleton = ({ variant = 'rectangular', width, height, className = '', count = 1, animated = true }) => {
  // Base animation class for the loading effect
  const animationClass = animated ? 'animate-pulse' : '';
  
  // Generate multiple skeleton items if count > 1
  const items = [...Array(count)].map((_, index) => {
    let variantClasses = '';
    
    // Set variant-specific classes
    switch (variant) {
      case 'circular':
        variantClasses = 'rounded-full';
        break;
      case 'text':
        variantClasses = 'rounded-md h-4';
        break;
      case 'rectangular':
      default:
        variantClasses = 'rounded-md';
        break;
    }

    return (
      <div
        key={index}
        className={`bg-gray-200 ${variantClasses} ${animationClass} ${className}`}
        style={{
          width: width || '100%',
          height: height || (variant === 'text' ? '1rem' : '100px'),
          ...(index !== count - 1 && { marginBottom: '0.5rem' })
        }}
      />
    );
  });

  return <>{items}</>;
};

// Preset components for common use cases
const SkeletonText = ({ lines = 3, width, animated = true }) => (
  <div className="space-y-2">
    {[...Array(lines)].map((_, index) => (
      <Skeleton
        key={index}
        variant="text"
        width={index === lines - 1 ? (width || '75%') : '100%'}
        animated={animated}
      />
    ))}
  </div>
);

const SkeletonCard = ({ animated = true }) => (
  <div className="border rounded-lg p-4 space-y-4">
    <Skeleton
      variant="rectangular"
      height={200}
      className="rounded-lg"
      animated={animated}
    />
    <SkeletonText lines={2} animated={animated} />
    <Skeleton
      variant="text"
      width="40%"
      animated={animated}
    />
  </div>
);

const SkeletonProfile = ({ animated = true }) => (
  <div className="flex items-center space-x-4">
    <Skeleton
      variant="circular"
      width={50}
      height={50}
      animated={animated}
    />
    <div className="space-y-2 flex-1">
      <Skeleton
        variant="text"
        width="40%"
        animated={animated}
      />
      <Skeleton
        variant="text"
        width="60%"
        animated={animated}
      />
    </div>
  </div>
);

// Example usage component
const Example = () => {
  return (
    <div className="space-y-8 p-4 max-w-2xl">
      <div>
        <h3 className="text-lg font-semibold mb-4">Basic Skeleton</h3>
        <Skeleton />
      </div>

      <div>
        <h3 className="text-lg font-semibold mb-4">Text Skeleton</h3>
        <SkeletonText />
      </div>

      <div>
        <h3 className="text-lg font-semibold mb-4">Card Skeleton</h3>
        <SkeletonCard />
      </div>

      <div>
        <h3 className="text-lg font-semibold mb-4">Profile Skeleton</h3>
        <SkeletonProfile />
      </div>

      <div>
        <h3 className="text-lg font-semibold mb-4">Multiple Items</h3>
        <Skeleton count={3} height={100} className="rounded-lg" />
      </div>
    </div>
  );
};

export { Skeleton, SkeletonText, SkeletonCard, SkeletonProfile, Example };