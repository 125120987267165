import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { getProductByCatId } from '../actions/query';
import { uploadImageBaseURL } from '../utils/endpoint';
const CategoryItem = ({ category, categories }) => {
  const navigate = useNavigate();
  const { data: productData } = useQuery(getProductByCatId, {
    variables: { categoryId: category.id },
  });

  // Get all descendant category IDs (including the current category)
  const getDescendantCategoryIds = (categoryId) => {
    const descendants = [categoryId];
    const children = categories.filter(cat => {
      // Check if the category has a children property and match with parentId
      return cat.children?.some(child => child.id === categoryId) || cat.parentId === categoryId;
    });

    children.forEach(child => {
      if (child.children) {
        child.children.forEach(grandChild => {
          descendants.push(...getDescendantCategoryIds(grandChild.id));
        });
      }
    });

    return descendants;
  };

  // Calculate total products including those in descendant categories
  const getTotalProducts = () => {
    const descendantIds = getDescendantCategoryIds(category.id);
    const products = productData?.getProductsByCategoryId || [];
    let total = 0;

    // Count products for each descendant category
    descendantIds.forEach(id => {
      total += products.filter(product => product.categoryId === id).length;
    });

    return total;
  };

  const productCount = getTotalProducts();

  // Get full category path
  const getCategoryPath = (cat) => {
    const path = [];
    let currentCat = cat;

    while (currentCat) {
      path.unshift(currentCat.name);
      currentCat = categories.find(c => c.id === currentCat.parentId);
    }
    return path;
  };

  const categoryPath = getCategoryPath(category);
  return (
    <div
      onClick={() => navigate(`/products/${category.id}`)}
      className="cursor-pointer group"
    >
      <div className="flex flex-col items-center space-y-2">
        <div className="w-32 h-32 rounded-full bg-gray-100 flex items-center justify-center overflow-hidden p-2 border border-gray-200 transition-all duration-300 group-hover:shadow-lg">
          <img
            src={category.images?.[0]
              ? `${uploadImageBaseURL}/get-image/${category.images[0]}`
              : "/api/placeholder/128/128"}
            alt={category.name}
            className="w-full h-full rounded-full object-cover transition-transform duration-300 group-hover:scale-110"
            loading="lazy"
          />
        </div>
        <div className="text-center">
          {categoryPath.length > 1 && (
            <p className="text-xs text-gray-500">
              {categoryPath.slice(0, -1).join(' > ')}
            </p>
          )}
          <p className="text-sm font-medium">
            {category.name}
          </p>
          <p className="text-xs text-gray-500">
            {productCount} items
          </p>
        </div>
      </div>
    </div>
  );
};

const PopularCategories = ({ categories }) => {
  // Get leaf categories by checking the children array
  const getLeafCategories = (cats) => {
    let leaves = [];
    cats.forEach(cat => {
      if (cat.children && cat.children.length > 0) {
        leaves = [...leaves, ...getLeafCategories(cat.children)];
      } else {
        leaves.push(cat);
      }
    });
    return leaves;
  };

  const leafCategories = getLeafCategories(categories);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex justify-between items-center mb-8">
        <h2 className="text-lg sm:text-xl font-semibold">
          Categories
        </h2>
        <Link
          to="/categories"
          className="text-sm text-gray-600 hover:text-gray-900"
        >
          View All
        </Link>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-7 gap-6">
        {leafCategories.map((category) => (
          <CategoryItem
            key={category.id}
            category={category}
            categories={categories}
          />
        ))}
      </div>
    </div>
  );
};

export default PopularCategories;