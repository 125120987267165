import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { uploadImageBaseURL } from '../../utils/endpoint';
const ProductDealsGrid = ({ deals }) => {
  const navigate = useNavigate();
  console.log("deals", deals);
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex justify-between items-center mb-2 sm:mb-4">
        <h2 className="text-lg sm:text-xl font-semibold">Deals</h2>
        <Link to={`/products/all`} className="text-sm text-gray-600 hover:text-gray-900">
          View All
        </Link>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {deals.map((deal) => (
          <div
            key={deal.id}
            className="rounded-lg overflow-hidden shadow hover:shadow-lg transition-shadow bg-white cursor-pointer"
            onClick={() => navigate(`/products/all`)}
          >
            <div className="p-0 relative">
              <div
                className="p-4 h-48 relative"
                style={{
                  backgroundColor: deal.banner?.backgroundColor || '#3f53ee',
                  backgroundImage: deal.banner?.backgroundImage
                    ? `url(${`${uploadImageBaseURL}/get-image/${deal.banner.backgroundImage}`})`
                    : 'none',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              >
                {/* Text content */}
                <div
                  className={`absolute inset-0 p-4 flex flex-col justify-center ${deal.banner?.textPosition === 'right'
                    ? 'items-end text-right'
                    : deal.banner?.textPosition === 'center'
                      ? 'items-center text-center'
                      : 'items-start text-left'
                    }`}
                >
                  {deal.banner?.title && (
                    <h3 className="text-xl font-bold text-white">{deal.banner.title}</h3>
                  )}
                  {deal.banner?.subtitle && (
                    <p className="text-sm text-white">{deal.banner.subtitle}</p>
                  )}
                  {deal.offerValue && (
                    <p className="text-lg font-semibold text-white">
                      {`${deal.offerValue} ${deal.offerInPercentage ? 'Off' : ''}`}
                    </p>
                  )}
                  {deal.banner?.additionalText && (
                    <p className="text-sm text-white opacity-90">
                      {deal.banner.additionalText}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductDealsGrid;