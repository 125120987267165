import { gql } from '@apollo/client';

// User Queries
const getUsersQuery = gql`
  query GetUsers {
    getUsers {
      id
      name
      email
      username
      phoneNumber
      role
      active
      createdAt
      updatedAt
    }
  }
`;

const getUserByIdQuery = gql`
 query GetUserById($id: Int!) {
  getUserById(id: $id) {
     id
    email
    name
    username
    gender
    role
    token
    image
    addresses {
      id
      userId
      street
      city
      state
      postalCode
      country
      isDefault
      createdAt
      updatedAt
    }
    orders {
      id
      userId
      totalAmount
      status
      discount
      createdAt
      updatedAt
      shippingAddress {
        id
        userId
        street
        city
        state
        postalCode
        country
        isDefault
        createdAt
        updatedAt
      }
      billingAddress {
        id
        userId
        street
        city
        state
        postalCode
        country
        isDefault
        createdAt
        updatedAt
      }
    }
    createdAt
    updatedAt
    active
  }
  }
`;

const getProductByCatId = gql`
query GetProductsByCategoryId($categoryId: ID) {    
  getProductsByCategoryId(categoryId: $categoryId) {
    id
      name
      description
      price
      originalPrice
      stock
      categoryId
      brand
      images
      rating
      discountPercentage
      discountStartDate
      discountEndDate
      shippingPolicyImage
      returnPolicyImage
      sizeChartImage
      variants
      offer {
        id
        offerType
        productId
        offerName
        description
        offerInPercentage
        offerValue
        validityType
        startingDate
        endingDate
        numberOfDays
        bannerId
      
        banner {
          id
          title
          subtitle
          style
          discountInfo
          additionalText
          backgroundColor
          backgroundImage
          rightImage
          textPosition
          imagePosition
        }
      }
      skus {
        id
        variants
        price
        images
        productId
        stock
        sku
        
      }
      rating
      slug
      createdAt
      updatedAt
      ratings {
        createdAt
        productId
        review
        userId
        images
        id
        rating
      }
  }
}

`
// Address Queries
const getAddressesQuery = gql`
  query GetAddresses {
    getAddresses {
      id
      userId
      street
      city
      state
      postalCode
      country
      createdAt
      updatedAt
    }
  }
`;


// Order Queries
const getOrdersQuery = gql`
  query GetOrders {
    getOrders {
      id
      userId
      totalAmount
      status
      createdAt
      updatedAt
    }
  }
`;

const getOrderByIdQuery = gql`
  query GetOrderById($id: Int!) {
    getOrderById(id: $id) {
      id
      userId
      totalAmount
      status
      createdAt
      updatedAt
    }
  }
`;

// OrderItem Queries
const getOrderItemsQuery = gql`
  query GetOrderItems {
    getOrderItems {
      id
      orderId
      productId
      quantity
      price
      discount
      createdAt
      updatedAt
    }
  }
`;

const getOrderItemByIdQuery = gql`
  query GetOrderItemById($id: Int!) {
    getOrderItemById(id: $id) {
      id
      orderId
      productId
      quantity
      price
      discount
      createdAt
      updatedAt
    }
  }
`;

// Product Queries
const getProductsQuery = gql`
  query GetProducts {
    getProducts {
      id
      name
      description
      price
      originalPrice
      stock
      brand
      categoryId
      images
      rating
      discountPercentage
      discountStartDate
      discountEndDate
      variants
      shippingPolicyImage
      returnPolicyImage
      sizeChartImage
      isFeatured
      skus {
        id
        variants
        price
        images
        productId
        stock
        sku
        
      }
      rating
      slug
      createdAt
      updatedAt
      offer {
        id
        offerType
        productId
        offerName
        description
        offerInPercentage
        offerValue
        validityType
        startingDate
        endingDate
        numberOfDays
        bannerId
      
        banner {
          id
          title
          subtitle
          style
          discountInfo
          additionalText
          backgroundColor
          backgroundImage
          rightImage
          textPosition
          imagePosition
        }
      }
      ratings {
        createdAt
        productId
        review
        userId
        images
        id
        rating
      }
    }
  }
`;

const getProductByIdQuery = gql`
  query GetProductById($id: Int!) {
    getProductById(id: $id) {
      id
      name
      description
      price
      originalPrice
      stock
      categoryId
      images
      rating
      discountPercentage
      discountStartDate
      discountEndDate
      variants
      shippingPolicyImage
      returnPolicyImage
      sizeChartImage
      isFeatured
      skus {
        id
        variants
        price
        images
        productId
        stock
        sku
        
      }
      rating
      slug
      createdAt
      updatedAt
      ratings {
        createdAt
        productId
        review
        userId
        images
        id
        rating
      }
    }
  }
`;

// Category Queries
const getCategoriesQuery = gql`
  query GetCategories {
    getCategories {
      id
    name
    description
    images
    children {
      id
      name
      description
      images
      children {
        id
        name
        description
        images
        children {
        id
        name
        description
        images
        children {
          id
        }
      }
      }
    }
    }
  }
`;

const getCategoryByIdQuery = gql`
  query GetCategoryById($id: Int!) {
    getCategoryById(id: $id) {
      id
    name
    description
    images
    children {
      id
      name
      description
      images
      children {
        id
        name
        description
        images
        children {
        id
        name
        description
        images
        children {
          id
        }
      }
      }
    }
    }
  }
`;

// ParentCategory Queries
const getParentCategoriesQuery = gql`
  query GetParentCategories {
    getParentCategories {
      id
      name
      description
      images
      categories {
        id
        name
        description
        parentCategoryId
        images
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

const getParentCategoryByIdQuery = gql`
  query GetParentCategoryById($id: Int!) {
    getParentCategoryById(id: $id) {
      id
      name
      description
      images
      categories {
        id
        name
        description
        parentCategoryId
        images
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

// AttributeSet Queries
const getAllAttributeSetsQuery = gql`
  query GetAllAttributeSets {
    allAttributeSets {
      id
      name
      createdAt
      keys {
        id
        name
        type
        value
      }
    }
  }
`;

const getAttributeSetByIdQuery = gql`
  query GetAttributeSetById($id: Int!) {
    getAttributeSetById(id: $id) {
      id
      name
      keys {
        id
        name
        type
        value
      }
    }
  }
`;

const getAllOrdersQuery = gql`
  query getAllOrders {
    getOrders {
      createdAt
      id
      totalAmount
      status
      userId
      orderItems {
        price
        skuId
      }
    }
  }
`;

const GET_CART_ITEMS = gql`
  query GetCarts($userId: Int!, $pagination: PaginationInput) {
    getCarts(userId: $userId, pagination: $pagination) {
      id
      total
      updatedAt
      cartItems {
        cartId
        id
        name
        quantity
        skuId
        originalPrice
        sku {
          id
          images
          price
          productId
          stock
          updatedAt
          variants
        }
      }
    }
  }
`;

export const GET_CART_BY_SESSIONID = gql`
query GetCartBySessionId($sessionId: String!) {
  getCartBySessionId(sessionId: $sessionId) {
     id
      total
      updatedAt
      cartItems {
        cartId
        id
        name
        quantity
        skuId
        originalPrice
        sku {
          id
          images
          price
          productId
          stock
          updatedAt
          variants
        }
      }
   
  }
}
`
// Address queries
const GET_ADDRESS = gql`
  query GetAddress($id: Int!) {
    address(id: $id) {
      id
      street
      city
      state
      postalCode
      country
      isDefault
      user {
        id
        name
        email
      }
      createdAt
      updatedAt
    }
  }
`;

const GET_USER_ADDRESSES = gql`
query GetAddressesByUserId($userId: Int!) {
  getAddressesByUserId(userId: $userId) {
    id
    street
    city
    state
    postalCode
    country
    isDefault
    createdAt
    updatedAt
  }
}
`;

const GET_DEFAULT_ADDRESS = gql`
  query GetDefaultAddress($userId: Int!) {
    defaultAddress(userId: $userId) {
      id
      street
      city
      state
      postalCode
      country
      isDefault
      createdAt
      updatedAt
    }
  }
`;
const getAllOffersQuery = gql`
  query GetAllOffers {
    getAllOffers {
      id
      offerType
      realmType
      department
      productId
      offerName
      description
      offerInPercentage
      offerValue
      validityType
      startingDate
      endingDate
      numberOfDays
      banner {
        id
        title
        subtitle
        discountInfo
        additionalText
        backgroundColor
        backgroundImage
        rightImage
        textPosition
        imagePosition
        style
      }
      bannerId
    }
  }
`;
const GET_ORDERS_BY_USER_ID = gql`
  query GetOrdersByUserId($userId: Int!) {
    getOrdersByUserId(userId: $userId) {
      id
      userId
      totalAmount
      status
      discount
      createdAt
      updatedAt
      orderItems {
        id
        orderId
        productId
        skuId
        quantity
        price
        createdAt
        updatedAt
        product {
          id
          name
          description
          price
          originalPrice
          stock
          categoryId
          images
          rating
          averageRating
          totalRatings
          attributeSets
          isFeatured
        }
        sku {
          id
          sku
          price
          productId
          images
          variants
          stock
        }
      }
      shippingAddress {
        id
        street
        city
        state
        postalCode
        country
      }
      billingAddress {
        id
        street
        city
        state
        postalCode
        country
      }
    }
  }
`;
const globalSearchQuery = gql`
  query GlobalSearch($query: String!) {
    globalSearch(query: $query) {
      id
      name
      description
      price
      originalPrice
      skus {
        id
        price
        variants
        images
      }
    }
  }
`;
const getSimilarProductsQuery = gql`
  query GetSimilarProducts($categoryId: Int!, $limit: Int) {
    getSimilarProducts(categoryId: $categoryId, limit: $limit) {
      id
      name
      price
      images
      categoryId
    }
  }
`;
const getAllInstagramPostsQuery = gql`
  query GetAllInstagramPosts {
    getAllInstagramPosts {
      id
      postUrl
      thumbnailUrl
      caption
      likes
      comments
      isActive
      createdAt
      updatedAt
    }
  }
`;
export {
  GET_DEFAULT_ADDRESS,
  globalSearchQuery,
  GET_ORDERS_BY_USER_ID,
  getSimilarProductsQuery,
  getAllOffersQuery,
  GET_USER_ADDRESSES,
  GET_CART_ITEMS,
  getUsersQuery,
  getUserByIdQuery,
  getAddressesQuery,
  getOrdersQuery,
  getAllOrdersQuery,
  getOrderByIdQuery,
  getOrderItemsQuery,
  getOrderItemByIdQuery,
  getProductsQuery,
  getProductByIdQuery,
  GET_ADDRESS,
  getAllInstagramPostsQuery,
  getProductByCatId,
  getCategoriesQuery,
  getCategoryByIdQuery,
  getParentCategoriesQuery,
  getParentCategoryByIdQuery,
  getAllAttributeSetsQuery,
  getAttributeSetByIdQuery,
};
