import React from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from '@apollo/client';
import { getCategoriesQuery, getProductByCatId } from '../actions/query';
import { uploadImageBaseURL } from '../utils/endpoint';
const AllCategories = () => {
  const navigate = useNavigate();

  const { data: categories, loading } = useQuery(getCategoriesQuery, {
    fetchPolicy: 'cache-and-network'
  });

  const CategoryItem = ({ category }) => {
    const { data: productData } = useQuery(getProductByCatId, {
      variables: { categoryId: category.id },
    });

    const productCount = productData?.getProductsByCategoryId?.length || 0;

    return (
      <div
        onClick={() => navigate(`/products/${category?.id}`)}
        className="cursor-pointer group"
      >
        <div className="flex flex-col items-center space-y-2">
          <div className="w-40 h-40 rounded-full bg-gray-100 flex items-center justify-center overflow-hidden p-2 border border-gray-200 transition-all duration-300 group-hover:shadow-lg">
            <img
              src={category.images?.[0] ? `${uploadImageBaseURL}/get-image/${category.images?.[0]}` : "/api/placeholder/112/112"}
              alt={category.name}
              className="w-full h-full rounded-full object-cover transition-transform duration-300 group-hover:scale-110"
              loading="lazy"
            />
          </div>
          <h3 className="text-lg font-medium text-center">
            {category.name}
          </h3>
          <p className="text-sm text-gray-500">
            {productCount} items
          </p>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-slate-200 pt-[126px]">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
            {[...Array(8)].map((_, i) => (
              <div key={i} className="flex flex-col items-center space-y-2">
                <div className="w-40 h-40 bg-gray-300 rounded-full animate-pulse"></div>
                <div className="w-24 h-4 bg-gray-300 rounded animate-pulse"></div>
                <div className="w-16 h-4 bg-gray-300 rounded animate-pulse"></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-slate-200 pt-[126px]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-2xl font-bold mb-8">All Categories</h1>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
          {categories?.getCategories?.map((category) => (
            <CategoryItem key={category.id} category={category} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AllCategories;