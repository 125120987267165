import React from 'react';
import { FaChevronDown } from 'react-icons/fa';

const TopHeader = () => {
  return (
    <div className="bg-black text-white py-1 px-4 text-xs">
      <div className="max-w-7xl mx-auto flex flex-wrap justify-between items-center gap-2">
        <div className="flex flex-wrap items-center gap-3">
          <span>+91 6392363003</span>
          <span>Swipmart@gmail.com</span>
          {/* <a href="#" className="hover:text-gray-300 underline">Our Store</a> */}
        </div>

        {/* <div className="flex flex-wrap items-center gap-3">
          <div className="flex items-center gap-1 cursor-pointer">
            <img 
              src="https://flagcdn.com/w20/us.png" 
              alt="Rupee"  
              className="w-3 h-auto"
            />
            <span>Rupee</span>
            <FaChevronDown className="w-2 h-2" />
          </div>

          <div className="flex items-center gap-1 cursor-pointer">
            <span>English</span>
            <FaChevronDown className="w-2 h-2" />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default TopHeader; 